import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { /* useMediaQuery, */ Dialog, DialogContent, Typography, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import CustomAvatar from 'ReusableComponents/CustomAvatar';
import DialogTitle from 'ReusableComponents/DialogTitle/DialogTitle';
import NewProjectStepper from './NewProjectStepper';
import RedirectDialog from './RedirectDialog';

import { useCurrentFolder } from 'Drive/CurrentFolderContext';
import { useMainContext } from 'ReusableComponents';
import { useMinimalAuth } from 'hooks';

import ApiManager from 'ApiManager';
import { CLOUDS } from 'ApiUrls';

const useStyles = makeStyles({
  name: 'NewProjectDialog',
})((theme, _params, classes) => ({
  root: { zIndex: 2002 },
  content: { overflow: 'initial' },
  loginContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px',
    [`& .${classes.img}`]: { maxWidth: '10vw', maxHeight: '10vh', marginBottom: '1.5rem' },
    [`& .${classes.buttonContainer}`]: { display: 'flex', gap: '12px', marginTop: '1rem' },
  },
  img: {},
  buttonContainer: {},
  flex: { display: 'flex', flexWrap: 'wrap', gap: theme.spacing(0.5, 1) },
}));

const NewProjectDialog = () => {
  const { classes: styles } = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const user = useMinimalAuth();
  const { currentFolderInfo, mainMode } = useCurrentFolder();
  const { openNewProjectDialog, handleCloseNewProjectDialog, tourOpen } = useMainContext();

  // deny project creation if conditions aren't met

  const canCreateHere =
    tourOpen ||
    (mainMode === '/drive/me' && (!currentFolderInfo || currentFolderInfo.type === 'folder')) ||
    (mainMode === '/drive/shared' &&
      currentFolderInfo &&
      currentFolderInfo.type === 'folder' &&
      currentFolderInfo.yourAccess.accessLevel >= ApiManager.newAccessLevel['edit+']);

  if (!canCreateHere) {
    return <RedirectDialog open={openNewProjectDialog} handleClose={handleCloseNewProjectDialog} />;
  }

  const onClose = () => {
    if (activeStep < 2) {
      setActiveStep(0);
      handleCloseNewProjectDialog();
    }
  };

  return (
    <Dialog
      className={styles.root}
      open={openNewProjectDialog}
      onClose={onClose}
      fullWidth
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle
        title={
          !!user || tourOpen ? (
            <>
              {`Create a new layer in ${currentFolderInfo ? `folder '${currentFolderInfo.name}'` : '"My Drive"'}`}
              <Typography component="span" className={styles.flex}>
                In the Drive of
                <span className={styles.flex}>
                  <CustomAvatar user={currentFolderInfo?.user ?? user} size="1em" />
                  {currentFolderInfo?.user?.username ?? user?.username}
                </span>
              </Typography>
            </>
          ) : (
            <>You are currently not logged in</>
          )
        }
        close={activeStep < 2 ? onClose : undefined}
        titleProps={{ noWrap: false }}
      />
      <DialogContent className={styles.content}>
        {!!user || tourOpen ? (
          <NewProjectStepper
            className="tutorialNewMap"
            activeStep={activeStep}
            demoMode={tourOpen}
            setActiveStep={setActiveStep}
            user={user}
            handleCloseNewProjectDialog={handleCloseNewProjectDialog}
          />
        ) : (
          <div className={styles.loginContainer}>
            <img
              className={styles.img}
              src={
                ApiManager.cloud === CLOUDS.ellipsis
                  ? '/ellipsis-logo.svg'
                  : ApiManager.apiUrl + '/v3/backoffice/theme/logo'
              }
              alt="ellipsis-logo"
            />
            <Typography>To create a layer, please log in or register first.</Typography>
            <div className={styles.buttonContainer}>
              <Button
                onClick={handleCloseNewProjectDialog}
                component={Link}
                to="/login"
                style={{ minWidth: '120px' }}
                variant="contained"
                color="primary"
              >
                Log in
              </Button>
              <Button
                onClick={handleCloseNewProjectDialog}
                component={Link}
                to="/register"
                style={{ minWidth: '120px' }}
                variant="contained"
                color="primary"
              >
                Register
              </Button>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default NewProjectDialog;
