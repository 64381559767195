import { Button, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ConfirmationDialog } from 'ReusableComponents';
import { DangerConfirmationDialog, FindMemberDialog } from '../../core';
import MapManagementContext from '../../MapManagementContext';
import PropTypes from 'prop-types';
import SectionTitle from '../../Section';
import { submitSettingsToAPI, submitToAPI } from '../../utils';

const useStyles = makeStyles()((theme) => ({
  groupTitle: {},
  content: {
    border: `1px solid ${theme.palette.error.main}`,
    borderRadius: theme.spacing(1),
    width: 'calc(100% - 32px)',
    padding: ' 0',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  caption: {},
  textContainer: {
    maxWidth: '400px',
  },
  section: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    width: '100%',
    padding: theme.spacing(2),
    '&+&': {
      borderTop: `1px solid ${theme.palette.divider}`,
    },
  },
}));

export const DangerButton = ({ children, onClick, alignRight }) => {
  return (
    <Button variant="outlined" color="error" onClick={onClick}>
      {children}
    </Button>
  );
};

DangerButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

function DangerZone() {
  const { classes } = useStyles();
  const history = useHistory();
  const { map, user, onOpenSnackbar, onSelectMap } = useContext(MapManagementContext);

  const [transferUser, setTransferUser] = useState('');

  const [dangerDialogOpen, setDangerDialogOpen] = useState({
    open: false,
    type: null,
    title: '',
    legend: '',
    agreeText: 'Agree',
    onClick: () => null,
  });

  const handleDialogClose = () =>
    setDangerDialogOpen({
      open: false,
      type: null,
      title: '',
      legend: '',
      agreeText: 'Agree',
      onGeometryClick: () => null,
    });

  const handleTransferSubmit = () => {
    const body = { mapId: map.id };
    if (transferUser.id) {
      body.userId = transferUser.id;
    } else {
      body.email = transferUser.username;
    }

    handleDialogClose();
    submitSettingsToAPI('POST', `/v3/path/${map.id}/transfer`, body, user, 'Transfer invite sent', onOpenSnackbar, () =>
      onSelectMap()
    );
  };

  const handleDeleteProject = async () => {
    await submitToAPI(
      'PUT',
      `/v3/path/${map.id}/trashed`,
      { trashed: true },
      user,
      'Project successfully moved to your trash.',
      onOpenSnackbar
    );
    handleDialogClose();
    history.push('/drive/me');
  };

  return (
    <>
      <SectionTitle border={false} style={{ marginBottom: 0 }}>
        <SectionTitle.SectionTitleText TypographyProps={{ color: 'error' }}>Danger Zone</SectionTitle.SectionTitleText>
      </SectionTitle>
      <div className={classes.content}>
        <section className={classes.section}>
          <div className={classes.textContainer}>
            <Typography variant="subtitle1" className={classes.groupTitle}>
              Transfer ownership
            </Typography>
            <Typography variant="body1" className={classes.caption}>
              Once you transfer the ownership of a layer, there is no going back. Please be certain.
            </Typography>
          </div>
          <DangerButton
            onClick={() =>
              setDangerDialogOpen({
                open: true,
                type: 'findMember',
                title: 'Transfer ownership of the layer',
                legend: `Enter the name of the user you want to transfer this layer to. Once that user has accepted the transfer, you can't go back. Please be certain.`,
                agreeText: 'Transfer',
              })
            }
          >
            Transfer ownership
          </DangerButton>
        </section>
        <section className={classes.section}>
          <div className={classes.textContainer}>
            <Typography variant="subtitle1" className={classes.groupTitle}>
              Trash layer
            </Typography>
            <Typography variant="body1" className={classes.caption}>
              A trashed layer can be recovered later on. Only once the layer has been permanently deleted it can no
              longer be restored and will no longer take up any space.
            </Typography>
          </div>
          <DangerButton
            onClick={() =>
              setDangerDialogOpen({
                open: true,
                type: 'delete',
                title: 'Delete layer',
                legend: `Are you sure you want to delete this layer?`,
                agreeText: 'Delete',
              })
            }
          >
            Delete layer
          </DangerButton>
        </section>
      </div>
      <FindMemberDialog
        open={dangerDialogOpen.open && dangerDialogOpen.type === 'findMember'}
        handleClose={handleDialogClose}
        onSubmit={(user) => {
          setTransferUser(user);
          setDangerDialogOpen({
            open: true,
            type: 'transfer',
            title: `Are you sure you want to transfer the ownership of this layer?`,
            legend: `This action isn't reversible. please be sure.
            Please input the name of the layer below in order to confirm.`,
            agreeText: 'Transfer',
          });
        }}
        title="Transfer ownership of the layer"
        legend={`Enter the name of the user you want to transfer this layer too. Once that user has accepted the transfer, you can't go back. Please be certain.`}
        agreeText="Transfer"
      />
      <DangerConfirmationDialog
        open={dangerDialogOpen.open && dangerDialogOpen.type === 'transfer'}
        handleClose={handleDialogClose}
        title={dangerDialogOpen.title}
        legend={dangerDialogOpen.legend}
        confirmationText={map.name}
        agreeText={dangerDialogOpen.agreeText}
        // I'm doing it that way to get around a closure issue
        onClick={dangerDialogOpen.type === 'transfer' ? handleTransferSubmit : dangerDialogOpen.onClick}
      />
      <ConfirmationDialog
        open={dangerDialogOpen.open && dangerDialogOpen.type === 'delete'}
        handleClose={handleDialogClose}
        title={dangerDialogOpen.title}
        legend={dangerDialogOpen.legend}
        confirmationText={map.name}
        agreeText={dangerDialogOpen.agreeText}
        onClick={handleDeleteProject}
      />
    </>
  );
}

export default DangerZone;
