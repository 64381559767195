import React, { useState } from 'react';

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Select,
  MenuItem,
  ListItem,
  ListItemText,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { DateTimePicker } from '@mui/x-date-pickers';

import { CustomColorPicker } from 'MapManagement/core';
import { useMainContext } from 'ReusableComponents';
import moment from 'moment';

const useStyles = makeStyles({ name: 'AddFeatureProperties' })((theme) => ({
  root: {},
  inputRow: {
    width: '100%',
    margin: 0,
    alignItems: 'flex-start',
    padding: '4px 16px',
    boxSizing: 'border-box',
  },
  formLabel: {
    fontWeight: 'bold',
    color: theme.palette.common.gray1,
    '& span': { fontWeight: 'normal', textTransform: 'initial' },
  },
}));

export const AddFeatureProperties = ({
  properties,
  onPropertyValueChange,
  errors,
  isEdit,
  copiedProperties,
  editing,
  isUpload,
  layerInfo,
  isExternal,
}) => {
  const { classes: styles } = useStyles();
  const { onModalContentChange } = useMainContext();
  const [showColorPicker, setShowColorPicker] = useState(false);
  //property inputs

  const isValidHttpUrl = (string) => {
    try {
      new URL(string);
    } catch (_) {
      return false;
    }
    return true;
  };

  let inputs = [];

  if (!!editing) {
    inputs = properties.map((property) => {
      if (property.levels && property.levels.length > 0) {
        return (
          <FormControlLabel
            className={styles.inputRow}
            key={property.name}
            label={
              <Typography variant="button" className={styles.formLabel}>
                {property.name + (!!property.required ? '*' : '')}
                {property.filtering && <span> ({property.filtering})</span>}
              </Typography>
            }
            labelPlacement="top"
            control={
              <Select
                variant="outlined"
                required={property.required}
                value={copiedProperties[property.name]}
                onChange={(e) => onPropertyValueChange(e.target.value, property)}
                fullWidth
              >
                {property.levels.map((l) => (
                  <MenuItem key={l} value={l}>
                    {l}
                  </MenuItem>
                ))}
              </Select>
            }
          />
        );
      } else if (property.type === 'string') {
        return (
          <FormControlLabel
            className={styles.inputRow}
            key={property.name}
            label={
              <Typography variant="button" className={styles.formLabel}>
                {property.name + (!!property.required ? '*' : '')}
                {property.filtering && <span> ({property.filtering})</span>}
              </Typography>
            }
            labelPlacement="top"
            control={
              <TextField
                size="small"
                variant="outlined"
                required={property.required}
                value={copiedProperties[property.name]}
                onChange={(e) => onPropertyValueChange(e.target.value.toString(), property)}
                error={errors[property.name] ? true : false}
                helperText={errors[property.name]}
                fullWidth
              />
            }
          />
        );
      } else if (property.type === 'integer') {
        return (
          <FormControlLabel
            className={styles.inputRow}
            label={
              <Typography variant="button" className={styles.formLabel}>
                {property.name + (!!property.required ? '*' : '')}
                {property.filtering && <span> ({property.filtering})</span>}
              </Typography>
            }
            labelPlacement="top"
            key={property.name}
            control={
              <TextField
                size="small"
                variant="outlined"
                required={property.required}
                type="number"
                value={copiedProperties[property.name]}
                inputProps={{ step: 1 }}
                onInput={(e) => onPropertyValueChange(parseInt(e.target.value), property)}
                error={errors[property.name] ? true : false}
                helperText={errors[property.name]}
                fullWidth
              />
            }
          />
        );
      } else if (property.type === 'float') {
        return (
          <FormControlLabel
            className={styles.inputRow}
            label={
              <Typography variant="button" className={styles.formLabel}>
                {property.name + (!!property.required ? '*' : '')}
                {property.filtering && <span> ({property.filtering})</span>}
              </Typography>
            }
            labelPlacement="top"
            key={property.name}
            control={
              <TextField
                size="small"
                variant="outlined"
                required={property.required}
                type="number"
                value={copiedProperties[property.name]}
                inputProps={{ step: 0.01 }}
                onInput={(e) => onPropertyValueChange(parseFloat(e.target.value), property)}
                error={errors[property.name] ? true : false}
                helperText={errors[property.name]}
                fullWidth
              />
            }
          />
        );
      } else if (property.type === 'boolean') {
        return (
          <FormControl
            variant="standard"
            className={styles.inputRow}
            key={property.name}
            required={property.required}
            error={errors[property.name] ? true : false}
          >
            <FormLabel className={styles.formLabel}>
              {property.name + (!!property.required ? '*' : '')}
              {property.filtering && <span> ({property.filtering})</span>}
            </FormLabel>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={copiedProperties[property.name] === false ? true : false}
                    onChange={(e, value) => onPropertyValueChange(value === true ? false : null, property)}
                    name="false"
                    color="primary"
                  />
                }
                label="False"
                labelPlacement="top"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={copiedProperties[property.name] === true ? true : false}
                    onChange={(e, value) => onPropertyValueChange(value === true ? true : null, property)}
                    name="true"
                    color="primary"
                  />
                }
                label="True"
                labelPlacement="top"
              />
            </FormGroup>
            <FormHelperText>{errors[property.name]}</FormHelperText>
          </FormControl>
        );
      } else if (property.type === 'date') {
        let value = copiedProperties[property.name];
        return (
          <FormControlLabel
            className={styles.inputRow}
            label={
              <Typography variant="button" className={styles.formLabel}>
                {property.name + (!!property.required ? '*' : '')}
                {property.filtering && <span> ({property.filtering})</span>}
              </Typography>
            }
            labelPlacement="top"
            key={property.name}
            control={
              <DateTimePicker
                variant="inline"
                value={!!value ? moment(value) : null}
                inputVariant="outlined"
                onChange={(a) => onPropertyValueChange(a?.toISOString(), property)}
              />
            }
          />
        );
      }
      return null;
    });
    if (!isUpload && !isExternal && layerInfo?.style.method === 'fromColorProperty') {
      inputs.push(
        <FormControlLabel
          className={styles.inputRow}
          label={
            <Typography variant="button" className={styles.formLabel}>
              Color
            </Typography>
          }
          labelPlacement="top"
          key={'color'}
          control={
            <CustomColorPicker
              color={copiedProperties.color ? copiedProperties.color : '#ffffff'}
              onChange={(e) => {
                onPropertyValueChange(e.hex, { name: 'color', type: 'color' });
                setShowColorPicker(false);
              }}
              showColorPicker={showColorPicker}
              toggleShowColorPicker={() => setShowColorPicker((prev) => !prev)}
            />
          }
        />
      );
    }
  } else {
    inputs = properties
      .filter((p) => !p.hidden)
      .map((property) => {
        const propertyValue = copiedProperties?.[property.name];
        const stringCastedValue =
          property?.type === 'date' && !!propertyValue ? moment(propertyValue).format('LLL') : String(propertyValue);

        return (
          <ListItem dense margin="none" key={property.id} component="div">
            <ListItemText
              primary={
                <>
                  {property.name + (!!property.required ? '*' : '')}
                  {property.filtering && <span> ({property.filtering})</span>}
                </>
              }
              secondary={
                isValidHttpUrl(stringCastedValue) ? (
                  <Link
                    onClick={() => {
                      onModalContentChange(stringCastedValue, false, true);
                    }}
                    underline="hover"
                  >
                    {stringCastedValue}
                  </Link>
                ) : propertyValue === 'undefined' || (stringCastedValue !== '' && stringCastedValue !== 'undefined') ? (
                  stringCastedValue
                ) : (
                  '-'
                )
              }
              primaryTypographyProps={{ noWrap: true, variant: 'button', className: styles.formLabel }}
            />
          </ListItem>
        );
      });
    if (!isUpload && !isExternal && layerInfo?.style.method === 'fromColorProperty') {
      inputs.push(
        <FormControlLabel
          className={styles.inputRow}
          label={
            <Typography variant="button" className={styles.formLabel}>
              Color
            </Typography>
          }
          labelPlacement="top"
          key={'color'}
          control={
            <CustomColorPicker
              color={copiedProperties.color ? copiedProperties.color : '#ffffff'}
              showColorPicker={false}
              toggleShowColorPicker={() => setShowColorPicker((prev) => !prev)}
            />
          }
        />
      );
    }
  }

  return (
    <>
      {inputs}
      {copiedProperties?.id && (
        <ListItem dense margin="none" key={'ID'} component="div" className={styles.inputRow}>
          <ListItemText
            primary={'ID'}
            secondary={copiedProperties?.id}
            primaryTypographyProps={{ noWrap: true, variant: 'button', className: styles.formLabel }}
          />
        </ListItem>
      )}
    </>
  );
};
