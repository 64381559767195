import React from 'react';
import { TextField, MenuItem, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const possibleDates = {
  Y: 'year',
  m: 'month',
  d: 'day',
  H: 'hour',
  M: 'minute',
  S: 'second',
  f: 'subsecond',
};
const possibleSeparators = ['-', ':', '/', 'T', ' ', '.', '$'];

const previewValue = {
  '%Y': '2021',
  '%m': '11',
  '%d': '23',
  '%H': '17',
  '%M': '46',
  '%S': '57',
  '%f': '126',
};

const getPreview = (datePattern) => {
  return datePattern
    .match(/.{1,3}/g)
    .map((pattern) => previewValue[pattern.slice(0, 2)] + pattern.slice(2))
    .join('');
};

const useStyles = makeStyles()((theme) => ({
  select: {
    marginRight: '6px',
    marginBottom: '6px',
  },
  newTextField: {
    minWidth: '50px',
  },
  selectRoot: {
    padding: '6px 3px !important',
    textAlign: 'center',
  },
  selectIcon: {
    display: 'none',
  },
  caption: {
    margin: '8px 0px',
  },
}));

const sepToLabel = (sep) => {
  if (sep === ' ') return '␣';
  if (sep === '$') return 'empty';
  return sep;
};

const DatePatternChooser = ({ datePattern, handleDatePatternChange }) => {
  const { classes, cx } = useStyles();
  // mock state for dev, will delete this and use the prop func instead
  // const [datePattern, setDatePattern] = useState(mockPattern);

  const handleChange = (e) => {
    // if you opted to remove the entry
    if (e.target.value === '') {
      // if it's the last entry, remove the last sepator too
      const amountToRemoveBefore = datePattern.length - 1 === parseInt(e.target.name) ? 2 : 1;
      handleDatePatternChange(
        (pattern) =>
          pattern.substring(0, parseInt(e.target.name) - amountToRemoveBefore) +
          pattern.substring(parseInt(e.target.name) + 2)
      );
    } else {
      // replace element at index `e.target.name` by `e.target.value`
      handleDatePatternChange(
        (pattern) =>
          pattern.substring(0, parseInt(e.target.name)) +
          e.target.value +
          pattern.substring(parseInt(e.target.name) + 1)
      );
    }
  };

  const handleAdd = (e) => {
    handleDatePatternChange((pattern) => `${pattern}${e.target.name === 'date' ? '%' : ''}${e.target.value}`);
  };

  return <>
    <Typography color="textSecondary" className={classes.caption}>
      Date format:{' '}
    </Typography>
    <div>
      {[...datePattern].map((element, index) =>
        element === '%' ? null : Object.keys(possibleDates).includes(element) ? (
          <TextField
            key={`date-${index}`}
            variant="filled"
            value={element}
            select
            className={classes.select}
            name={String(index)}
            onChange={handleChange}
            SelectProps={{ classes: { icon: classes.selectIcon, root: classes.selectRoot } }}
          >
            <MenuItem value="">
              <em>delete</em>
            </MenuItem>
            {Object.keys(possibleDates).map((date, idx) => (
              <MenuItem key={date} value={date} disabled={datePattern.includes(date)}>
                {possibleDates[date]}
              </MenuItem>
            ))}
          </TextField>
        ) : (
          <TextField
            key={`sep-${index}`}
            variant="filled"
            value={element}
            select
            className={classes.select}
            name={String(index)}
            onChange={handleChange}
            SelectProps={{ classes: { icon: classes.selectIcon, root: classes.selectRoot } }}
          >
            {possibleSeparators.map((separator) => (
              <MenuItem key={separator} value={separator}>
                {sepToLabel(separator)}
              </MenuItem>
            ))}
          </TextField>
        )
      )}
      {(datePattern === null || datePattern === '' || datePattern?.match(/.{1,3}/g)?.length < 7) &&
        (!Object.keys(possibleDates).includes(datePattern[datePattern.length - 1]) ? (
          <TextField
            value={''}
            variant="filled"
            name="date"
            select
            className={cx(classes.newTextField, classes.select)}
            onChange={handleAdd}
            SelectProps={{ classes: { icon: classes.selectIcon, root: classes.selectRoot } }}
            helperText="date type"
          >
            {Object.keys(possibleDates).map((date, idx) => (
              <MenuItem key={date} value={date} disabled={datePattern.includes(date)}>
                {possibleDates[date]}
              </MenuItem>
            ))}
          </TextField>
        ) : (
          <TextField
            value={''}
            variant="filled"
            name="sep"
            select
            className={cx(classes.newTextField, classes.select)}
            onChange={handleAdd}
            SelectProps={{ classes: { icon: classes.selectIcon, root: classes.selectRoot } }}
            helperText="separator"
          >
            {possibleSeparators.map((separator) => (
              <MenuItem key={separator} value={separator}>
                {sepToLabel(separator)}
              </MenuItem>
            ))}
          </TextField>
        ))}
    </div>
    {!!datePattern && (
      <Typography color="textSecondary" className={classes.caption}>
        Date format example: {getPreview(datePattern)}
      </Typography>
    )}
  </>;
};

export default DatePatternChooser;
