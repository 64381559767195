import React, { useCallback } from 'react';

import { Slider } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles({ name: 'Resolution' })((theme) => ({
  root: {
    margin: theme.spacing(1.5, 1.5, 4, 1.5),
    [theme.breakpoints.down('sm')]: { margin: theme.spacing(0, 1.5, 4, 1.5) },
  },
}));

const LABELS = ['1cm', '10cm', '1m', '10m', '100m', '1km', '10km', '100km'];
const MARKS = LABELS.map((label, i) => ({ label, value: i }));

const Resolution = ({ searchVal: input, setSearchVal }) => {
  const { classes: styles } = useStyles();

  const scaleUp = useCallback((value) => 10 ** (value - 2), []);
  const scaleDown = useCallback((value) => Math.log10(value) + 2, []);

  const toSearchVal = useCallback(([min, max]) => ({ min: scaleUp(min), max: scaleUp(max) }), [scaleUp]);
  const toVal = useCallback(({ min, max }) => [scaleDown(min), scaleDown(max)], [scaleDown]);

  const searchVal = !input ? [0, scaleUp(MARKS?.length - 1)] : toVal(input);

  const valueLabelFormat = useCallback((value) => MARKS[value]?.label, []);

  const handleChange = useCallback((_, newVal) => setSearchVal(toSearchVal(newVal)), [setSearchVal, toSearchVal]);

  return (
    <Slider
      className={styles.root}
      min={MARKS[0]?.value}
      max={MARKS?.length - 1}
      value={searchVal}
      valueLabelDisplay="auto"
      step={1}
      marks={MARKS}
      onChange={handleChange}
      valueLabelFormat={valueLabelFormat}
    />
  );
};

export default Resolution;
