import React, { useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import UserSelect from '../UserSelect/UserSelect';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useAuth } from '../../Account/AuthContext';

const FindMemberDialog = ({ open, handleClose, onSubmit, title, legend, agreeText = 'Add', cancelText = 'Cancel' }) => {
  const authUser = useAuth();

  const [user, setUser] = useState(null);

  const handleUserChange = useCallback((u) => {
    setUser(u);
  }, []);

  const ref = useRef();

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" ref={ref}>
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent style={{ paddingBottom: '50px' }}>
        <DialogContentText>{legend}</DialogContentText>
        <UserSelect value={user} user={authUser} onChange={handleUserChange} portalRef={ref} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {cancelText}
        </Button>
        <Button onClick={() => onSubmit(user)} color="primary">
          {agreeText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

FindMemberDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  legend: PropTypes.string.isRequired,
  agreeText: PropTypes.string,
  cancelText: PropTypes.string,
};

export default FindMemberDialog;
