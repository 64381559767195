import { Button, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import React from 'react';

const LeaveFavorite = ({ cancel, confirm, isMap }) => {
  const handleConfirm = () => {
    confirm();
    cancel();
  };

  return (
    <React.Fragment>
      <DialogContent>
        <DialogContentText>
          You are about to remove this {isMap ? 'layer' : 'folder'} from your favorites. This might not be easily
          reversible.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={cancel} color={'inherit'}>
          Cancel
        </Button>
        <Button onClick={handleConfirm} color="primary">
          Remove
        </Button>
      </DialogActions>
    </React.Fragment>
  );
};

export default LeaveFavorite;
