import React, { useMemo } from 'react';

import { Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Switch, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import capitalize from 'lodash/capitalize';

import Sidebar from '../../ReusableComponents/SettingsSidebar';
import { useAuth } from '../AuthContext';

import AccountSecurity from './AccountSecurity';
import Profile from './Profile';
import Developers from './Developers';
import Applications from './Applications';
import ConnectionKeys from './ConnectionKeys';

import PersonalAccessTokens from './PersonalAccessTokens';
import NewOAuthApp from './NewOAuthApp';
import { useHistory } from 'react-router-dom';
import SentryRoute from 'ReusableComponents/SentryRoute/SentryRoute';
import ApiManager from 'ApiManager';
// import ChangeHostLocation from './ChangeHostLocation/ChangeHostLocation';

const useStyles = makeStyles()((theme) => ({
  gridRoot: {
    maxWidth: '1024px',

    margin: 'auto',
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
  },
}));

const AccountManagement = () => {
  const { classes } = useStyles();
  const user = useAuth();
  const history = useHistory();

  const tabs = useMemo(() => {
    const tabs = [
      {
        name: 'Profile',
        path: 'profile',
        component: Profile,
        type: 'tab',
      },
      {
        name: 'Account Security',
        path: 'security',
        component: AccountSecurity,
        type: 'tab',
      },
      {
        name: 'Applications',
        path: 'applications',
        component: Applications,
        type: 'tab',
      },
      {
        name: 'Personal access tokens',
        path: 'personalAccessTokens',
        component: PersonalAccessTokens,
        type: 'tab',
      },
    ];

    if (user?.username === 'admin') {
      tabs.push({
        name: 'External connection keys',
        path: 'connectionKeys',
        component: ConnectionKeys,
        type: 'tab',
      });
    }

    if (user?.commercial) {
      tabs.push({
        name: 'Developer settings',
        path: 'developers',
        component: Developers,
        type: 'tab',
      });
    }

    return tabs;
  }, [user]);

  if (!user) {
    let url = `${window.location.pathname}${window.location.search}`;
    if (url.includes('account-settings')) {
      history.push('/login', { from: url });
    }
    return null;
  }

  return (
    <div>
      <Helmet>
        <meta name="robots" content="noindex" data-react-helmet="true" />
      </Helmet>
      <Grid container spacing={4} className={classes.gridRoot}>
        <Grid item md={3} sm={12} xs={12}>
          <Sidebar>
            <Sidebar.Title user={user} variant="account" />
            <Sidebar.SectionContainer>
              {tabs.map((tab, i) => (
                <Sidebar.Section
                  tab={tab}
                  key={tab.name}
                  rootPath="/account-settings"
                  useSidebarSectionLink={(path) => path}
                />
              ))}
            </Sidebar.SectionContainer>
          </Sidebar>
        </Grid>

        <Grid item md={9} sm={12} xs={12} className={classes.gridMainBody}>
          <Switch>
            {tabs.map((tab, i) => (
              <SentryRoute exact path={`/account-settings/${tab.path}`} key={tab.name}>
                <Helmet>
                  <title>
                    {capitalize(tab.name)} settings - {ApiManager?.displayName ?? 'Ellipsis Drive'}
                  </title>
                </Helmet>
                <tab.component name={tab.name} />
              </SentryRoute>
            ))}

            <SentryRoute exact path="/account-settings/developers/new-oauth-app">
              <NewOAuthApp />
            </SentryRoute>

            <SentryRoute path="/account-settings">
              <Redirect to={`/account-settings/${tabs[0].path}`} />
            </SentryRoute>
          </Switch>
        </Grid>
      </Grid>
    </div>
  );
};

export default AccountManagement;
