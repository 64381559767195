import { createContext, useContext } from 'react';

const UploadContext = createContext(null);

export default UploadContext;

const useUploadContext = () => {
  const context = useContext(UploadContext);
  if (context === undefined) {
    throw new Error('useCount must be used within a CountProvider');
  }
  return context;
};

export { useUploadContext };
