import React, { createContext, useContext, useState, useMemo } from 'react';

const userInfoStateContext = createContext();
const userInfoDispatchContext = createContext();

const UserInfoDialogProvider = ({ children }) => {
  const [dialogOpen, setDialogOpen] = useState({ open: false, user: {}, picture: '' });

  const memoizedDialogOpen = useMemo(() => dialogOpen, [dialogOpen]);
  const memoizedSetDialogOpen = useMemo(() => setDialogOpen, [setDialogOpen]);

  return (
    <userInfoStateContext.Provider value={memoizedDialogOpen}>
      <userInfoDispatchContext.Provider value={memoizedSetDialogOpen}>{children}</userInfoDispatchContext.Provider>
    </userInfoStateContext.Provider>
  );
};

function useUserInfoState() {
  const context = useContext(userInfoStateContext);
  if (context === undefined) {
    throw new Error('useUserInfoState must be used within a UserInfoDialogProvider');
  }
  return context;
}

function useUserInfoDispatch() {
  const context = useContext(userInfoDispatchContext);
  if (context === undefined) {
    throw new Error('useUserInfoDispatch must be used within a UserInfoDialogProvider');
  }
  return context;
}

export { UserInfoDialogProvider, useUserInfoState, useUserInfoDispatch };
