import isEqual from 'lodash/isEqual';

export function validateUrl(value) {
  return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    value
  );
}

export const classicColors = [
  '#CDBA88',
  '#D0B084',
  '#D2AA6D',
  '#F9A900',
  '#E49E00',
  '#CB8F00',
  '#E19000',
  '#E88C00',
  '#AF8050',
  '#DDAF28',
  '#E3D9C7',
  '#DDC49B',
  '#E6D2B5',
  '#F1DD39',
  '#F6A951',
  '#FACA31',
  '#A48F7A',
  '#A08F65',
  '#F6B600',
  '#F7B500',
  '#BA8F4C',
  '#FFFF00',
  '#A77F0F',
  '#FF9C00',
  '#E2A300',
  '#F99A1D',
  '#EB9C52',
  '#8F8370',
  '#806440',
  '#F09200',
  '#DA6E00',
  '#BA481C',
  '#BF3922',
  '#F67829',
  '#E25304',
  '#FF4D08',
  '#FFB200',
  '#EC6B22',
  '#DE5308',
  '#D05D29',
  '#E26E0F',
  '#D5654E',
  '#923E25',
  '#FC5500',
  '#A72920',
  '#9B2423',
  '#9B2321',
  '#861A22',
  '#6B1C23',
  '#59191F',
  '#3E2022',
  '#6D342D',
  '#782423',
  '#C5856D',
  '#972E25',
  '#CB7375',
  '#D8A0A6',
  '#A63D30',
  '#CA555D',
  '#C63F4A',
  '#BB1F11',
  '#CF6955',
  '#FF2D21',
  '#FF2A1C',
  '#AB273C',
  '#CC2C24',
  '#A63437',
  '#701D24',
  '#A53A2E',
  '#816183',
  '#8D3C4B',
  '#C4618C',
  '#651E38',
  '#76689A',
  '#903373',
  '#47243C',
  '#844C82',
  '#9D8692',
  '#BB4077',
  '#6E6387',
  '#6A6B7F',
  '#304F6E',
  '#0E4C64',
  '#00387A',
  '#1F3855',
  '#191E28',
  '#005387',
  '#376B8C',
  '#2B3A44',
  '#215F78',
  '#004F7C',
  '#1A2B3C',
  '#0089B6',
  '#193153',
  '#637D96',
  '#007CAF',
  '#005B8C',
  '#048B8C',
  '#005E83',
  '#00414B',
  '#007577',
  '#222D5A',
  '#41698C',
  '#6093AC',
  '#20697C',
  '#0F3052',
  '#3C7460',
  '#366735',
  '#325928',
  '#50533C',
  '#024442',
  '#114232',
  '#3C392E',
  '#2C3222',
  '#36342A',
  '#27352A',
  '#4D6F39',
  '#6B7C59',
  '#2F3D3A',
  '#7C765A',
  '#474135',
  '#3D3D36',
  '#00694C',
  '#587F40',
  '#60993B',
  '#B9CEAC',
  '#37422F',
  '#8A9977',
  '#3A3327',
  '#008351',
  '#5E6E3B',
  '#005F4E',
  '#7EBAB5',
  '#315442',
  '#006F3D',
  '#237F52',
  '#45877F',
  '#7AADAC',
  '#194D25',
  '#04574B',
  '#008B29',
  '#00B51B',
  '#B3C43E',
  '#7A888E',
  '#8C979C',
  '#817863',
  '#797669',
  '#9A9B9B',
  '#6B6E6B',
  '#766A5E',
  '#745F3D',
  '#5D6058',
  '#585C56',
  '#52595D',
  '#575D5E',
  '#575044',
  '#4F5358',
  '#383E42',
  '#2F3234',
  '#4C4A44',
  '#808076',
  '#45494E',
  '#374345',
  '#928E85',
  '#5B686D',
  '#B5B0A1',
  '#7F8274',
  '#92886F',
  '#C5C7C4',
  '#979392',
  '#7A7B7A',
  '#B0B0A9',
  '#6B665E',
  '#989EA1',
  '#8E9291',
  '#4F5250',
  '#B7B3A8',
  '#8D9295',
  '#7E868A',
  '#C8C8C7',
  '#817B73',
  '#89693F',
  '#9D622B',
  '#794D3E',
  '#7E4B27',
  '#8D4931',
  '#70462B',
  '#724A25',
  '#5A3827',
  '#66332B',
  '#4A3526',
  '#5E2F26',
  '#4C2B20',
  '#442F29',
  '#3D3635',
  '#1A1719',
  '#A45729',
  '#795038',
  '#755847',
  '#513A2A',
  '#7F4031',
  '#E9E0D2',
  '#D6D5CB',
  '#ECECE7',
  '#2B2B2C',
  '#0E0E10',
  '#A1A1A0',
  '#868581',
  '#F1EDE1',
  '#27292B',
  '#F8F2E1',
  '#F1F1EA',
  '#29292A',
  '#C8CBC4',
  '#858583',
  '#787B7A',
];

export const fewColors = ['black', 'blue', 'green'];

export const colors = [
  '#b71c1c',
  '#f44336',
  '#880e4f',
  '#e91e63',
  '#4a148c',
  '#9c27b0',
  '#311b92',
  '#673ab7',
  '#1a237e',
  '#3f51b5',
  '#0d47a1',
  '#2196f3',
  '#01579b',
  '#03a9f4',
  '#006064',
  '#00bcd4',
  '#004d40',
  '#009688',
  '#194D33',
  '#4caf50',
  '#33691e',
  '#8bc34a',
  '#827717',
  '#cddc39',
  '#f57f17',
  '#ffeb3b',
  '#ff6f00',
  '#ffc107',
  '#e65100',
  '#ff9800',
  '#bf360c',
  '#ff5722',
  '#3e2723',
  '#795548',
];

export const graphColors = [
  '#e6194B',
  '#3cb44b',
  '#ffe119',
  '#4363d8',
  '#f58231',
  '#911eb4',
  '#42d4f4',
  '#f032e6',
  '#bfef45',
  '#fabed4',
  '#469990',
  '#dcbeff',
  '#9A6324',
  '#fffac8',
  '#800000',
  '#aaffc3',
  '#808000',
  '#ffd8b1',
  '#000075',
  '#a9a9a9',
  '#ffffff',
  '#000000',
];

export const colorsGreenToRed = [
  '#0062B1',
  '#009CE0',
  '#73D8FF',
  '#808900',
  '#A4DD00',
  '#A4DD00',
  '#FCDC00',
  '#FB9E00',
  '#D33115',
  '#9F0500',
  '#0062B1',
  '#009CE0',
  '#73D8FF',
  '#808900',
  '#A4DD00',
  '#A4DD00',
  '#FCDC00',
  '#FB9E00',
  '#D33115',
  '#9F0500',
];

export const getAvatarColor = ({ id, length = 2 }) => {
  if (!id) {
    return null;
  } else {
    let max = 36 * length;
    let offset = colors.length / max;
    let sum = 0;

    for (let i = 0; i < id.length && i < length; i++) {
      let regex = /[0-9]/;
      let isNumber = id[i].match(regex);

      if (!!isNumber) {
        sum += id.charCodeAt(i) - 48;
      } else {
        sum += id.charCodeAt(i) - 97;
      }
    }

    let index = Math.floor((sum > max ? max - sum : sum) / offset);

    return colors[index];
  }
};

export const getAvatarTextColor = (hex) => {
  if (!hex) {
    return null;
  } else {
    const threshold = 128;
    return (parseInt(hex.substring(1, 3), 16) * 299 +
      parseInt(hex.substring(3, 5), 16) * 587 +
      parseInt(hex.substring(5, 7), 16) * 114) /
      1000 >=
      threshold
      ? '#000000'
      : '#ffffff';
  }
};

const ACTION_MENU_ACTION = {
  view: 1,
  details: 2,
  manage: 3,
};

const MAIN_MODES = [
  'dashboard',
  'mapManagement',
  'orders',
  'developer',
  'viewer',
  'overview',
  'contact',
  // 'placeOrder',
  'faq',
  'registered',
  'tour',
  'search',
  'drive',
  'balance',
  'storage',
  'pricing',
  'plugins',
  'services',
  'filesManagement',
  'bookmarks',
  'disclosure',
  'thumbnail',
  'backoffice',
  'constellation',
]?.reduce((pv, key, index) => ({ ...pv, [key]: index }), {});

const getObjectDiff = (obj1, obj2) => {
  const diff = Object.keys(obj1).reduce((result, key) => {
    if (!obj2.hasOwnProperty(key)) {
      result.push(key);
    } else if (isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key);
      result.splice(resultKeyIndex, 1);
    }
    return result;
  }, Object.keys(obj2));

  return diff;
};

const getClass = (obj) => {
  return Object.prototype.toString.call(obj);
};

const objectTester = (a, b) => {
  if (a === b) {
    return true;
  }

  if (typeof a !== typeof b) {
    return false;
  }

  if (typeof a === 'number' && isNaN(a) && isNaN(b)) {
    return true;
  }

  var aClass = getClass(a);
  var bClass = getClass(b);

  if (aClass !== bClass) {
    console.log('different classes', aClass, bClass);
    return false;
  }

  if (aClass === '[object Boolean]' || aClass === '[object String]' || aClass === '[object Number]') {
    console.log(a.valueOf() === b.valueOf() ? null : [a.valueOf(), b.valueOf()]);
    return a.valueOf() === b.valueOf();
  }

  if (aClass === '[object RegExp]' || aClass === '[object Date]' || aClass === '[object Error]') {
    console.log(a.toString() === b.toString() ? null : [a.toString(), b.toString()]);

    return a.toString() === b.toString();
  }

  if (typeof a === 'object' || typeof a === 'function') {
    if (aClass === '[object Function]' && a.toString() !== b.toString()) {
      console.log('not same function string', a.toString(), b.toString());
      return false;
    }

    var aKeys = Object.keys(a);
    var bKeys = Object.keys(b);

    if (aKeys.length !== bKeys.length) {
      console.log('different amount of keys', aKeys, bKeys);
      return false;
    }

    if (
      !aKeys.every(function (key) {
        return b.hasOwnProperty(key);
      })
    ) {
      console.log(
        'different keys',
        aKeys.every(function (key) {
          return b.hasOwnProperty(key);
        })
      );
      return false;
    }

    return aKeys.every(function (key) {
      return objectTester(a[key], b[key]);
    });
  }
  return false;
};

const sharingTypesLong = {
  private: { name: 'private', label: 'private' },
  subscribable: { name: 'subscribable', label: 'subscribable' },
  linkSharing: { name: 'linkSharing', label: 'link sharing' },
  public: { name: 'public', label: 'public' },
};

const sharingTypes = Object.keys(sharingTypesLong);

const appUtility = {
  mainModes: MAIN_MODES,

  colors: colors,
  graphColors: graphColors,

  mainModeRoutes: {
    [MAIN_MODES.dashboard]: { route: '/' },
    [MAIN_MODES.mapManagement]: { route: '/settings' },
    [MAIN_MODES.orders]: { route: '/orders' },
    [MAIN_MODES.developer]: { route: '/developer' },
    [MAIN_MODES.viewer]: { route: '/view' },
    [MAIN_MODES.contact]: { route: '/contact' },
    [MAIN_MODES.faq]: { route: '/faq' },
    [MAIN_MODES.search]: { route: '/search' },
    [MAIN_MODES.backoffice]: { route: '/backoffice' },
    [MAIN_MODES.registered]: { route: '/notification' },
    [MAIN_MODES.tour]: { route: '/tour' },
    [MAIN_MODES.drive]: {
      route: '/drive/:type(me|shared|favorites|trash|search|external)',
      path: '/drive',
    },
    [MAIN_MODES.balance]: { route: '/balance' },
    [MAIN_MODES.plan]: { route: '/plan' },
    [MAIN_MODES.storage]: { route: '/storage' },
    [MAIN_MODES.pricing]: { route: '/pricing' },
    [MAIN_MODES.plugins]: { route: '/integrate' },
    [MAIN_MODES.services]: { route: '/services' },
    [MAIN_MODES.disclosure]: { route: '/responsible-disclosure-policy' },
    [MAIN_MODES.thumbnail]: { route: '/thumbnail' },
    [MAIN_MODES.constellation]: { route: '/constellation', title: 'Constellation' },
  },

  subModeKeys: {
    balance: 'balanceMode',
    orders: 'ordersMode',
    overview: 'overviewMode',
  },

  balanceModes: {
    ledger: 1,
    deposit: 2,
    withdrawal: 3,
  },

  orderStep: {
    selectAction: 1,
    selectArea: 2,
    editArea: 3,
    createOrder: 4,
    confirmOrder: 5,
    finished: 6,
  },

  searchMode: {
    byName: 1,
    byAtlas: 2,
  },

  searchProjectStatus: {
    any: 1,
    activeOnly: 2,
    finishedOnly: 3,
    pausedOnly: 4,
    warningOnly: 5,
  },

  sharingTypes: sharingTypes,
  sharingTypesLong: sharingTypesLong,

  actionMenuAction: ACTION_MENU_ACTION,

  adminUsername: 'admin',

  accessLevels: {
    viewMap: 100,
    aggregatedData: 200,
    viewGeoMessages: 300,
    addGeoMessages: 400,
    addGeoMessageImage: 410,
    addPrivateGeoMessage: 420,
    addPolygons: 500,
    submitRasterData: 515,
    addPolygonsRestricted: 525,
    viewPrivateElements: 550,
    deleteGeomessages: 600,
    alterOrDeletePolygons: 700,
    forms: 750,
    managePolygonLayers: 800,
    userManagement: 900,
    owner: 1000,

    mapPublicLevelOne: 300, // viewGeoMessages
    mapPublicLevelTwo: 525, // addPolygons

    min: 0,
    max: 1000,
  },

  orderLocalStorageKey: 'order',

  demoAreaIds: [
    'e082adb0-43bb-49f6-9990-af08e0ad19a8', // gran_chaco
    '09f5e90d-f011-437c-b789-3cedfbca80bb', // semarang
    '5d541467-51c6-44e5-b105-95cc336c1594', // Kangerlussuaq
  ],

  demoDataSourceType: {
    lowResSat: 'LowResSat',
    highResSat: 'HighResSat',
    droneImagery: 'DroneImagery',
    insarRadar: 'InSARRadar',
  },

  getDemoDataSourceType(datasource) {
    if (datasource === 'sentinel2RGBIR' || datasource === 'sentinel2') {
      return this.demoDataSourceType.lowResSat;
    }

    if (
      datasource === 'pleiades' ||
      datasource === 'pleiadesTasking' ||
      datasource === 'skySat' ||
      datasource === 'skySatExample'
    ) {
      return this.demoDataSourceType.highResSat;
    }

    if (datasource === 'droneRGB') {
      return this.demoDataSourceType.droneImagery;
    }

    if (datasource === 'insarAndDisplacement') {
      return this.demoDataSourceType.insarRadar;
    }
  },

  getDemoMaps(maps, type) {
    let retmaps = [];
    maps.forEach((map) => {
      if (map.atlases.includes('DemoMaps')) {
        if (this.getDemoDataSourceType(map.dataSource.name) === type) {
          retmaps.push(map);
        }
      }
    });
    return retmaps;
  },

  propSort: (a, b, prop = 'name') => {
    let aValue = a[prop];
    let bValue = b[prop];

    if (typeof aValue === 'string') {
      aValue = aValue.toLowerCase();
      bValue = bValue.toLowerCase();
    }

    if (aValue > bValue) {
      return 1;
    } else if (aValue < bValue) {
      return -1;
    } else {
      return 0;
    }
  },

  emptyProjectMap: (project) => {
    return {
      name: project.name,
      isEmptyProjectMap: true,
    };
  },

  getObjectDiff: getObjectDiff,

  testDiff: (prevProps, nextProps) => {
    const diffs = getObjectDiff(prevProps, nextProps);

    var same = true;

    diffs.forEach((key) => {
      if (same && !(typeof prevProps[key] === typeof nextProps[key] && typeof nextProps[key] === 'function')) {
        console.log({ key: key, nextProps: nextProps[key] });
        same = false;
      }
    });

    return same;
  },

  getClass: getClass,

  objectTester: objectTester,

  demoSteps: [
    {
      element: null,
      title: 'Ellipsis Drive',
      intro:
        'Ellipsis Drive is a cloud-based file system where you can manage, share and collaborate on spatial data. It works just like your drive for office docs.',
      mobileIntro: 'Use Ellipsis Drive to share spatial data!',
      url: '/drive/external?pathId=6454b8a2-a144-4723-b2cd-86c701ac7ff6',
      position: 'right',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass',
      hideBack: true,
    },
    {
      element: '.tutorialFolder',
      title: 'Folders',
      intro: `You can organize your layers in folders - looks familiar right?`,
      mobileIntro: 'Organize your layers in folders.',
      url: '/drive/external?pathId=6454b8a2-a144-4723-b2cd-86c701ac7ff6',
      position: 'bottom',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass',
    },
    {
      element: '.tutorialGridItemRaster',
      title: 'Raster layers',
      intro: `Make raster layers…`,
      url: '/drive/external?pathId=6454b8a2-a144-4723-b2cd-86c701ac7ff6',
      position: 'bottom',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass',
    },
    {
      element: '.tutorialGridItemVector',
      title: 'Vector layers',
      intro: `…or vector layers.`,
      url: '/drive/external?pathId=6454b8a2-a144-4723-b2cd-86c701ac7ff6',
      position: 'bottom',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass',
    },
    {
      element: '.tutorialGridItemVector',
      title: 'Open layer',
      intro: `Double-click to open your data in the Viewer.`,
      mobileIntro: 'Double-click to open your layer.',
      url: '/drive/external?pathId=6454b8a2-a144-4723-b2cd-86c701ac7ff6',
      position: 'bottom',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass',
    },
    {
      element: null,
      title: 'Your Drive',
      sidebar: true,
      intro: `Now let’s  upload some data ourselves!`,
      mobileIntro: 'Now let’s  upload some data!',
      url: '/drive/external?pathId=6454b8a2-a144-4723-b2cd-86c701ac7ff6',
      position: 'right',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass',
    },
    {
      element: '.tutorialNewProject',
      title: 'Create a layer',
      sidebar: true,
      intro: `You can create a new layer here. Let’s make a raster layer for now!`,
      mobileIntro: 'Create a raster or vector map here.',
      url: '/drive/external?pathId=6454b8a2-a144-4723-b2cd-86c701ac7ff6',
      position: 'right',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass',
    },
    {
      element: null,
      title: 'Configure raster layer',
      intro: 'Once your layer is created you can add a timestamp and upload your raster files.',
      mobileIntro: 'Add a timestamp and upload your files.',
      url: '/drive/external?pathId=1e40cc88-ba5f-4d0b-97b1-1a29cf3b05a8',
      position: 'right',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass',
    },
    {
      element: '.tutorialUpload',
      title: 'Upload your files',
      intro: 'You can drag-and-drop your files when clicking onto this icon. Easy as that!',
      mobileIntro: 'Drag-and-drop files when clicking on this icon.',
      url: '/drive/external?pathId=1e40cc88-ba5f-4d0b-97b1-1a29cf3b05a8',
      position: 'left',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass',
    },
    {
      element: '.tutorialProcess',
      title: 'Activate your data',
      intro: 'Now your raster data is ready to be supercharged into a fully interoperable, live map.',
      mobileIntro: 'Now your data can form a live map. ',
      url: '/drive/external?pathId=1e40cc88-ba5f-4d0b-97b1-1a29cf3b05a8',
      position: 'left',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass',
    },
    {
      element: '.tutorialOpenViewer',
      title: 'See Result',
      intro: 'We can now go to the viewer to see the result',
      mobileIntro: 'Look at your data in the Viewer.',
      url: '/drive/external?pathId=1e40cc88-ba5f-4d0b-97b1-1a29cf3b05a8',
      position: 'right',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass',
    },
    {
      element: null,
      title: 'Data activated',
      intro: 'Once processed, you can take a closer look at your data in the Viewer.',
      mobileIntro: 'Look at your data in the Viewer.',
      url: '/view?pathId=77239b78-ff95-4c30-a90e-0428964a0f00',
      position: 'right',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass',
    },
    {
      element: '.tutorialBrowse',
      title: 'Mix & match',
      intro: `You can enrich your data by importing other layers.`,
      mobileIntro: `You can enrich your data by importing other layers.`,
      url: '/view?pathId=77239b78-ff95-4c30-a90e-0428964a0f00',
      position: 'right',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass',
    },
    {
      element: '.tutorialViewer',
      title: 'Your spatial project in one glance',
      intro: `For example, here we imported the country boundaries that we saw earlier.`,
      mobileIntro: 'For e.g. we imported these country boundaries.',
      url: '/view?pathId=731f9632-d9a3-4deb-ac6a-5f88a056f730&state=425c9114-7a92-42c1-b9fe-ea1445751f47',
      position: 'left',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass',
    },
    {
      element: null,
      title: 'Seamless data integration for everyone, everywhere',
      intro:
        'The Viewer is just one way in which people can interact with your data. Ellipsis Drive content can be connected to any tool you choose. True interoperability achieved!',
      mobileIntro: 'Drive content can be connected to any tool! ',
      url: '/integrate',
      position: 'right',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass',
    },
    {
      element: null,
      title: 'Activate your data now',
      intro: 'Easy data management and consumption awaits you, your partners and your clients.',
      mobileIntro: 'Easy data management and consumption awaits you!',
      url: '/drive/me',
      position: 'right',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass',
      skipStepUser: true,
    },
    {
      element: '.tutorialRegister',
      title: 'Register',
      intro: 'Give it a quick try! and register now.',
      mobileIntro: 'Easy data management and consumption awaits you!',
      url: '/drive/me',
      position: 'right',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass',
      skipStepUser: true,
    },
  ],
  insureTech: [
    {
      element: null,
      title: 'Ellipsis Drive',
      intro:
        'Ellipsis Drive is the world’s leading solution for managing spatial data. Facilitating seamless use in all your processes. Both manual and automated.',
      url: '/drive/me?pathId=d8797997-1594-4064-b97d-7cb36c3c18ef',
      position: 'right',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass',
      hideBack: true,
    },
    {
      element: '.tutorialNewProject',
      title: 'Connect',
      intro: `Connect Ellipsis Drive to your database and have all of your spatial data activated for direct ingestion into your workflows. Zero data-wrangling needed.`,
      url: '/drive/me?pathId=d8797997-1594-4064-b97d-7cb36c3c18ef',
      position: 'bottom',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass',
    },
    {
      element: '.tutorialFolder',
      title: 'Organize',
      intro: `Spatial data can be organized in folders… `,
      url: '/drive/me?pathId=d8797997-1594-4064-b97d-7cb36c3c18ef',
      position: 'bottom',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass',
    },
    {
      title: 'Organize',
      intro: `…and subfolders that are very easy to structure, search through, and manage permissions for.`,
      url: '/drive/me?pathId=6452b662-6a26-40a3-ae08-44d2cbe25e88',
      position: 'bottom',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass',
    },
    {
      title: 'View',
      intro: `Your content can be viewed with the click of a button…`,
      url: '/view?pathId=38f881a7-fbc3-4f00-b506-1ff006b568c1',
      position: 'bottom',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass',
    },
    {
      element: null,
      title: 'View ',
      intro: `…and overlaid with other spatial data, with another click.`,
      url: '/view?pathId=38f881a7-fbc3-4f00-b506-1ff006b568c1&state=7116158c-7b2f-4396-8aca-f91cff2057e4',
      position: 'right',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass',
    },
    {
      title: 'Seamless Data Integration For Everyone',
      intro: `The Viewer is just one way in which you can work with spatial data. Your content can be connected to any tool or analysis pipeline. True interoperability achieved!`,
      url: '/integrate',
      position: 'right',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass',
    },
    {
      element: null,
      title: 'Activate your data now',
      intro: 'Easy data management and consumption awaits you, your team and your clients.',
      url: '/drive/me',
      position: 'right',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass',
      skipStepUser: true,
    },
    {
      element: '.tutorialRegister',
      title: 'Register',
      intro: 'Give it a quick try! and register now.',
      mobileIntro: 'Easy data management and consumption awaits you!',
      url: '/drive/me',
      position: 'right',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass',
      skipStepUser: true,
    },
  ],
  sampleShare: [
    {
      element: null,
      title: 'Ellipsis Drive',
      intro: 'Share your data samples with ease!',
      url: '/drive/external?pathId=6454b8a2-a144-4723-b2cd-86c701ac7ff6',
      position: 'bottom',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass',
      hideBack: true,
    },
    {
      element: null,
      title: 'New Layer',
      intro: 'Start out with creating a new layer',
      url: '/drive/external?pathId=6454b8a2-a144-4723-b2cd-86c701ac7ff6',
      position: 'bottom',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass',
      sidebar: true,
      hideBack: true,
    },
    {
      element: '.tutorialNewProject',
      title: 'New layer',
      intro: `Click here to create a new layer.`,
      url: '/drive/external?pathId=6454b8a2-a144-4723-b2cd-86c701ac7ff6',
      position: 'bottom',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass',
      sidebar: true,
    },
    {
      element: null,
      title: 'Pick a name',
      intro: `You can pick a layer type and name.`,
      url: '/drive/external?pathId=6454b8a2-a144-4723-b2cd-86c701ac7ff6',
      position: 'bottom',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass',
      newMap: true,
    },
    {
      element: '.tutorialNewMap',
      title: 'Pick a name',
      intro: 'In this example we choose type raster.',
      url: '/drive/external?pathId=6454b8a2-a144-4723-b2cd-86c701ac7ff6',
      position: 'bottom',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass',
    },
    {
      element: null,
      title: 'Upload files',
      intro: 'A raster layer will be created',
      url: '/drive/external?pathId=0ebbbfed-facd-4e0d-98a6-11787f02a9f3',
      position: 'bottom',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass',
      closeMap: true,
    },
    {
      element: '.tutorialUpload',
      title: 'Upload your files',
      intro: 'Now click here to upload your files',
      url: '/drive/external?pathId=0ebbbfed-facd-4e0d-98a6-11787f02a9f3',
      position: 'bottom',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass',
      closeMap: true,
    },
    {
      element: '.tutorialOpenViewer',
      title: 'See Result',
      intro: 'Once your uploads are done you can view them as a live map!',
      url: '/drive/external?pathId=0ebbbfed-facd-4e0d-98a6-11787f02a9f3',
      position: 'bottom',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass',
    },
    {
      element: null,
      title: 'Data activated',
      intro: 'You can now look at your data in the Viewer.',
      url: '/view?pathId=33d7505b-ca23-4505-b746-f17e60d185a3&state=205b3fee-9306-466f-9e87-9182592585e0',
      position: 'bottom',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass',
    },
    {
      element: '.tutorialLayers',
      title: 'Time to share',
      layerDetails: true,
      intro: 'In the layers tab you can find the share button.',
      url: '/view?pathId=33d7505b-ca23-4505-b746-f17e60d185a3&state=205b3fee-9306-466f-9e87-9182592585e0',
      position: 'bottom',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass',
    },
    {
      element: '.tutorialShare',
      title: 'Open share',
      intro: 'You can click the share button to open sharing settings.',
      url: '/view?pathId=33d7505b-ca23-4505-b746-f17e60d185a3&state=205b3fee-9306-466f-9e87-9182592585e0',
      position: 'bottom',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass',
    },
    {
      element: null,
      title: 'Share settings',
      intro: 'Here you can invite people or edit public permissions.',
      url: '/view?pathId=33d7505b-ca23-4505-b746-f17e60d185a3&state=205b3fee-9306-466f-9e87-9182592585e0',
      position: 'bottom',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass',
      share: true,
    },
    {
      element: '.tutorialLinkShare',
      title: 'Link sharing',
      intro: 'You can click here to place your layer on link sharing.',
      url: '/view?pathId=33d7505b-ca23-4505-b746-f17e60d185a3&state=205b3fee-9306-466f-9e87-9182592585e0',
      position: 'bottom',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass',
      share: true,
    },
    {
      element: '.tutorialShareView',
      title: 'Share view',
      intro: 'Click here to share your current view. Just send them the URL.',
      url: '/view?pathId=33d7505b-ca23-4505-b746-f17e60d185a3&state=205b3fee-9306-466f-9e87-9182592585e0',
      position: 'bottom',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass',
      closeShare: true,
    },
    {
      element: null,
      title: 'Done',
      intro: 'All done! Your data is activated and shared!',
      url: '/drive/external?pathId=6454b8a2-a144-4723-b2cd-86c701ac7ff6',
      position: 'bottom',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass',
    },
    {
      element: '.tutorialFolder',
      title: 'Organize',
      intro: 'Have more samples? Create some folders to get organized. ',
      url: '/drive/external?pathId=6454b8a2-a144-4723-b2cd-86c701ac7ff6',
      position: 'bottom',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass',
    },
    {
      element: null,
      title: 'Try it yourself',
      intro: 'Easy data sharing awaits you!',
      url: '/drive/me',
      position: 'bottom',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass',
      skipStepUser: true,
    },
    {
      element: '.tutorialRegister',
      title: 'Register',
      intro: 'Give it a quick try and register now!',
      url: '/drive/me',
      position: 'bottom',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass',
      skipStepUser: true,
    },
  ],

  tutorialSteps: {
    general: [
      {
        element: null,
        title: 'Ellipsis Drive',
        intro: 'In your Drive you can create, share and collaborate on spatial data.',
        position: 'top',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
      },
      {
        element: '.tutorialNewProject',
        title: 'New Layer',
        intro: 'You can create layers to store your vector or raster data in.',
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
        sidebar: true,
      },
      {
        element: '.tutorialMyProjects',
        title: 'My Drive',
        intro: `All your created layers will be placed in 'my Drive'.`,
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
        sidebar: true,
      },
      {
        element: '.tutorialSharedWithMe',
        title: 'Shared with me',
        intro: `In 'Shared with me', you can find all items that have been shared with you. If you have been granted admin rights you can create new items in shared folders.`,
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
        sidebar: true,
      },
      {
        element: '.tutorialFavorites',
        title: 'Favorites',
        intro: `'Favorites' contains references to all layers that you favorited.`,
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
        sidebar: true,
      },
      {
        element: '.tutorialTrash',
        title: 'Trash',
        intro: `All removed layers and folders are moved to 'Trash'. Mind that clearing your trash is irreversible.`,
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
        sidebar: true,
      },
      {
        element: '.tutorialStorage',
        title: 'Storage',
        intro: `Here you can manage your storage limit and other details of your plan.`,
        position: 'right',
        tooltipClass: 'myTooltipClass',
        sidebar: true,
        highlightClass: 'myHighlightClass',
      },
      {
        element: '.tutorialLibrary',
        title: 'Library',
        intro: 'In the library you can search through all public layers of the wider Ellipsis Drive community.',
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
        sidebar: true,
      },
      {
        element: '.tutorialPlugins',
        title: 'Integrate',
        intro:
          'Here you can find documentation and tutorials on how to use the Drive in your favorite frameworks. Ranging from QGIS and ArcGIS to Python and Leaflet..',
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
        sidebar: true,
      },
    ],
    drive: [
      {
        element: null,
        title: 'Ellipsis Drive',
        intro: 'In your Drive you can create, share and collaborate on spatial data.',
        position: 'top',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
        sidebar: true,
      },
      {
        element: '.tutorialNewProject',
        title: 'New Layer',
        intro: 'You can create layers to store your vector or raster data in.',
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
        sidebar: true,
      },
      {
        element: '.tutorialNewProject',
        title: 'New Folder',
        intro:
          'You can also create folders to organize your created Layers. You can also use folders to easily share content with others.',
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
        sidebar: true,
        skipStepNoUser: true,
      },
      {
        element: '.tutorialMyProjects',
        title: 'My Drive',
        intro: `All your created layers will be placed in 'my Drive'.`,
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
        sidebar: true,
      },
      {
        element: '.tutorialSharedWithMe',
        title: 'Shared with me',
        intro: `In 'Shared with me', you can find all items that have been shared with you. If you have been granted admin rights you can create new items in shared folders.`,
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
        sidebar: true,
      },
      {
        element: '.tutorialFavorites',
        title: 'Favorites',
        intro: `'Favorites' contains references to all layers that you favorited.`,
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
        sidebar: true,
      },
      {
        element: '.tutorialTrash',
        title: 'Trash',
        intro: `All removed layers and folders are moved to 'Trash'. Mind that clearing your trash is irreversible.`,
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
        sidebar: true,
      },
      {
        element: '.tutorialStorage',
        title: 'Storage',
        intro: `Here you can manage your storage limit and other details of your plan.`,
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
        sidebar: true,
      },
      {
        element: '.tutorialLibrary',
        title: 'Library',
        intro:
          'In the library you can search through all layers that you have access to. This includes public layers from the user community.',
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
        sidebar: true,
      },
      {
        element: '.tutorialPlugins',
        title: 'Integrate',
        intro:
          'Here you can find documentation and tutorials on how to use the Drive in your favorite frameworks. Ranging from QGIS and ArcGIS to Python and Leaflet..',
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
        sidebar: true,
      },
    ],
    settings: [
      {
        element: '.tutorialCloseViewer',
        title: 'Close settings',
        intro: 'Click here to return to your Drive',
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
      },
      {
        element: '.tutorialGeneral',
        title: 'General',
        intro: `In the 'General' tab you can set some metadata like hashtags, attribution and title. You can also transfer your layer to another account.`,
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
      },
      {
        element: '.tutorialIntegrate',
        title: 'Integrate',
        intro: `In the Integrate tab you see all information you need to integrate your layer.`,
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
      },
      {
        element: '.tutorialSharing',
        title: 'Sharing',
        intro: `In the 'Sharing' tab you can add other people to your layer. You can also make your layer public or enable link sharing.`,
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
      },
      {
        element: '.tutorialSubscriptions',
        title: 'Subscription Plans',
        intro: `Other people can get paid access to your layer when you add subscriptions. People pay for their access on a monthly basis and all subscription fees will be added to your balance.`,
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
      },
      {
        element: '.tutorialStyles',
        title: 'Styles',
        intro: `In the styles tab you can add and edit the ways your data looks!`,
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
      },
      {
        element: '.tutorialProperties',
        title: 'Properties',
        intro:
          'Geometries can have properties. Here you can manage the properties geometries may have. You can also add indexes so you can filter on these properties',
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
        mapType: 'shape',
      },
      {
        element: '.tutorialBands',
        title: 'Bands',
        intro: 'A raster consists of bands, you can see and rename the bands here.',
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
        mapType: 'map',
      },
      {
        element: '.tutorialUsage',
        title: 'Usage',
        intro: 'You can track which users have used your layer and when.',
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
      },
    ],
    files: [
      {
        element: '.tutorialUpload',
        title: 'Upload',
        intro: 'Click here to upload your spatial data files.',
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
      },
      {
        element: '.tutorialOpenViewer',
        title: 'Open viewer',
        intro: 'Click here to see your files as a live map.',
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
      },
      {
        element: null,
        title: 'Multiple files',
        intro:
          'You can upload multiple files to the same timestamp. The files will be shown as one unit in the viewer. To make a good mosaic the files need to be similar.',
        sidebar: true,
        position: 'left',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
      },
      {
        element: '.tutorialNewProject',
        title: 'Add other layers',
        intro:
          'If you would like to add other files that are not similar you shoud create an additional layers in your drive to upload these files to. No worries in the viewer you can overlay any layers you wish.',
        sidebar: true,
        position: 'left',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
      },
      {
        element: '.tutorialShare',
        title: 'Invite others!',
        intro: 'Invite others by their username or email.',
        sidebar: false,
        position: 'left',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
      },
      {
        element: '.tutorialLink',
        title: 'Sent a link',
        intro: 'You can sent others a link to your layer.',
        sidebar: false,
        position: 'left',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
      },
    ],
    view: [
      {
        element: '.tutorialCloseViewer',
        title: 'Close viewer',
        intro: 'Click here to go back to your Drive.',
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
      },
      {
        element: '.tutorialBrowse',
        title: 'Browse',
        intro: `In the browse tab you can import layers from other layers in your Drive.`,
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
      },
      {
        element: '.tutorialLayers',
        title: 'Layers',
        intro: 'In this tab you can manage the present layers.',
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
      },
      {
        element: '.tutorialLayerDetails',
        title: 'Layer Details',
        intro:
          'Click here to manage a particular layer. Anything for this layer from styling, analyzing to downloading can be found here.',
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
      },
      {
        title: 'Share view',
        element: '.tutorialShareView',
        intro: 'With this button you can create a sharable link of your current view.',
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
      },
      {
        element: '.tutorial3D',
        title: '2D and 3D View',
        intro: 'You can switch between 2D and 3D view (on Desktop).',
        position: 'left',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
        skipStepMobile: true,
      },
    ],
    library: [
      {
        element: null,
        title: 'Community Catalog',
        intro:
          'With Community Catalog you can search through all data that you have access to, being it on your own Drive or publicly hosted.',
        position: 'bottom',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
      },
      {
        element: '.tutorialRasterOrVector',
        title: 'Search by type',
        intro: 'Here you can select whether you are looking for raster or vector data.',
        position: 'bottom',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
      },
      {
        element: '.tutorialBounds',
        title: 'Area of interest',
        intro: 'Here you can select a region of interest',
        position: 'bottom',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
      },
      {
        element: '.tutorialUser',
        title: 'Search by owner',
        intro: 'You can search for items by the username of the owner.',
        position: 'bottom',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
      },
    ],
  },
};

export default appUtility;
