import React, { useRef } from 'react';

import { Dialog, DialogContent } from '@mui/material';
import DialogTitle from 'ReusableComponents/DialogTitle/DialogTitle';
import MarkdownEditor from './MarkdownEditor';
import { useCallback } from 'react';

export default function MarkdownDialog({ open, close, submit, title, ...MarkdownProps }) {
  const elementRef = useRef();

  const cb = useCallback(
    (res) => {
      typeof submit === 'function' && submit(res);
      typeof close === 'function' && close();
    },
    [close, submit]
  );

  return (
    <Dialog open={open} onClose={close} fullWidth sx={{ overflowX: 'hidden' }}>
      <DialogTitle title={title} close={close} />
      <DialogContent sx={{ pb: 0, mb: 1 }}>
        <MarkdownEditor elementRef={elementRef} cb={cb} {...MarkdownProps} />
      </DialogContent>
    </Dialog>
  );
}
