export const isValidTimestamp = (t, m) => {
  if (t?.status !== 'finished') {
    return { available: false, reason: 'Timestamp not active' };
  } else if (t.availability.blocked) {
    return { available: false, reason: t.availability.reason };
  }

  return { available: true };
};

export const isValidMap = (m) => {
  if (!m) {
    return { available: false, type: 'noLayer', reason: 'No Layer' };
  }
  if (m.type !== 'map' && m.type !== 'shape' && m.type !== 'pointCloud') {
    return { available: true };
  }
  if (m.disabled) {
    return { available: false, type: 'disabled', reason: 'Layer disabled' };
  }
  if (m.deleted) {
    return { available: false, type: 'deleted', reason: 'Layer trashed' };
  }
  if (m.yourAccess.accessLevel === 0) {
    return { available: false, type: 'noAccess', reason: 'No access' };
  }

  if (m?.timestamps?.length === 0) {
    return { available: false, type: 'noTimestamps', reason: 'No timestamps' };
  }

  // console.log(m);

  if (m.timestamps.filter((t) => isValidTimestamp(t, m).available).length === 0) {
    if (m.timestamps.find((t) => t.availability?.reason === 'relocation')) {
      return { available: false, type: 'relocating', reason: 'Relocating layer' };
    } else if (m.timestamps.find((t) => t.availability?.reason === 'reindexing')) {
      return { available: false, type: 'reindexing', reason: 'Reindexing layer' };
    } else if (m.timestamps.find((t) => t.availability?.reason === 'fast upload')) {
      return { available: false, type: 'fastUpload', reason: 'Activating files' };
    } else if (m.type === 'map' && m.timestamps.filter((t) => t.totalSize > 0).length === 0) {
      return { available: false, type: 'noUploads', reason: 'No uploads' };
    } else if (m.timestamps.find((t) => t.status === 'activating')) {
      return { available: false, type: 'activating', reason: 'Activating files' };
    } else if (m.timestamps.find((t) => t.status === 'pausing')) {
      return { available: false, type: 'pausing', reason: 'Pausing files' };
    } else if (m.timestamps.find((t) => t.status === 'created')) {
      return { available: false, type: 'created', reason: 'No active timestamps' };
    } else {
      return { available: false, type: 'noTimestamps', reason: 'No timestamps' };
    }
  }

  if (m?.styles?.length === 0) {
    return { available: false, type: 'noStyles', reason: 'No styles' };
  }

  return { available: true };
};
