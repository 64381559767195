import React from 'react';

import { UploadPage } from '../core';

const fileFormats = [
  'kml',
  'shape',
  'dxf',
  'las',
  'geojson',
  'gpkg',
  'pbf',
  'gpx',
  'gml',
  'csv',
  'gdb',
  'mesh',
  'obj',
  'fgb',
  'tab',
  'jpg',
  'png',
  'jpeg',
  'parquet',
];

// This is the object that controls formats and which are accepted and how they are uploaded
export const uploadFormats = [
  {
    formats: ['cpgg', 'dbf', 'prj', 'shp', 'shx', 'cpg'],
    type: 'error',
    errorMessage: 'Please zip the shape files and upload the resulting archive instead',
  },
  {
    formats: ['gdbtable', 'gdbtablx', 'spx', 'freelist', 'gdbindexes', 'horizon', 'atx'],
    type: 'error',
    errorMessage: 'Please zip the geodatabase fiels and upload the resulting archive instead',
  },
  {
    formats: ['json', 'geojson', 'JSON', 'GeoJSON'],
    type: 'success',
    // `uploadAs`, when specified, means that all of the `formats` should be uploaded as `uploadAs`
    // in this case for example, it means that json will be uploaded as a geojson
    uploadAs: 'geojson',
  },
  {
    formats: ['tab', 'TAB'],
    type: 'success',
    uploadAs: 'tab',
  },
  {
    formats: ['mesh', 'obj'],
    type: 'success',
    uploadAs: 'mesh',
  },
  {
    formats: ['pbf', 'PBF'],
    type: 'success',
    uploadAs: 'pbf',
  },
  {
    formats: ['connection'],
    type: 'success',
    uploadAs: 'connection',
  },
  {
    formats: ['gpx', 'GPX'],
    type: 'success',
    uploadAs: 'gpx',
  },
  {
    formats: ['jpg', 'jpeg', 'png', 'JPG', 'JPEG', 'PNG'],
    type: 'success',
    uploadAs: 'IMAGE',
  },
  {
    formats: ['parquet', 'geoparquet'],
    type: 'success',
    uploadAs: 'parquet',
  },
  {
    formats: ['connection'],
    type: 'success',
    uploadAs: 'connection',
  },
  {
    formats: ['zip', 'gpk', 'csv', 'dxf', 'kml', 'gml', 'mesh', 'gpkg', 'fgb', 'gz'],
    type: 'success',
  },
];

const VectorUpload = ({ captureId, setUploadOpen, setAutoProcessOpen }) => {
  return (
    <UploadPage
      fileFormats={fileFormats}
      setUploadOpen={setUploadOpen}
      setAutoProcessOpen={setAutoProcessOpen}
      uploadFormats={uploadFormats}
      type="geometry"
      captureId={captureId}
    />
  );
};

export default VectorUpload;
