import React from 'react';

import CombinedMenuItem from '../Components/CombinedMenuItem/CombinedMenuItem';
import { BorderColor } from '@mui/icons-material';

const EditProcess = ({ path, setDialog, onClose }) => {
  return (
    <CombinedMenuItem
      icon={<BorderColor />}
      label="Edit process"
      action={() => {
        onClose();
        setDialog({
          type: 'process',
          edit: true,
          path,
        });
      }}
      openDialog
    />
  );
};

export default EditProcess;
