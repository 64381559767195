import React from 'react';
import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';

import MainSettings from './MainSettings';
import RasterSettings from './RasterSettings';
import ShapeSettings from './ShapeSettings';

const useStyles = makeStyles({ name: 'UploadSettings' })((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const UploadSettings = ({
  uploadedFileFormats,
  formatsForceEspg,
  autoDetectEspg,
  handleAutoDetectEspgChange,
  type,
  noDataValue,
  handleNoDataValue,
  fastUpload,
  setFastUpload,
  downsample,
  setDownsample,
  dateColumns,
  setDateColumns,
  datePatterns,
  setDatePatterns,
}) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <MainSettings
        uploadedFileFormats={uploadedFileFormats}
        formatsForceEspg={formatsForceEspg}
        autoDetectEspg={autoDetectEspg}
        handleAutoDetectEspgChange={handleAutoDetectEspgChange}
        noDataValue={noDataValue}
        type={type}
        handleNoDataValue={handleNoDataValue}
      />
      {type === 'raster' ? (
        <RasterSettings />
      ) : type === 'vector' ? (
        <ShapeSettings
          setFastUpload={setFastUpload}
          fastUpload={fastUpload}
          setDownsample={setDownsample}
          downsample={downsample}
          dateColumns={dateColumns}
          setDateColumns={setDateColumns}
          datePatterns={datePatterns}
          setDatePatterns={setDatePatterns}
        />
      ) : null}
    </div>
  );
};

UploadSettings.propTypes = {
  formatsForceEspg: PropTypes.arrayOf(PropTypes.string).isRequired,
  autoDetectEspg: PropTypes.object,
  handleAutoDetectEspgChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  dateColumns: PropTypes.arrayOf(PropTypes.string),
  setDateColumns: PropTypes.func.isRequired,
  datePatterns: PropTypes.arrayOf(PropTypes.string),
  setDatePatterns: PropTypes.func.isRequired,
};

export default UploadSettings;
