import React from 'react';

import { Typography, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useHistory /* , Link */ } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useCurrentFolder } from 'Drive/CurrentFolderContext';

const useStyles = makeStyles()((theme, _params, classes) => ({
  container: {
    marginTop: '6px',
  },
  subSection: {
    paddingLeft: '16px',
    textDecoration: 'none',
    display: 'flex',
    '&:not(:last-child)': {
      marginBottom: '4px',
    },
    borderRadius: '4px',
    color: theme.palette.text.primary,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
    fontWeight: '500',
    [`&.${classes.selected}`]: {
      color: theme.palette.secondary.main,
      fontWeight: '600',
    },
    [`&.${classes.disabled}`]: {
      pointerEvents: 'none',
      color: theme.palette.text.disabled,
    },
  },
  name: {
    fontWeight: '500',
    [`&.${classes.selected}`]: {
      fontWeight: '600',
    },
  },
  disabled: {},
  selected: {},
}));

const SidebarSectionSubs = ({ subs, ...otherProps }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container} onClick={(e) => e.stopPropagation()}>
      {subs.map((sub) => (
        <SectionSub key={sub.name} sub={sub} {...otherProps} />
      ))}
    </div>
  );
};

SidebarSectionSubs.propTypes = {
  subs: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const SectionSub = ({ sub, useSidebarSectionLink, rootPath }) => {
  const { classes, cx } = useStyles();
  const history = useHistory();
  const link = useSidebarSectionLink(`${rootPath}/${sub.path}`);
  const { changeMainMode } = useCurrentFolder();
  return (
    <Button
      key={sub.name}
      className={cx(
        classes.subSection,
        history.location.pathname.split('/').slice(2, 4).join('/') === sub.path && classes.selected,
        sub.disabled && classes.disabled
      )}
      onClick={() => changeMainMode(link)}
    >
      <Typography
        className={cx(
          classes.name,
          history.location.pathname.split('/').slice(2, 4).join('/') === sub.path && classes.selected
        )}
      >
        {sub.name}
      </Typography>
    </Button>
  );
};

SectionSub.propTypes = {
  sub: PropTypes.shape({
    path: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  useSidebarSectionLink: PropTypes.func.isRequired,
  rootPath: PropTypes.string.isRequired,
};

export default SidebarSectionSubs;
