import React, { useCallback /* , useEffect, useRef */ } from 'react';
import { withRouter } from 'react-router';

// import { Room, Timeline } from '@mui/icons-material';
// import { makeStyles } from 'tss-react/mui';

// import { faDrawPolygon } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// import { RoundButtonWithLabel } from 'ReusableComponents';
import { AddFeaturePropertiesForm } from './AddFeaturePropertiesForm';
import { useCurrentFolder } from 'Drive/CurrentFolderContext';

/* const useMapStyles = makeStyles()((theme, { isEdit }) => ({
  buttonsWrapper: {
    display: 'grid',
    gridTemplateColumns: isEdit ? '1fr 1fr 1fr' : '1fr 1fr 1fr 1fr',
    gridGap: '1.5rem',
  },
  shapeEditTitle: { marginLeft: theme.spacing(2), fontWeight: 'bold', lineHeight: 1, marginBottom: theme.spacing(2) },
})); */

/* const BUTTONS = [
  { type: 'point', label: 'Point', icon: <Room /> },
  { type: 'line', label: 'Line', icon: <Timeline /> },
  { type: 'polygon', label: 'Polygon', icon: <FontAwesomeIcon icon={faDrawPolygon} /> },
]; */

const AddFeature = withRouter(
  ({
    addGeometry,
    draw,
    hidePane,
    isEdit = false,
    isExternal,
    isPresentationMode,
    isUpload,
    layer,
    layerInfo,
    makeBackup,
    onStartDraw,
    recoverOriginal,
    selectedElement,
    selectFeature,
  }) => {
    // const { classes } = useMapStyles({ isEdit });
    const { changeMainMode } = useCurrentFolder();
    const setEditing = (active) => {
      if (active && isEdit && makeBackup) {
        makeBackup();
      }

      if (active) {
        selectFeature(
          selectedElement.feature,
          'draw',
          selectedElement.mapId,
          selectedElement.timestampId,
          selectedElement.accessLevel,
          null,
          true,
          null,
          true
        );

        let geometryType = selectedElement.feature.geometry.type;
        if (geometryType.includes('Polygon')) {
          handleButtonClick('polygon');
        }
        if (geometryType.includes('Line')) {
          handleButtonClick('line');
        }
        if (geometryType.includes('Point')) {
          handleButtonClick('point');
        }
      }

      if (!active && isEdit) {
        recoverOriginal();
      }

      if (!active && !isEdit) {
        selectFeature(null);
      }

      if (!active) {
        onStartDraw('stop');
      }
    };

    const internalOnStartDraw = useCallback(
      (type) => {
        if (type === 'stop') {
          onStartDraw(type);
        } else if (isEdit) {
          onStartDraw(
            type,
            'draw',
            selectedElement.mapId,
            selectedElement.timestampId,
            selectedElement.accessLevel,
            selectedElement.feature.properties,
            null,
            selectedElement.feature
          );
        } else {
          let properties = {};
          //No item should be selected when drawing
          onStartDraw(type, 'draw', null, null, null, properties);
        }
      },
      [selectedElement, isEdit, onStartDraw]
    );

    const handleButtonClick = useCallback(
      (type) => {
        if (draw?.active && type === draw?.type) {
          internalOnStartDraw('stop');
        } else if (type === 'upload') {
          changeMainMode('drive');
        } else {
          const cb = () => {
            internalOnStartDraw(type);
          };
          cb();
          //internalOnStartDraw('stop', null, null, null, null, null, cb);
        }
      },
      [internalOnStartDraw, draw?.active, draw?.type, changeMainMode]
    );

    return (
      <>
        {/* !!isEdit && selectedElement?.type === 'draw' && !isExternal && !isUpload && false && (
          <React.Fragment>
            <div className={classes.buttonsWrapper}>
              {BUTTONS.map(({ type, label, icon }) => (
                <RoundButtonWithLabel
                  key={label}
                  icon={icon}
                  data-test-id={'addFeatureButton-' + label}
                  label={label}
                  onClick={() => {
                    handleButtonClick(type);
                  }}
                  selected={draw?.active && type === draw?.type}
                />
              ))}
            </div>
          </React.Fragment>
        ) */}
        <AddFeaturePropertiesForm
          layer={layer}
          isEdit={isEdit}
          layerInfo={layerInfo}
          isExternal={isExternal}
          isUpload={isUpload}
          makeBackup={makeBackup}
          hidePane={hidePane}
          selectedElement={selectedElement}
          setEditing={setEditing}
          addGeometry={addGeometry}
          onStartDraw={onStartDraw}
          isPresentationMode={isPresentationMode}
        />
      </>
    );
  }
);

export default AddFeature;
