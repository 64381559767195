export const CLOUDS = {
  ellipsis: 'ellipsis',
  satsure: 'satsure',
  catalystEarth: 'catalyst-earth',
  tnc: 'tnc',
  maxar: 'maxar',
  esa: 'esa',
};

export const TYPES = {
  dev: 'dev',
  acc: 'acc',
  prod: 'prod',
};

const ApiUrls = [
  {
    url: 'https://acc.api.ellipsis-drive.com',
    cloud: CLOUDS.ellipsis,
    idProvider: 'google',
    displayName: 'Acceptance',
    apiType: TYPES['acc'],
  },
  {
    url: 'https://dev.api.ellipsis-drive.com',
    cloud: CLOUDS.ellipsis,
    idProvider: 'google',
    displayName: 'Development',
    apiType: TYPES['dev'],
  },
  {
    url: 'https://api.ellipsis-drive.com',
    cloud: CLOUDS.ellipsis,
    idProvider: 'google',
    displayName: 'Ellipsis Drive',
    apiType: TYPES['prod'],
  },
  {
    url: 'https://api.sy1.ellipsis-drive.com',
    cloud: CLOUDS.ellipsis,
    idProvider: 'google',
    displayName: 'Ellipsis Drive',
  },
  {
    url: 'https://api.satsure.ellipsis-drive.com',
    cloud: CLOUDS.satsure,
    idProvider: 'google',
    displayName: 'Satsure',
  },
  {
    url: 'https://api.catalyst-earth.ellipsis-drive.com',
    cloud: CLOUDS.catalystEarth,
    displayName: 'Catalyst Earth',
    idProvider: 'saml',
  },
  {
    url: 'https://api.maxar.ellipsis-drive.com',
    cloud: CLOUDS.maxar,
    idProvider: null,
    displayName: 'Maxar',
  },
  {
    url: 'https://api.dokdata.ellipsis-drive.com',
    cloud: CLOUDS.dokdata,
    idProvider: null,
    displayName: 'Dokdata',
  },
  {
    url: 'https://api.esa.ellipsis-drive.com',
    cloud: CLOUDS.esa,
    idProvider: null,
    displayName: 'ESA',
  },
  {
    url: 'https://api.tnc.ellipsis-drive.com',
    cloud: CLOUDS.tnc,
    idProvider: null,
    displayName: 'TNC',
  },
];

export default ApiUrls;
