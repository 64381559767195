import appUtility from 'AppUtility';
import React, { createContext, useContext, useState, useCallback, useRef, useMemo, useEffect } from 'react';
import bbox from 'geojson-bbox';

import { PANE_TYPES, usePaneContent, usePaneContentFuncs } from '../DetailsPane/PaneContentContext';
import { useLayers } from 'Viewer/ViewerContexts/ViewerLayersContext';
import { useMainContext } from 'ReusableComponents';
import { useMainViewer } from 'Viewer/ViewerContexts/MainViewerContext';
import { useMinimalAuth } from 'hooks';
import ApiManager from 'ApiManager';
import { centerToTiles } from '../_2DControl/GeoJsonControl/GeoJsonControl';

const selectedElementContext = createContext();

export const SelectedElementProvider = ({ children }) => {
  const { layersInfo, layers } = useLayers();
  const user = useMinimalAuth();

  const [selectedElement, setSelectedElementN] = useState();
  const [compareWith, setCompareWith] = useState({ layerId: null, feature: null, active: false });
  const [draw, setDrawN] = useState({ key: 0, type: null, active: false });
  const paneContent = usePaneContent();
  const [addGeometry, setAddGeometry] = useState();
  const [deleteGeometry, setDeleteGeometry] = useState();

  const { onOpenSnackbar } = useMainContext();
  const { viewerConfig, propertyTableSelect, setPropertyTable } = useMainViewer();
  const { openPane, setContent, hidePane } = usePaneContentFuncs();

  const callB = useRef({ N: 0, cb: null });
  const setSelectedElement = useCallback((element, cb) => {
    if (cb) {
      callB.current = { N: callB.current.N + 1, cb: cb };
    }
    setSelectedElementN(element);
  }, []);

  const done = useRef(0);
  useEffect(() => {
    if (done.current !== callB.current.N) {
      done.current = callB.current.N;
      if (callB.current.cb) {
        callB.current.cb();
      }
    }
  }, [selectedElement]);

  const callBdraw = useRef({ N: 0, cb: null });
  const setDraw = useCallback((newDraw, cb) => {
    if (cb) {
      callBdraw.current = { N: callB.current.N + 1, cb: cb };
    }
    setDrawN(newDraw);
  }, []);

  const doneDraw = useRef(0);
  useEffect(() => {
    if (doneDraw.current !== callBdraw.current.N) {
      doneDraw.current = callBdraw.current.N;
      if (callBdraw.current.cb) callBdraw.current.cb.cb();
    }
  }, [draw]);

  const selectFeature = useCallback(
    (
      feature,
      type,
      mapId,
      timestampId,
      accessLevel,
      cb,
      ignorePane = false,
      ignoreSwitch = false,
      ignoreDraw = false
    ) => {
      if (propertyTableSelect) {
        if (type === 'propertyTable' || mapId === propertyTableSelect.id) {
          const colors = appUtility.colors;

          setPropertyTable((old) => {
            const page = old?.pages?.[0] ?? [];
            const index = page?.findIndex((p) => p?.properties?.id === feature.properties?.id);

            const n = Math.floor(colors.length * Math.random());
            feature.properties.color = feature.properties.color = colors[n];

            return {
              show: true,
              mapId: propertyTableSelect.id,
              layerId: propertyTableSelect.id,
              access: layers.find((l) => l.id === propertyTableSelect.id).yourAccess,
              pages: [
                index >= 0 ? page?.filter((p) => p?.properties?.id !== feature.properties?.id) : [...page, feature],
              ],
            };
          });
        }
        return;
      }
      if (compareWith.active) {
        if (mapId === compareWith.layerId) {
          setCompareWith({ active: false, feature: feature });
          onOpenSnackbar({ level: 'success', content: 'Adding this reference point to the graph' });
        } else {
          setCompareWith({ active: false });
          onOpenSnackbar({
            level: 'error',
            content: 'You need compare it with a geometry from the same layer',
          });
        }
        return;
      }

      if (
        (!!viewerConfig?.hideSidePane && type !== 'measure') ||
        (!ignoreDraw && feature && draw.active) ||
        (feature &&
          !ignoreDraw &&
          ['draw', 'download', 'sample', 'vectorSample'].includes(selectedElement?.type) &&
          type !== 'draw')
      ) {
        return;
      }

      //type can be layer, deleted, geoMessage, version, external, sample, draw, upload and download
      //upload and geoMessage are depricated
      if (!feature) {
        let callback = () => {
          if (typeof cb === 'function') {
            cb();
          }
        };
        window.parent.postMessage(
          JSON.stringify({
            action: 'deselectFeature',
          }),
          '*'
        );
        setSelectedElement(null, callback);
      } else {
        let element = {
          type,
          feature,
          mapId,
          timestampId,
          accessLevel,
        };
        let callback = () => {
          if (typeof cb === 'function') {
            cb();
          }
          let w = 0;
          if (window.innerWidth < 1000) {
            w = 200;
          }

          setTimeout(() => {
            // console.log('pancecontent', this.props.paneContent);
            if (paneContent?.pane?.type === PANE_TYPES.Geoprocessing) {
              return;
            }
            if ((type === 'layer' || type === 'deleted' || type === 'external' || type === 'upload') && !ignoreSwitch) {
              if (!ignorePane) {
                openPane();
              }
              if (![PANE_TYPES.feature, PANE_TYPES.featureMessages].includes(paneContent?.pane?.type)) {
                setContent({ type: PANE_TYPES.feature });
              }
            } else if (type === 'draw' || type === 'sample' || type === 'vectorSample') {
              if (!ignorePane) {
                openPane();
              }
            }
          }, w);
        };
        // console.log('posting message', feature);
        window.parent.postMessage(
          JSON.stringify({
            action: 'featureClick',
            data: { pathId: mapId, timestampId: timestampId, feature: feature },
          }),
          '*'
        );
        setSelectedElement(element, callback);
      }
    },
    [
      layers,
      onOpenSnackbar,
      propertyTableSelect,
      openPane,
      paneContent,
      setContent,
      selectedElement,
      viewerConfig,
      draw.active,
      compareWith,
      setSelectedElement,
      setPropertyTable,
    ]
  );

  const geometryAdd = useCallback(
    (mapId, id, timestampId, movedFromLayer, select = true) => {
      let style = layersInfo[mapId].style;

      let body = {
        featureIds: [id],
        returnType: 'all',
        allowTrashed: true,
        style: style,
        applyStyle: false,
      };

      ApiManager.get(`/v3/path/${mapId}/vector/timestamp/${timestampId}/featuresByIds`, body, user)
        .then(async (result) => {
          if (result.result.features.length > 0) {
            let feature = result.result.features[0];

            let bounds = bbox(feature);
            bounds = { xMin: bounds[0], xMax: bounds[2], yMin: bounds[1], yMax: bounds[3] };
            let tiles = await centerToTiles((bounds.xMin + bounds.xMax) / 2, (bounds.yMin + bounds.yMax) / 2);

            let accessLevel = layers.find((l) => l.id === mapId).yourAccess.accessLevel;
            if (select) {
              selectFeature(feature, 'layer', mapId, timestampId, accessLevel, null, true, false, true);
            } else {
              selectFeature(null);
            }

            setAddGeometry({
              mapId: mapId,
              id: id,
              timestampId: timestampId,
              movedFromLayer: movedFromLayer,
              feature: feature,
              accessLevel: accessLevel,
              tiles: tiles,
              number: addGeometry ? addGeometry.number + 1 : 1,
            });
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
    [addGeometry, layersInfo, user, layers, selectFeature]
  );

  const geometryDelete = useCallback(
    (mapId, id, timestampId, layerName) => {
      setDeleteGeometry({
        mapId,
        id,
        timestampId,
        layerName,
        number: deleteGeometry ? deleteGeometry.number + 1 : 1,
      });
    },
    [deleteGeometry]
  );

  const onStartDraw = useCallback(
    (type, elementType, mapId, timestampId, access, properties, cb, initialFeature) => {
      //polyogn, bbox, line or point
      // draw, download, analyse
      if (type === 'stop') {
        // cleanDrawingGroupExternally(this, true);
        let newDraw = { ...draw };
        newDraw.key = draw.key + 1;
        newDraw.active = false;
        let w = 0;
        if (window.innerWidth < 1000) {
          w = 300;
        }
        setTimeout(() => {
          setDraw(newDraw, cb);
        }, w);
      } else {
        if (draw.active) {
          return;
        }

        if (initialFeature) {
          onOpenSnackbar({
            content: 'Click the geometry on the map to update it',
            level: 'info',
          });
        } else if (type === 'rectangle') {
          onOpenSnackbar({
            content: 'Draw a ' + type + ' on the map, click once to start and another time to finish',
            level: 'info',
          });
        } else {
          onOpenSnackbar({
            content: 'Draw a ' + type + ' on the map, double click to finish',
            level: 'info',
          });
        }
        let key = draw.key + 1;
        window.innerWidth < 1000 && hidePane();
        setDraw(
          {
            key: key,
            type: type,
            elementType: elementType,
            active: true,
            mapId: mapId,
            timestampId: timestampId,
            accessLevel: access,
            initialFeature: initialFeature,
            properties: properties,
          },
          cb
        );
      }
    },
    [draw, hidePane, onOpenSnackbar, setDraw]
  );

  const value = useMemo(
    () => ({
      selectedElement,
      addGeometry,
      selectFeature,
      geometryAdd,
      compareWith,
      onStartDraw,
      geometryDelete,
      deleteGeometry,
      draw,
      setSelectedElement,
      setCompareWith,
    }),
    [
      deleteGeometry,
      geometryDelete,
      addGeometry,
      selectedElement,
      selectFeature,
      compareWith,
      setCompareWith,
      setSelectedElement,
      onStartDraw,
      draw,
      geometryAdd,
    ]
  );

  return <selectedElementContext.Provider value={value}>{children}</selectedElementContext.Provider>;
};

export function useSelectedElement() {
  const context = useContext(selectedElementContext);
  if (context === undefined) {
    throw new Error('useSelectedElement must be used within a SelectedElementProvider');
  }
  return context;
}

export function withSelectedElement(Component) {
  return function WrapperComponent(props) {
    const context = useSelectedElement();
    return <Component {...props} {...context} />;
  };
}

export function withSelectedElementContext(Component) {
  return function WrapperComponent(props) {
    return (
      <SelectedElementProvider>
        <Component {...props} />
      </SelectedElementProvider>
    );
  };
}
