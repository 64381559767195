const tileToFeature = (x, y, zoom) => {
  let pi = Math.PI;

  let comp4 = Math.pow(2, zoom);
  let comp5 = pi / 2;
  let comp6 = 360 / (2 * pi);

  let xMin = (x * 360) / comp4 - 180;
  let xMax = ((x + 1) * 360) / comp4 - 180;
  let yMin = comp6 * (2 * Math.atan(Math.exp((-2 * (pi * (y + 1))) / comp4 + pi)) - comp5);
  let yMax = comp6 * (2 * Math.atan(Math.exp((-2 * (pi * y)) / comp4 + pi)) - comp5);

  let coords = [
    [
      [xMin, yMin],
      [xMax, yMin],
      [xMax, yMax],
      [xMin, yMax],
      [xMin, yMin],
    ],
  ];

  return {
    type: 'Feature',
    properties: {
      tileX: x,
      tileY: y,
      zoom: zoom,
    },
    geometry: {
      type: 'Polygon',
      coordinates: coords,
    },
  };
};

const getBoundsToTiles = (bounds, zoom) => {
  zoom = zoom + 1;
  zoom = Math.max(0, zoom);

  let xMin = Math.max(bounds.xMin, -180);
  let xMax = Math.min(bounds.xMax, 180);
  let yMin = Math.max(bounds.yMin, -85);
  let yMax = Math.min(bounds.yMax, 85);

  let zoomComp = Math.pow(2, zoom);
  let comp1 = zoomComp / 360;
  let pi = Math.PI;
  let comp2 = 2 * pi;
  let comp3 = pi / 4;

  let tileXMin = Math.floor((xMin + 180) * comp1);
  let tileXMax = Math.floor((xMax + 180) * comp1);
  let tileYMin = Math.floor((zoomComp / comp2) * (pi - Math.log(Math.tan(comp3 + (yMax / 360) * pi))));
  let tileYMax = Math.floor((zoomComp / comp2) * (pi - Math.log(Math.tan(comp3 + (yMin / 360) * pi))));

  let tiles = [];
  let x = Math.max(0, tileXMin - 1);
  while (x <= Math.min(2 ** zoom - 1, tileXMax + 1)) {
    let y = Math.max(0, tileYMin - 1);
    while (y <= Math.min(2 ** zoom - 1, tileYMax + 1)) {
      let feature = tileToFeature(x, y, zoom);
      tiles.push(feature);
      y = y + 1;
    }
    x = x + 1;
  }

  return tiles;
};

export { tileToFeature, getBoundsToTiles };
