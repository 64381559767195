import React, { useRef, useState } from 'react';

import { Button, ButtonGroup, Divider, ListItemIcon, ListItemText, ListSubheader, Menu, MenuItem } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles({ name: 'StyledGroupButton' })((theme, _params, classes) => ({
  root: {
    flexShrink: 0,
    overflow: 'hidden',
    transition: theme.transitions.create('border-radius'),
    [`&.${classes.open}`]: { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 },
    '& .MuiButtonGroup-grouped.MuiButton-contained:not(:last-of-type)': { borderColor: theme.palette.primary.light },
  },
  paper: { paddingTop: theme.spacing(0.5), paddingBottom: theme.spacing(0.5) },
  menu: { padding: 0 },
  menuItem: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(3),
    '& .MuiListItemIcon-root': {
      minWidth: theme.spacing(4.5),
      '&>svg': { height: theme.spacing(3), width: theme.spacing(3) },
    },
    [`&.${classes.secondary}`]: { paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) },
  },
  secondary: {},
  open: {},
  icon: {},
  subheader: { lineHeight: 1.5 },
}));

const StyledGroupButton = ({
  actions = [],
  defaultActionProps: propsDefaultAction,
  buttonGroupProps: propsButtonGroup,
  buttonProps: propsButton,
  menuProps: propsMenu,
  menuItemProps: propsMenuItem,
  openCb = () => {},
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef();

  const {
    index: defaultActionIndex = 0,
    startIcon: defaultActionStartIcon = true,
    labelProp: defaultActionLabelProp = 'label',
    ...defaultActionProps
  } = { ...propsDefaultAction };

  const {
    ref: buttonGroupRef,
    className: buttonGroupClassName,
    sx: buttonGroupSX,
    ...buttonGroupProps
  } = { ...propsButtonGroup };

  const { className: buttonClassName, ...buttonProps } = { ...propsButton };

  const { paperProps: propsPaper, menuListProps: propsMenuList, ...menuProps } = { ...propsMenu };
  const { className: menuListClassName, ...menuListProps } = { ...propsMenuList };
  const { className: paperClassName, sx: paperSX, ...paperProps } = { ...propsPaper };
  const { listItemIconProps, listItemTextProps: propsListItemText, ...menuItemProps } = { ...propsMenuItem };
  const {
    primaryTypographyProps: typographyPropsPrimary,
    secondaryTypographyProps: typographyPropsSecondary,
    ...listItemTextProps
  } = { ...propsListItemText };
  const { className: primaryClassName, ...primaryTypographyProps } = { ...typographyPropsPrimary };
  const { className: secondaryClassName, ...secondaryTypographyProps } = { ...typographyPropsSecondary };

  const { classes: styles, cx } = useStyles();

  const handleOpen = () => {
    setOpen(true);
    openCb(true);
  };
  const handleClose = () => {
    setOpen(false);
    openCb(false);
  };

  const handleClick = (f) => {
    handleClose();
    typeof f === 'function' && f();
  };

  const DefaultActionButton = ({ fullWidth, className }) => (
    <Button
      className={className}
      onClick={() => handleClick(actions?.[defaultActionIndex]?.onClick)}
      fullWidth={fullWidth}
      startIcon={!!defaultActionStartIcon && actions?.[defaultActionIndex]?.icon}
      variant="outlined"
      {...buttonProps}
      {...defaultActionProps}
    >
      {actions?.[defaultActionIndex]?.[defaultActionLabelProp]}
    </Button>
  );

  if (actions?.length <= 1) return <DefaultActionButton />;

  return (
    <>
      <ButtonGroup
        ref={buttonGroupRef || anchorRef}
        className={cx(styles.root, open && styles.open, buttonGroupClassName)}
        sx={{ ...buttonGroupSX }}
        {...buttonGroupProps}
      >
        <DefaultActionButton fullWidth className={cx(buttonClassName)} />
        <Button className={cx(buttonClassName)} size="small" onClick={handleOpen} {...buttonProps}>
          <ArrowDropDown />
        </Button>
      </ButtonGroup>
      <Menu
        open={open}
        anchorEl={anchorRef.current}
        disablePortal
        onClose={handleClose}
        PaperProps={{
          className: cx(styles.paper, paperClassName),
          sx: { minWidth: anchorRef?.current?.offsetWidth, ...paperSX },
          ...paperProps,
        }}
        MenuListProps={{ className: cx(styles.menu, menuListClassName), ...menuListProps }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        {...menuProps}
      >
        {actions?.map((a, i) => {
          const { type, label, onClick, icon } = { ...a };
          return type === 'subheader' ? (
            [
              <Divider key={'divider_' + i} sx={{ lineHeight: 4 }} />,
              <ListSubheader key={'subheader_' + i} className={cx(styles.subheader)}>
                {label}
              </ListSubheader>,
            ]
          ) : (
            <MenuItem
              key={label + '_' + i}
              className={cx(styles.menuItem /* , secondary && styles.secondary */)}
              onClick={() => handleClick(onClick)}
              {...menuItemProps}
            >
              <ListItemIcon {...listItemIconProps}>{icon}</ListItemIcon>
              <ListItemText
                primary={label}
                primaryTypographyProps={{ className: cx(primaryClassName), ...primaryTypographyProps }}
                secondaryTypographyProps={{ className: cx(secondaryClassName), ...secondaryTypographyProps }}
                {...listItemTextProps}
              />
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default StyledGroupButton;
