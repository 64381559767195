import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { useAuth } from '../hooks';

import { useLocation } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { useCurrentFolder } from 'Drive/CurrentFolderContext';

import { Collapse, Drawer, IconButton, List, useMediaQuery, Typography } from '@mui/material';
import { Alert, AlertTitle } from '@mui/material';

import {
  Power,
  MeetingRoom,
  Delete,
  Explore,
  Folder,
  FolderShared,
  FolderSpecial,
  ExpandMore,
  Storage,
  Bookmark,
} from '@mui/icons-material';

import SidebarButton from './SidebarButton/SidebarButton';
import ApiManager from 'ApiManager';
import { useMainContext } from 'ReusableComponents';

import AppUtility from 'AppUtility';
import { NAVBAR_BREAK } from 'App';

const DRAWER_WIDTH = 320;

const useStyles = makeStyles({
  name: 'Sidebar',
})((theme) => ({
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
    maxWidth: '100vw',
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
    padding: theme.spacing(2),
    marginTop: 64,
    height: 'calc(100% - 64px)',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    maxWidth: '100vw',
    backgroundColor: theme.palette.primary.surface,
    borderRight: 'none',
  },
  titleContainer: {
    display: 'grid',
    gridGap: 6,
  },
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  menuGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.75),
    padding: 0,

    '& .MuiListItemSecondaryAction-root': {
      zIndex: 3,
      pointerEvent: 'none',
    },
  },
  alertTitle: {
    display: 'flex',
    gap: 6,
    alignItems: 'center',
    justifyContent: 'space-evenly',
    flexWrap: 'nowrap',
    flexDirection: 'row',
    '& .MuiAlertTitle-root': {},
  },
}));

const pageSidebarHidden = [
  '/view',
  'settings',
  '/login',
  '/forgot-password',
  '/register',
  '/verify',
  '/registration',
  '/account-settings',
  '/oauth/consent',
  '/thumbnail',
  AppUtility.mainModeRoutes[AppUtility.mainModes.constellation].route,
];

const Sidebar = (props) => {
  const { classes } = useStyles();
  const location = useLocation();
  const [title, setTitle] = useState('Ellipsis Drive');
  const [errorOpen, setErrorOpen] = useState(false);
  const { currentFolderInfo, mainMode } = useCurrentFolder();
  const { tourOpen } = useMainContext();
  const handleErrorOpenClick = () => {
    setErrorOpen((oldOpen) => !oldOpen);
  };
  const user = useAuth();

  const queryBreak = useMediaQuery((theme) => theme.breakpoints.up(NAVBAR_BREAK));

  useEffect(() => {
    const newLocations = handleLocation({ location: location, selectedMap: props.selectedMap });

    setTitle(newLocations.title);
  }, [location, props.selectedMap]);

  const isSpecialPage = pageSidebarHidden.some((page) => location.pathname.includes(page));

  return (
    <React.Fragment>
      <Helmet>
        <title>
          {title?.length === 0 ? ApiManager?.displayName ?? 'Ellipsis Drive' : title}
          {title?.length !== 0 ? ` - ${ApiManager?.displayName ?? 'Ellipsis Drive'}` : ''}
        </title>
      </Helmet>
      {!props.hideNavbar && (
        <Drawer
          className={classes.drawer}
          variant={!queryBreak || isSpecialPage ? 'temporary' : 'permanent'}
          classes={{
            paper: classes.drawerPaper,
          }}
          open={(queryBreak && !isSpecialPage) || props.openSidebar ? true : false}
          anchor="left"
          onClose={() => props.onOpenSidebar(false)}
        >
          <div className={classes.pageContainer}>
            <div className={'tutorialNewProject'}>
              <SidebarButton
                title={'New'}
                type="newProject"
                user={user}
                onRepresenting={(e) => {
                  const canCreateHere =
                    tourOpen ||
                    (mainMode === '/drive/me' && (!currentFolderInfo || currentFolderInfo.type === 'folder')) ||
                    (mainMode === '/drive/shared' &&
                      currentFolderInfo &&
                      currentFolderInfo.type === 'folder' &&
                      currentFolderInfo.yourAccess.accessLevel >= ApiManager.newAccessLevel['admin']);

                  if (!canCreateHere || !user) {
                    props.handleOpenNewProjectDialog();
                  } else {
                    props.setNewCaptureKey(e);
                    props.onOpenSidebar(false);
                  }
                }}
              />
            </div>

            <List className={classes.menuGroup}>
              <SidebarButton className={'tutorialMyProjects'} title={'My Drive'} url={'/drive/me'} icon={<Folder />} />
              <SidebarButton
                className={'tutorialSharedWithMe'}
                title={'Shared with me'}
                url={'/drive/shared'}
                icon={<FolderShared />}
              />
              <SidebarButton
                className={'tutorialFavorites'}
                title={'Favorites'}
                url={'/drive/favorites'}
                icon={<FolderSpecial />}
              />
              <SidebarButton className={'tutorialTrash'} url={'/drive/trash'} title={'Trash'} icon={<Delete />} />
            </List>

            <List className={classes.menuGroup}>
              <SidebarButton
                className={'tutorialLibrary'}
                title={'Community Catalog'}
                url={'/search'}
                icon={<Explore />}
              />
            </List>

            <List className={classes.menuGroup}>
              <SidebarButton className={'tutorialPlugins'} title={'Integrate'} url={'/integrate'} icon={<Power />} />
            </List>

            <List className={classes.menuGroup}>
              <React.Fragment>
                <SidebarButton
                  className={'tutorialStorage'}
                  title={'Storage'}
                  url={AppUtility.mainModeRoutes[AppUtility.mainModes.storage].route}
                  icon={<Storage />}
                  type="storage"
                  user={user}
                  error={user?.disabled}
                />

                {(user?.disabled === true || user?.stripe3dSecureInfo?.url) && (
                  <Alert severity="error">
                    <div className={classes.alertTitle}>
                      <AlertTitle>
                        {user?.stripe3dSecureInfo?.url
                          ? 'You need to approve your payment'
                          : 'Your content is temporarily blocked'}
                      </AlertTitle>
                      <IconButton onClick={handleErrorOpenClick}>
                        <ExpandMore />
                      </IconButton>
                    </div>
                    <Collapse in={errorOpen}>
                      <Typography variant="body2">
                        {user?.stripe3dSecureInfo?.url
                          ? 'Go to the storage page and approve your payment'
                          : !user?.plan?.maxNegative || user?.plan.maxNegative === 0
                          ? 'Because your balance is negative. '
                          : `Because your balance is below your maximum negative of ${user?.plan.maxNegative.toLocaleString(
                              undefined,
                              { style: 'currency', currency: 'EUR' }
                            )} `}
                        {user?.stripe3dSecureInfo?.url
                          ? ''
                          : `Add some balance or couple a credit card to resolve this issue`}
                      </Typography>
                    </Collapse>
                  </Alert>
                )}
              </React.Fragment>
            </List>

            {/* false && (
              <List className={classes.menuGroup}>
                <SidebarButton
                  className={'tutorialPricing'}
                  title={'Pricing'}
                  url={'/pricing'}
                  icon={<LocalOffer />}
                  type="pricing"
                />
              </List>
            ) */}

            {user && (user.username === 'admin' || user.username === 'backoffice') && (
              <List className={classes.menuGroup}>
                <SidebarButton title={'Backoffice'} url={'/backoffice'} icon={<MeetingRoom />} />
              </List>
            )}
          </div>
        </Drawer>
      )}
    </React.Fragment>
  );
};

const handleLocation = (props) => {
  const { location, selectedMap } = props;

  const pathname = location.pathname;
  let title = '';

  //#region Title if-statements
  if (pathname.includes('/settings')) {
    title = 'Settings';
    if (selectedMap) {
      title += ' - ' + selectedMap.name;
    }
  } else if (pathname.includes('/drive/me')) {
    title = 'My Drive';
  } else if (pathname.includes('/files')) {
    title = 'Files';
    if (selectedMap) {
      title += ' - ' + selectedMap.name;
    }
  } else if (pathname.includes('/drive/shared')) {
    title = 'Shared with Me';
  } else if (pathname.includes('/drive/favorites')) {
    title = 'Favorites';
  } else if (pathname.includes('/drive/search')) {
    title = 'Search Drive';
  } else if (pathname.includes('/trash')) {
    title = 'Trash';
  } else if (pathname.includes('/search')) {
    title = 'Community Catalog';
  } else if (pathname.includes('/balance')) {
    title = 'Balance';
  } else if (pathname.includes('/services')) {
    title = 'Community Offerings';
  } else if (pathname.includes('/plan')) {
    title = 'Your Plan';
  } else if (pathname.includes('/billing')) {
    title = 'Billing';
  } else if (pathname.includes('/pricing')) {
    title = 'Pricing';
  } else if (pathname.includes('/storage')) {
    title = 'Storage';
  } else if (pathname.includes('/integrate')) {
    title = 'Integrate';
  } else if (pathname.includes('/developer')) {
    title = 'Developer Center';
  } else if (pathname.includes('/view')) {
    title = 'View';
    if (selectedMap) {
      title += ' - ' + selectedMap.name;
    }
  } else if (pathname.includes('/faq')) {
    title = 'FAQ';
  } else if (pathname.includes(AppUtility.mainModeRoutes[AppUtility.mainModes.constellation].route)) {
    title = AppUtility.mainModeRoutes[AppUtility.mainModes.constellation].title;
  }

  return {
    title: title,
  };
};
export default Sidebar;
