import React from 'react';
import { TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';

const useStyles = makeStyles()((theme, _params, classes) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const ProjectNameStep = ({ projectName, handleProjectNameChange, projectNameError, MAX_LENGTH }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <TextField
        value={projectName}
        onChange={handleProjectNameChange}
        variant="outlined"
        label="Name"
        className={classes.textField}
        autoFocus
        fullWidth
        error={!!projectNameError.error}
        helperText={
          projectNameError?.exists
            ? projectNameError.error
            : `${projectName?.length}/${MAX_LENGTH} ${!!projectNameError ? projectNameError.errorName : ''}`
        }
      />
    </div>
  );
};

ProjectNameStep.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  projectName: PropTypes.string.isRequired,
  handleProjectNameChange: PropTypes.func.isRequired,
  projectNameError: PropTypes.string,
};

export default ProjectNameStep;
