import React from 'react';

import { makeStyles } from 'tss-react/mui';
import { Box } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

import { TRANCHES } from './ActionsPerMinuteUtils';

import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';

const fontSize = 14;

const useStyles = makeStyles({ name: 'ActionsPerMinuteComponent' })((theme) => ({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: 6,
    fontSize: fontSize,
    lineHeight: fontSize + 'px',
    flexGrow: 1,
    '& .MuiSvgIcon-root': { marginRight: theme.spacing(1) },
    transition: theme.transitions.create('opacity'),
  },
  label: {
    display: 'flex',
    gap: 6,
    justifyContent: 'space-between',
    flexGrow: 1,
  },
}));

const testSame = (prevProps, nextProps) => {
  if (
    prevProps?.actionsPerMinute !== nextProps?.actionsPerMinute ||
    prevProps?.skeleton !== nextProps?.skeleton ||
    prevProps?.disabled !== nextProps?.disabled ||
    prevProps?.className !== nextProps?.className ||
    !isEqual(prevProps?.labelProps, nextProps?.labelProps)
  ) {
    return false;
  }
  return true;
};

const ActionsPerMinuteComponent = ({
  actionsPerMinute,
  skeleton,
  suppressInherit,
  disabled,
  style,
  className: userClassName = '',
  labelProps = {},
}) => {
  const { classes: styles, cx } = useStyles();

  const contentObj = !skeleton && (TRANCHES.find((x) => x.ApM === actionsPerMinute) ?? TRANCHES[TRANCHES.length - 1]);

  return (
    <Box className={cx(styles.root, userClassName)}>
      {contentObj?.icon ? contentObj.icon : <Skeleton variant="circular" width={fontSize} height={fontSize} />}
      <div className={styles.label}>
        {contentObj?.label ? (
          <Box component="span" {...labelProps}>
            {contentObj.label} {disabled && !suppressInherit ? ' (inherited)' : null}
          </Box>
        ) : (
          <Skeleton variant="text" width={52} height={fontSize} />
        )}
      </div>
    </Box>
  );
};

export default React.memo(ActionsPerMinuteComponent, testSame);

ActionsPerMinuteComponent.propTypes = {
  actionsPerMinute: PropTypes.number,
  skeleton: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
};
