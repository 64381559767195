import React from 'react';

import { withStyles } from 'tss-react/mui';
import { Menu } from '@mui/material';

const StyledMenu = withStyles(
  (props) => (
    <Menu
      elevation={0}
      // getContentAnchorEl={null} //The getContentAnchorEl prop was removed to simplify the positioning logic.
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      {...props}
    />
  ),
  {
    paper: {
      border: '1px solid #d3d4d5',
      marginTop: '4px',
      maxWidth: '300px',
    },
  }
);

export default StyledMenu;
