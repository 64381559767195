import React, { useCallback, useMemo } from 'react';

import { Checkbox, List, ListItem, ListItemButton, ListItemText, ListSubheader } from '@mui/material';

import PARAMETERS from './LinkUtils';
import { makeStyles } from 'tss-react/mui';

const HEADING_HEIGHT = 5;

const useStyles = makeStyles({ name: 'CustomBookmarkConfiguration' })((theme) => ({
  root: { overflow: 'auto', maxHeight: '75vh', [theme.breakpoints.up('sm')]: { maxHeight: '40vh' } },
}));

const CustomBookmarkConfiguration = ({ configuration, setConfiguration }) => {
  const { classes } = useStyles();

  const search = useMemo(() => new URLSearchParams(configuration), [configuration]);

  const recursiveRender = useCallback(
    (obj, level = -1, subLevel) => (
      <List disablePadding dense key={`list_${level}_${subLevel}`}>
        {obj?.map((p, i) => {
          const isHeading = ['optionHeading', 'heading'].includes(p?.type);
          const isOption = ['optionHeading', 'option'].includes(p?.type);
          const checked = !!search?.has(p?.parameter);

          return p?.type === 'heading' ? (
            <ListSubheader
              key={`heading_${level}_${i}`}
              sx={(theme) => ({ lineHeight: theme.spacing(HEADING_HEIGHT), zIndex: 2 })}
            >
              {p?.label}
            </ListSubheader>
          ) : !!p?.label ? (
            <ListItem
              key={`item_${level}_${i}`}
              disablePadding
              sx={
                isHeading
                  ? (theme) => ({
                      position: 'sticky',
                      top: p?.type === 'heading' ? 0 : theme.spacing(HEADING_HEIGHT),
                      zIndex: 1,
                      background: 'white',
                    })
                  : undefined
              }
            >
              <ListItemButton
                sx={{ pl: 2 + level * 1.5 }}
                onClick={() => {
                  setConfiguration(p?.parameter);
                }}
              >
                <ListItemText primary={p?.label} secondary={p?.secondary} sx={{ pr: 6 }} />
                {isOption && (
                  <Checkbox
                    edge="end"
                    checked={checked}
                    sx={{ position: 'absolute', right: 0, transform: 'translateX(-50%)' }}
                  />
                )}
              </ListItemButton>
            </ListItem>
          ) : (
            recursiveRender(p, level + 1, i)
          );
        })}
      </List>
    ),
    [search, setConfiguration]
  );

  return <div className={classes.root}>{recursiveRender(PARAMETERS)}</div>;
};

export default CustomBookmarkConfiguration;
