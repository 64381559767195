import React from 'react';

import { Paper } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';
import ApiManager from 'ApiManager';
import { CLOUDS } from 'ApiUrls';
import { LinkStyleButton } from 'ReusableComponents';

const GAP = 2;

const useStyles = makeStyles({ name: 'FullScreenLayout' })((theme) => ({
  root: {
    height: '100vh',
    width: '100vw',
    backgroundColor: theme.palette.background.default,
    zIndex: 3000,
    position: 'fixed',
    top: 0,
    left: 0,
    overflowY: 'auto',
  },
  container: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '100px',
    marginBottom: '30px',
    width: 'min(360px, 100vw)',
    height: 'fit-content',
    position: 'relative',
    padding: '32px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down('lg')]: {
      marginTop: '50px',
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(GAP),
      padding: '32px 16px',
      boxSizing: 'border-box',
      width: `calc(100vw - ${theme.spacing(GAP * 2)})`,
      marginTop: '50px',
    },
  },
  ellipsisLogo: {
    position: 'absolute',
    left: '50%',
    top: 0,
    transform: 'translate(-50%, -50%)',
    height: '70px',
    width: '70px',
    borderRadius: '50%',
    border: `4px solid ${theme.palette.background.default}`,
  },
  linksContainer: {
    display: 'grid',
    width: 'min(364px, 100vw)',
    margin: '0 auto',
    gridTemplateColumns: 'repeat(3, auto)',
    alignItems: 'start',
    gap: theme.spacing(3),
  },
  link: { textDecoration: 'none' },
}));

const FullScreenLayout = ({
  className = '',
  style = {},
  paperClassName = '',
  paperStyle = {},
  children,
  Bottom = null,
}) => {
  const { classes } = useStyles();

  return (
    <div
      className={`${classes.root} ${className}`}
      style={{
        backgroundImage: `linear-gradient(180deg, rgba(13, 46, 76, 0.6), rgba(13, 46, 76, 0.6)), url(${process.env.PUBLIC_URL}/images/error/spacebg.jpg)`,
        ...style,
      }}
    >
      <Paper className={`${classes.container} ${paperClassName}`} style={paperStyle}>
        <img
          className={classes.ellipsisLogo}
          src={
            ApiManager.cloud === CLOUDS.ellipsis
              ? '/ellipsis-logo.svg'
              : ApiManager.apiUrl + '/v3/backoffice/theme/logo'
          }
          alt="ellipsis-logo"
        />
        {children}
      </Paper>
      {Bottom !== null ? (
        Bottom
      ) : (
        <div className={classes.linksContainer}>
          <FullScreenLink href="https://ellipsis-drive.com/terms/">Terms of Service</FullScreenLink>

          <FullScreenLink href="https://ellipsis-drive.com/privacy/">Privacy Policy</FullScreenLink>

          <FullScreenLink href="https://docs.ellipsis-drive.com/">Help Center</FullScreenLink>
        </div>
      )}
    </div>
  );
};

FullScreenLayout.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  paperClassName: PropTypes.string,
  paperStyle: PropTypes.object,
};

export default FullScreenLayout;

const FullScreenLink = ({ href, children }) => (
  <LinkStyleButton
    component="a"
    target="_blank"
    rel="noreferrer"
    href={href}
    color="grey.200"
    sx={{ textAlign: 'center', pb: 1 }}
  >
    {children}
  </LinkStyleButton>
);
