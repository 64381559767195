import React /* , { useState } */ from 'react';

import MoveToAction from 'ReusableComponents/MoveTo/MoveTo';
import { useHistory } from 'react-router';

import CombinedMenuItem from '../Components/CombinedMenuItem/CombinedMenuItem';
import { CircularProgress, DialogContent, SvgIcon, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import ApiManager from 'ApiManager';
import { handleItemMove, handleFolderMove } from 'Drive/itemUtils';
import { useCurrentFolder } from 'Drive/CurrentFolderContext';
import { useDriveContext } from 'Drive/DriveContext';
import { useAuthFuncs, useMinimalAuth } from 'hooks';
import { useMainContext } from 'ReusableComponents';
import { useSelectedItems } from 'Drive/selectedItemContext';
import { useContextMenu } from '../../ContextMenuContext';

const FileMove = (
  <path d="M20 6h-8l-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-6 12v-3h-4v-4h4V8l5 5-5 5z" />
);

const MoveTo = ({ setDialog, onClose, closeDialog }) => {
  const history = useHistory();

  const { selectedItems } = useSelectedItems();
  const { menuOptions } = useContextMenu();
  const { type, path } = menuOptions;
  const { currentFolderInfo, onToLocationClick, root } = useCurrentFolder();
  const { handleOptimisticUpdate } = useDriveContext();
  const user = useMinimalAuth();
  const { fetchUserInfo } = useAuthFuncs();
  const { onOpenSnackbar } = useMainContext();

  const handleMove = (folder, ownerSwitch) => {
    let items = type === 'single' ? [path] : [...selectedItems];
    const isBreadcrumb = items[0].id === currentFolderInfo?.id;

    closeDialog();
    handleItemMove({
      movedItems: items,
      folder,
      user,
      onOpenSnackbar,
      handleOptimisticUpdate: handleOptimisticUpdate,
      isBreadcrumb,
      cb: () => {
        if (ownerSwitch) {
          fetchUserInfo();
        }
        if (isBreadcrumb) {
          if (!!folder) {
            onToLocationClick(folder);
          } else {
            history.push('/drive/me');
          }
        }
      },
    });
  };

  const moveTo = () => {
    setDialog({
      title: `Move ${type === 'multiple' ? 'items' : path.name} to:`,
      content: (
        <MoveToAction
          currentRoot={{
            root: root,
            path:
              type === 'multiple'
                ? selectedItems[0].path?.path?.slice(1).reverse()
                : path.path?.path?.slice(1).reverse(),
          }}
          currentAccess={
            !currentFolderInfo ? ApiManager.newAccessLevel['admin'] : currentFolderInfo.yourAccess.accessLevel
          }
          closeDialog={() => setDialog(null)}
          handleMove={handleMove}
          selectedItems={type === 'multiple' ? selectedItems : [path]}
        />
      ),
    });

    onClose();
  };

  return (
    <CombinedMenuItem
      icon={<SvgIcon>{FileMove}</SvgIcon>}
      label="Move to"
      openDialog
      accessLevel={ApiManager.newAccessLevel.admin}
      action={moveTo}
    />
  );
};

export default MoveTo;
