import React, { useState } from 'react';

import { CircularProgress, Dialog, DialogTitle, Typography } from '@mui/material';
import PlansSection from 'ReusableComponents/PlansSection/PlansSection';
import { CenteredBox } from '../../CenteredBox';
import { BuyNowButton, LoginButton } from '../DriveButtons';
import { makeStyles } from 'tss-react/mui';
import { useLocation } from 'react-router';
import { useCurrentFolder } from 'Drive/CurrentFolderContext';
import { useAuth, useAuthFuncs } from 'hooks';
import Pay from 'ReusableComponents/Pay';
import ApiManager from 'ApiManager';
import VersionUtility from 'VersionUtility';

const useStyles = makeStyles({ name: 'BuyItem' })((theme) => ({
  root: {
    display: 'flex',
    flex: '1 0 100%',
    flexDirection: 'column',
    height: '100%',
  },
  img: { maxWidth: '33vw', maxHeight: '33vh', marginTop: '1rem', marginBottom: '1.5rem' },
  buttonContainer: {
    display: 'flex',
    maxWidth: '50vw',
    gap: 12,
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
}));

export const BuyItem = () => {
  const location = useLocation();
  const { classes: styles } = useStyles();
  const { fetchUserInfo } = useAuthFuncs();
  const { currentFolderInfo, putInShared } = useCurrentFolder();
  const user = useAuth();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubscribe = (plan) => {
    setOpen({ open: true, plan: plan });
  };

  const onPaid = async () => {
    setLoading(true);
    setOpen(false);
    await ApiManager.post(`/v3/path/${currentFolderInfo.id}/plan/${open.plan.id}/subscribe`, null, user);
    const res = VersionUtility.convertPathInfo(await ApiManager.get(`/v3/path/${currentFolderInfo.id}`, null, user));
    putInShared(res);
    setLoading(false);
    fetchUserInfo();
  };

  const justOnePlan = currentFolderInfo?.plans.length === 1;
  return (
    <>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Select Subscription</DialogTitle>
        <PlansSection
          className="subscription-body"
          plans={currentFolderInfo.plans}
          type="subscribe"
          path={currentFolderInfo}
          // activePlan={getActivePlan}
          onClick={handleSubscribe}
        />
      </Dialog>
      {open?.open && (
        <Pay
          onClose={() => {
            setOpen(false);
          }}
          onSuccess={onPaid}
          pay={open?.plan.monthlyFee}
        />
      )}

      <div className={styles.root}>
        <CenteredBox>
          <Typography variant="h1" align="center">
            Get access to the{' '}
            {['raster', 'vector', 'pointCloud', 'map', 'shape'].includes(currentFolderInfo.type)
              ? 'layer'
              : currentFolderInfo.type}
            {' "'}
            {currentFolderInfo.name}
            {'"'}
          </Typography>
          <img className={styles.img} src="/images/drive/Astronaut seated_2.svg" alt="Not logged in" />
          <div className={styles.buttonContainer}>
            {loading ? (
              <CircularProgress />
            ) : user ? (
              <BuyNowButton
                onClick={() => {
                  if (justOnePlan) {
                    setOpen({ open: true, plan: currentFolderInfo?.plans[0] });
                  } else {
                    setDialogOpen(true);
                  }
                }}
                label={
                  justOnePlan
                    ? 'Buy Access for ' +
                      currentFolderInfo?.plans[0].monthlyFee.toLocaleString(undefined, {
                        style: 'currency',
                        currency: 'EUR',
                      })
                    : 'Buy Access'
                }
              />
            ) : (
              <LoginButton
                overRideText={'Login to buy access'}
                location={{ pathname: '/login', state: { from: `${location.pathname}${location.search}` } }}
              />
            )}
          </div>
        </CenteredBox>
      </div>
    </>
  );
};
