import React from 'react';

import { Tooltip, Zoom } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Info } from '@mui/icons-material';

import PropTypes from 'prop-types';

const useStyles = makeStyles()((theme) => ({
  infoButton: {},
  tooltip: {},
}));

const InfoTooltip = ({ title, placement = 'left', interactive = false, className = '', style = {} }) => {
  const { classes, cx } = useStyles();
  return (
    <Tooltip
      title={title}
      TransitionComponent={Zoom}
      placement={placement}
      disableInteractive={!interactive}
      classes={{ tooltip: classes.tooltip }}
      arrow
    >
      <Info color="primary" className={cx(classes.infoButton, className)} fontSize="small" style={style} />
    </Tooltip>
  );
};

InfoTooltip.propTypes = {
  title: PropTypes.node.isRequired,
  placement: PropTypes.string,
  interactive: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default InfoTooltip;
