import React, { createContext, useContext, useRef, useState, useCallback, useMemo, useEffect } from 'react';

import { useLayers } from './ViewerLayersContext';

const SPECIAL_LAYER_INIT = { show: false, mapId: null, layerId: null, access: null, pages: [] };

const extraLayersContext = createContext();

export const ExtraLayersProvider = ({ children }) => {
  const { layersInfo } = useLayers();
  const [changelog, setChangelogN] = useState(SPECIAL_LAYER_INIT);
  const [feed, setFeedN] = useState(SPECIAL_LAYER_INIT);
  const [video, setVideo] = useState({ layer: null, timestamp: null });

  useEffect(() => {
    setVideo(null);
  }, [layersInfo]);

  const callB2 = useRef({ N: 0, cb: null });
  const done2 = useRef(0);
  useEffect(() => {
    if (done2.current !== callB2.current.N) {
      done2.current = callB2.current.N;
      if (callB2.current.cb) {
        callB2.current.cb();
      }
    }
  }, [feed]);

  const callB = useRef({ N: 0, cb: null });
  const done = useRef(0);
  useEffect(() => {
    if (done.current !== callB.current.N) {
      done.current = callB.current.N;
      if (callB.current.cb) {
        callB.current.cb();
      }
    }
  }, [changelog]);

  const setFeed = useCallback((reset, show, timestampId, mapId, access, page, cb, filterByBounds) => {
    setFeedN((old) => {
      let feedN = { ...old };
      feedN.show = show;
      feedN.timestampId = timestampId;
      feedN.mapId = mapId;
      feedN.access = access;
      feedN.filterByBounds = filterByBounds;
      if (reset) {
        feedN.pages = [];
      } else if (page) {
        feedN.pages.push(page);
      }
      if (cb) {
        callB2.current = { cb: cb, N: callB2.current.N + 1 };
      }

      return feedN;
    });
  }, []);

  const setChangelog = useCallback((reset, show, timestampId, mapId, access, page, cb, filterByBounds) => {
    setChangelogN((old) => {
      let changelogN = { ...old };
      if (reset) {
        changelogN.pages = [];
      } else if (page) {
        changelogN.pages.push(page);
      }
      changelogN.show = show;
      changelogN.timestampId = timestampId;
      changelogN.mapId = mapId;
      changelogN.access = access;
      changelogN.filterByBounds = filterByBounds;
      if (cb) {
        callB.current = { cb: cb, N: callB.current.N + 1 };
      }
      return changelogN;
    });
  }, []);

  const value = useMemo(
    () => ({ changelog, setChangelog, feed, setFeed, video, setVideo }),
    [changelog, feed, video, setVideo, setChangelog, setFeed]
  );

  return <extraLayersContext.Provider value={value}>{children}</extraLayersContext.Provider>;
};

export function useExtraLayers() {
  const context = useContext(extraLayersContext);
  if (context === undefined) {
    throw new Error('useExtraLayers must be used within an extraLayersProvider');
  }
  return context;
}

export function withExtraLayers(Component) {
  return function WrapperComponent(props) {
    const context = useExtraLayers();
    return <Component {...props} {...context} />;
  };
}

export function withExtraLayersContext(Component) {
  return function WrapperComponent(props) {
    return (
      <ExtraLayersProvider>
        <Component {...props} />
      </ExtraLayersProvider>
    );
  };
}
