import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  ListItemButton,
  ListItemText,
  Popover,
  Typography,
  useMediaQuery,
} from '@mui/material';

import { Cancel, ExpandMore } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';

import TypeChip from 'ReusableComponents/TypeChip';
import DialogTitle from 'ReusableComponents/DialogTitle/DialogTitle';
import { GENERAL_INPUTS, INITIAL_SEARCH_VALS, SPATIAL_INPUTS } from './SearchInput';

const useStyles = makeStyles({ name: 'AdvancedSearch' })((theme, _params, classes) => ({
  root: {
    transition: theme.transitions.create('backdrop-filter'),
    backdropFilter: 'blur(0px)',
    [`&.${classes.open}`]: { backdropFilter: 'blur(1px)' },
  },
  paper: {
    boxSizing: 'border-box',
    maxWidth: 'min(750px, calc(100vw - 32px))',
    overflow: 'hidden',
    width: '100%',
    display: 'grid',
    gridTemplateRows: '1fr auto',
    transition: theme.transitions.create(['box-shadow', 'left'], { duration: theme.transitions.duration.shortest }),
    [theme.breakpoints.down('sm')]: { gridTemplateRows: 'auto auto 1fr auto' },
  },

  content: {
    overflow: 'auto',
    padding: theme.spacing(1, 3),
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: { padding: theme.spacing(1, 2) },
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '150px 1fr',
    alignItems: 'center',
    gap: theme.spacing(1.5, 2),
    [theme.breakpoints.down('sm')]: { gridTemplateColumns: '1fr', gap: theme.spacing(0.5) },
  },

  listItem: { padding: theme.spacing(0, 2), margin: theme.spacing(0, -2), borderRadius: theme.spacing(1) },

  bottomContainer: {
    padding: theme.spacing(1, 3),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: { padding: theme.spacing(1, 2), paddingBottom: theme.spacing(2) },
  },
  buttonContainer: { display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: theme.spacing(2) },

  chevron: {
    transition: theme.transitions.create('transform'),
    [`&.${classes.open}`]: { transform: 'rotate(180deg)' },
  },
  disabledHelpContainer: { display: 'flex', gap: theme.spacing(1), alignItems: 'center' },
  typeContainer: { display: 'inline-flex', gap: theme.spacing(1) },
  overrideDisable: { pointerEvents: 'all' },
  chip: { [theme.breakpoints.down('sm')]: { gap: theme.spacing(0.5), '& .MuiChip-label': { display: 'none' } } },
  disabled: {
    pointerEvents: 'none',
    cursor: 'default',
    '&:hover': { backgroundColor: 'initial' },
    '& .MuiTypography-root': { opacity: 0.38 },
    [`& .MuiButtonBase-root:not(.${classes.overrideDisable})`]: { opacity: 0.38 },
  },

  open: {},
}));

const AdvancedSearch = ({
  open,
  searchRef,
  onClose,
  handleSearch,
  searchVals,
  setSearchVals,
  clearSearch,
  spatialDisabled,
}) => {
  const { classes: styles, cx, theme } = useStyles();
  const [spatialSearch, setSpatialSearch] = useState(true);

  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  const boundingRect = searchRef.current?.getBoundingClientRect() || {};
  const { top = 0, left = 0, width = 0 } = { ...JSON.parse(JSON.stringify(boundingRect)) };

  const deleteType = useCallback(
    (typeKey) => {
      setSearchVals((old) => ({ ...old, type: old?.type?.map((k) => (k !== typeKey ? k : false)).filter((x) => !!x) }));
    },
    [setSearchVals]
  );

  const handleSearchSubmit = useCallback(() => {
    handleSearch();
    onClose();
  }, [handleSearch, onClose]);

  return (
    <Popover
      open={open}
      className={cx(styles.root, open && styles.open)}
      onClose={onClose}
      anchorPosition={{ left: isSm ? window.innerWidth / 2 : left + width / 2, top }}
      anchorReference="anchorPosition"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      disableEnforceFocus
      onContextMenu={(e) => e?.stopPropagation()}
      PaperProps={{ className: styles.paper, sx: { maxHeight: `calc(100vh - 32px - ${top}px)` } }}
    >
      {isSm && (
        <>
          <DialogTitle title="Advanced Search" close={onClose} />
          <Divider />
        </>
      )}

      <div className={styles.content}>
        <div className={styles.grid}>
          {GENERAL_INPUTS?.map(({ key, ...i }) => (
            <InputRow key={key} input={{ key, ...i }} searchVals={searchVals} setSearchVals={setSearchVals} />
          ))}
        </div>
        <Divider sx={{ my: 1 }} />
        <ListItemButton
          onClick={!spatialDisabled ? () => setSpatialSearch((old) => !old) : undefined}
          className={cx(styles.listItem, spatialDisabled && styles.disabled)}
          {...(spatialDisabled ? { focusRipple: false, disableRipple: true, tabIndex: -1 } : {})}
        >
          <ListItemText
            primary={<Typography variant="subtitle1">Spatial Search</Typography>}
            secondary={
              spatialDisabled ? (
                <div className={styles?.disabledHelpContainer}>
                  <Typography variant="overline">Disabled by:</Typography>
                  <div className={styles.typeContainer}>
                    {searchVals?.type?.length === 0 ? (
                      <Typography variant="overline">No type selected</Typography>
                    ) : (
                      searchVals?.type?.map(
                        (t) =>
                          (t === 'folder' || t === 'file' || t === 'bookmark' || t === 'process') && (
                            <TypeChip
                              key={t}
                              className={cx(styles.chip, styles.overrideDisable)}
                              type={t}
                              onDelete={() => deleteType(t)}
                            />
                          )
                      )
                    )}
                  </div>
                </div>
              ) : (
                <Typography variant="overline">Note: only available for vector and raster</Typography>
              )
            }
            disableTypography
          />
          <IconButton>
            <ExpandMore className={cx(styles.chevron, spatialSearch && !spatialDisabled && styles.open)} />
          </IconButton>
        </ListItemButton>
        <Collapse in={spatialSearch && !spatialDisabled}>
          <Box className={styles.grid} sx={{ mt: 2 }}>
            {SPATIAL_INPUTS?.map(({ key, ...i }) => (
              <InputRow key={key} input={{ key, ...i }} searchVals={searchVals} setSearchVals={setSearchVals} />
            ))}
          </Box>
        </Collapse>
      </div>
      <div className={styles.bottomContainer}>
        <Divider sx={{ mb: 2 }} />
        <div className={styles.buttonContainer}>
          <Button onClick={clearSearch}>Clear</Button>
          <Button variant="contained" onClick={handleSearchSubmit}>
            Search
          </Button>
        </div>
      </div>
    </Popover>
  );
};

const useInputRowStyles = makeStyles({ name: 'InputRow' })((theme) => ({
  root: { display: 'flex', gap: theme.spacing(2), alignItems: 'center', minHeight: theme.spacing(6) },
  title: { alignSelf: 'flex-start', marginTop: theme.spacing(1) },
}));

const InputRow = ({ input, searchVals, setSearchVals }) => {
  const { key, label, component: Component, reset } = { ...input };
  const { classes: styles } = useInputRowStyles();

  const resetFunc = useCallback(
    () => setSearchVals((old) => ({ ...old, [key]: INITIAL_SEARCH_VALS[key] })),
    [key, setSearchVals]
  );

  const setSearchVal = useCallback(
    (newVal) => {
      if (typeof newVal === 'function') {
        setSearchVals((old) => ({ ...old, [key]: newVal(old[key]) }));
      } else {
        setSearchVals((old) => ({ ...old, [key]: newVal }));
      }
    },
    [key, setSearchVals]
  );

  return (
    <>
      <Typography variant="button" className={styles.title}>
        {label}
      </Typography>
      {!!Component && (
        <div className={styles.root}>
          <Component
            input={input}
            searchVal={searchVals[key]}
            setSearchVal={setSearchVal}
            initialValue={INITIAL_SEARCH_VALS[key]}
          />
          {!!reset && (
            <IconButton
              edge="end"
              onClick={resetFunc}
              disabled={searchVals[key] === INITIAL_SEARCH_VALS[key]}
              size="small"
            >
              <Cancel fontSize="inherit" />
            </IconButton>
          )}
        </div>
      )}
    </>
  );
};

export default AdvancedSearch;
