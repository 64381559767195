import React, { useState, useEffect, useCallback } from 'react';

import {
  List,
  ListItem,
  Tooltip,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  CircularProgress,
} from '@mui/material';
import { Delete, CheckCircle, Cancel } from '@mui/icons-material';
import { useMainContext } from '../../../ReusableComponents';

import ApiManager from 'ApiManager';
import { SectionTitle } from '../../../ReusableComponents';

import { useMinimalAuth } from '../../AuthContext';

const PersonalAccessTokens = () => {
  const user = useMinimalAuth();
  const [tokens, setTokens] = useState([]);
  const [loading, setLoading] = useState(true);
  const { onOpenSnackbar } = useMainContext();

  const onDelete = (token) => {
    ApiManager.delete(`/v3/account/security/accessToken/${token.id}`, null, user)
      .then((r) => {
        setTokens((tokens) => tokens.filter((t) => t.id !== token.id));
        onOpenSnackbar({ level: 'success', content: 'Token revoked' });
      })
      .catch((e) => {
        onOpenSnackbar({ level: 'error', content: e.message });
      });
  };

  const getPage = useCallback(
    (nextPageStart) => {
      ApiManager.get('/v3/account/security/accessToken', { pageStart: nextPageStart }, user)
        .then((r) => {
          setTokens((tokens) => [...tokens, ...r.result]);
          if (r.nextPageStart) {
            getPage(r.nextPageStart);
          } else {
            setLoading(false);
          }
        })
        .catch((e) => {
          onOpenSnackbar({ level: 'error', content: e.message });
        });
    },
    [user, onOpenSnackbar]
  );

  useEffect(() => {
    getPage(null);
  }, [getPage]);

  return (
    <>
      <SectionTitle first={true}>
        <SectionTitle.SectionTitleText>Your personal access tokens</SectionTitle.SectionTitleText>
      </SectionTitle>
      <List dense disablePadding>
        {tokens.map((t) => (
          <ListItem key={t.id}>
            <Tooltip title={t.accessList.find((a) => !a.valid) ? 'Not Valid' : 'Valid'}>
              <ListItemIcon>{t.accessList.find((a) => !a.valid) ? <Cancel /> : <CheckCircle />}</ListItemIcon>
            </Tooltip>
            <ListItemText primary={t.description} secondary={'Creation date: ' + t.creationDate} />
            <Tooltip title={'Revoke this token'}>
              <ListItemSecondaryAction onClick={() => onDelete(t)}>
                <IconButton size="large">
                  <Delete />
                </IconButton>
              </ListItemSecondaryAction>
            </Tooltip>
          </ListItem>
        ))}
        {!loading && tokens.length === 0 && (
          <ListItem>
            <ListItemText primary={'No personal access tokens'} />
          </ListItem>
        )}
        {loading && (
          <ListItem>
            <CircularProgress />
          </ListItem>
        )}
      </List>
    </>
  );
};

export default PersonalAccessTokens;
