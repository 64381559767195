import React, { useState } from 'react';

import { CreateNewFolder } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { InputDialog } from 'ReusableComponents';
import CombinedMenuItem from './DriveMenu/Components/CombinedMenuItem/CombinedMenuItem';
import { useCurrentFolder } from './CurrentFolderContext';
import { DriveButton } from './Pages/DriveButtons';
import { useDriveContext } from './DriveContext';
import ApiManager from 'ApiManager';

const useStyles = makeStyles()((theme) => ({
  addFolderButton: {
    width: '60px',
    height: '60px',
  },
  newFolderDialog: {
    minWidth: '300px',
  },
}));

const AddFolderComponent = ({ type, close, buttonProps }) => {
  const { classes: styles } = useStyles();
  const { currentFolderInfo, mainMode } = useCurrentFolder();
  const { handleAddNewFolder } = useDriveContext();
  const [folderName, setFolderName] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleClose = () => {
    setDialogOpen(false);
    if (!!close) {
      close();
    }
  };

  const handleSubmit = () => {
    handleAddNewFolder(folderName);
    setFolderName('');
    handleClose();
  };

  const disabled =
    ((mainMode === '/drive/shared' || mainMode === '/drive/favorites') && !currentFolderInfo) ||
    (currentFolderInfo && currentFolderInfo.type !== 'folder') ||
    mainMode === '/drive/trash' ||
    mainMode === '/drive/search' ||
    (currentFolderInfo && currentFolderInfo?.yourAccess?.accessLevel < ApiManager.newAccessLevel['edit+']);

  return (
    <>
      {type === 'menuItem' ? (
        <span>
          <CombinedMenuItem
            className={'tutorialNewFolder'}
            icon={<CreateNewFolder />}
            label="New Folder"
            action={() => {
              close();
              setDialogOpen(true);
            }}
            disabled={disabled}
          />
        </span>
      ) : type === 'button' ? (
        <DriveButton label="New Folder" onClick={() => setDialogOpen(true)} disabled={disabled} {...buttonProps} />
      ) : (
        <div className={'tutorialNewFolder'}>
          <IconButton
            color="primary"
            className={styles.addFolderButton}
            onClick={() => {
              setDialogOpen(true);
            }}
            disabled={disabled}
            {...buttonProps}
          >
            <CreateNewFolder />
          </IconButton>
        </div>
      )}
      <InputDialog
        className={styles.newFolderDialog}
        open={dialogOpen}
        handleClose={handleClose}
        value={folderName}
        handleValueChange={(e) => setFolderName(e.target.value)}
        onClick={handleSubmit}
        legend=""
        title="New folder"
        agreeText="Create"
      />
    </>
  );
};

export default AddFolderComponent;
