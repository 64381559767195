import React, { forwardRef } from 'react';

import { Link, Skeleton, Typography } from '@mui/material';

import basicTheme from 'theme';
import { makeStyles } from 'tss-react/mui';

import { retryableLazy } from 'globalUtils';
import SuspenseContainer from 'ReusableComponents/SuspenseContainer';

const ReactMarkdown = retryableLazy(() => import('react-markdown'));
const remarkGfm = retryableLazy(() => import('remark-gfm'));

const useStyles = makeStyles({ name: 'MarkdownWrapper' })(() => ({ basic: { wordBreak: 'break-all' } }));

const MarkdownWrapper = forwardRef(({ children, className, theme = basicTheme, textProps = {} }, ref) => {
  return (
    <div ref={ref} className={className || undefined}>
      <SuspenseContainer
        displayName="MarkdownWrapper"
        fallback={<Skeleton animation="wave" sx={{ fontSize: '0.9rem' }} />}
      >
        <ReactMarkdown
          sx={{ minWidth: 0 }}
          skipHtml
          remarkPlugins={[remarkGfm]}
          allowedElements={['a', 'p', 'strong', 'em', 'ul', 'ol', 'li', 'del']}
          linkTarget="_blank"
          unwrapDisallowed
          components={{
            p: ({ children, ...props }) => (
              <StyledP textProps={textProps} {...props}>
                {children}
              </StyledP>
            ),
            a: ({ children, ...props }) => (
              <StyledA theme={theme} textProps={textProps} {...props}>
                {children}
              </StyledA>
            ),
          }}
        >
          {children}
        </ReactMarkdown>
      </SuspenseContainer>
    </div>
  );
});

MarkdownWrapper.displayName = 'MarkdownWrapper';

export default MarkdownWrapper;

const StyledP = forwardRef(({ node, children, textProps: inputTextProps, ...props }, ref) => {
  const { classes: styles } = useStyles();
  const { sx, ...textProps } = { ...inputTextProps };

  return (
    <Typography ref={ref} className={styles.basic} {...props} {...textProps} sx={sx}>
      {children}
    </Typography>
  );
});

StyledP.displayName = 'StyledP';

const StyledA = forwardRef(({ node, href, children, theme, textProps: { sx = {}, ...textProps }, ...props }, ref) => {
  const { classes: styles } = useStyles();

  return (
    <Link
      className={styles.basic}
      {...props}
      {...textProps}
      href={href.indexOf('http') === 0 ? href : 'https://' + href}
      sx={{ color: theme?.palette?.primary?.main, ...sx }}
      ref={ref}
    >
      {children}
    </Link>
  );
});

StyledA.displayName = 'StyledA';
