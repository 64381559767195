import React from 'react';

import { Star, StarBorder } from '@mui/icons-material';

import CombinedMenuItem from '../Components/CombinedMenuItem/CombinedMenuItem';
import { useAuth } from 'hooks';
import { toggleFavorite } from 'Drive/itemUtils';
import { useCurrentFolder } from 'Drive/CurrentFolderContext';
import { useDriveContext } from 'Drive/DriveContext';
import { useMainContext } from 'ReusableComponents';
import { useContextMenu } from '../../ContextMenuContext';
import LeaveFavoriteComponent from '../Components/LeaveFavorite/LeaveFavorite';

import ApiManager from 'ApiManager';
import { useSelectedItems } from 'Drive/selectedItemContext';
// import { layer } from '@fortawesome/fontawesome-svg-core';

const View = ({ onClose, setDialog }) => {
  const user = useAuth();

  const { menuOptions } = useContextMenu();
  const { path } = menuOptions;
  const isMap = path?.type !== 'folder';
  const {
    path: breadcrumbPath,
    currentFolderInfo,
    mainMode,
    updatePath,
    onToLocationClick,
    favoriteFolder,
  } = useCurrentFolder();

  const { handleOptimisticUpdate } = useDriveContext();
  const { onOpenSnackbar } = useMainContext();
  const { resetSelectedItems } = useSelectedItems();

  const favoriteFunc = () => {
    onClose();

    const cb = () => {
      onOpenSnackbar({
        level: 'success',
        content: !path.favorite ? 'Added to favorites' : 'Removed from favorites',
      });
    };

    if (currentFolderInfo && currentFolderInfo.id === path?.id) {
      ApiManager.put(`/v3/path/${path?.id}/favorite`, { favorite: !path.favorite }, user)
        .then((r) => {
          cb();
          if (mainMode === '/drive/favorites' && breadcrumbPath.length === 1 && path.favorite) {
            onToLocationClick({ ...path, favorite: false, favoriteLocation: null });
          } else if (mainMode === '/drive/external' && !path.favorite) {
            favoriteFolder(path);
          } else {
            updatePath(path.id, {
              favorite: !path.favorite,
              favoriteLocation: !path.favorite ? { path: [{ id: path.id, name: path.name }] } : null,
            });
          }
        })
        .catch((e) => {
          onOpenSnackbar({ level: 'error', content: e.message });
        });
    } else {
      toggleFavorite({
        path,
        user,
        onOpenSnackbar,
        handleOptimisticUpdate,
        mainMode,
        resetSelectedItems,
        breadcrumbPath,
      });
    }
  };

  const onBeforeFavorite = () => {
    if (!path.path.path && path.favoriteLocation && path.favoriteLocation.path.length === 1) {
      setDialog({
        title: `Remove From Drive`,
        content: (
          <LeaveFavoriteComponent
            isMap={isMap}
            cancel={() => setDialog(null)}
            confirm={() => {
              favoriteFunc();
            }}
          />
        ),
      });

      onClose();
    } else {
      favoriteFunc();
    }
  };

  return (
    <CombinedMenuItem
      icon={path?.favorite ? <Star color="secondary" /> : <StarBorder />}
      label={path?.favorite ? `Remove from favorites` : `Add to favorites`}
      action={onBeforeFavorite}
    />
  );
};

export default View;
