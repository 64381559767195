import React from 'react';

import { Box, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles({ name: 'MainPaneHeader' })((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: '100%',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    minHeight: 0,
    flexGrow: 1,
  },
  title: {
    background: 'white',
    border: `0 solid ${theme.palette.divider}`,
    borderWidth: '1px 0',
    padding: theme.spacing(1, 2),
  },
}));

const MainPaneHeader = ({ label, children, ...props }) => {
  const { classes: styles } = useStyles();

  return (
    <Box className={styles.root} {...props}>
      <Typography className={styles.title} variant="button" color="primary" component="div">
        {label}
      </Typography>
      <div className={styles.contentContainer}>{children}</div>
    </Box>
  );
};

export default MainPaneHeader;
