import React from 'react';

import {
  Divider,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  Typography,
  // IconButton,
  // Button,
} from '@mui/material';
import { ArrowRightAlt, Launch /* , PhotoCamera */ } from '@mui/icons-material';

import { makeStyles } from 'tss-react/mui';

import { Link } from 'react-router-dom';
const useCombinedMenuItemStyles = makeStyles({ name: 'CombinedMenuItem' })((theme) => ({
  menuItem: {
    width: '100%',
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    gap: theme.spacing(0.5),
    paddingRight: theme.spacing(4),
    color: theme.palette.common.gray2,
    '& .MuiListItemIcon-root': {
      color: theme.palette.common.gray3,
    },
  },
  secondary: {
    paddingRight: theme.spacing(2),
  },
  secondaryContainer: {
    display: 'flex',
    paddingLeft: theme.spacing(2),
    color: theme.palette.action.active,
  },
  divider: {
    marginTop: 8,
    marginBottom: 8,
  },
  subheader: {
    padding: theme.spacing(0.5, 2),
    backgroundColor: 'white',
  },
  secondaryAction: {
    display: 'flex',
    right: 12,
    color: theme.palette.common.gray3,
  },
  itemText: {
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: 6,
    '&>*': {
      lineHeight: 1,
    },
  },
}));

const CombinedMenuItem = React.forwardRef(
  (
    {
      action,
      autoFocus,
      className,
      icon,
      label,
      secondaryText,
      openDialog,
      openNew,
      tabIndex,
      type,
      path,
      component,
      accessLevel,
      children,
      onClick,
      ...rest
    },
    ref
  ) => {
    const { classes: styles, cx } = useCombinedMenuItemStyles();

    if (type === 'subheading') {
      return (
        <Typography component={ListSubheader} className={styles.subheader} variant="overline" disableSticky>
          {label}
        </Typography>
      );
    }

    if (type === 'divider') {
      return <Divider className={styles.divider} />;
    }

    return (
      <MenuItem
        dense
        autoFocus={autoFocus}
        tabIndex={tabIndex}
        onClick={!!onClick ? onClick : !!action ? () => action(path) : () => {}}
        className={cx(styles.menuItem, (openNew || openDialog || component === Link) && styles.secondary, className)}
        component={component ? component : null}
        target={!!openNew ? '_blank' : undefined}
        ref={ref}
        {...rest}
      >
        {icon && <ListItemIcon fontSize="small">{icon}</ListItemIcon>}
        {(label || secondaryText) && (
          <ListItemText
            className={styles.itemText}
            primaryTypographyProps={{ variant: 'button', noWrap: true, align: 'left' }}
            secondaryTypographyProps={{ variant: 'overline', noWrap: true, color: 'error' }}
            primary={label}
            secondary={secondaryText}
          />
        )}
        {!!openDialog && <div className={styles.secondaryContainer}>{<ArrowRightAlt fontSize="inherit" />}</div>}
        {!!openNew && <div className={styles.secondaryContainer}>{<Launch fontSize="inherit" />}</div>}
        {children}
      </MenuItem>
    );
  }
);

export default CombinedMenuItem;
CombinedMenuItem.displayName = 'CombinedMenuItem';
