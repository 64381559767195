import React from 'react';

import { Card, Typography, Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import PropTypes from 'prop-types';

import * as animationData from './redirect.json';

import SuspenseContainer from 'ReusableComponents/SuspenseContainer';
import { retryableLazy } from 'globalUtils';

const Lottie = retryableLazy(() => import('lottie-react'));

const useStyles = makeStyles()((theme, _params, classes) => ({
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    display: 'block',
    zIndex: '99999',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    width: '100vw',
    height: '100vh',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
    },
    overflow: 'hidden',
    backgroundImage: `linear-gradient(180deg, rgba(13, 46, 76, 0.6), rgba(13, 46, 76, 0.6)), url(${process.env.PUBLIC_URL}/images/error/spacebg.jpg)`,
  },
  mainCard: {
    position: 'relative',
    height: '587px',
    width: '454px',
    margin: 'auto',
    top: '200px',
    borderRadius: '8px',
    textAlign: 'center',
    backgroundColor: '#fafafa',
    overflow: 'visible',

    [`& .${classes.logoCircle}`]: {
      position: 'relative',
      height: '96px',
      width: '96px',
      margin: 'auto',
      top: '-48px',
      borderRadius: '9999px',
      padding: '4px',
      backgroundColor: '#fafafa',
    },

    [`& .${classes.mainGrid}`]: {
      marginTop: '150px',
    },

    [`& .${classes.rocketAstronaut}`]: {
      position: 'relative',
      left: '200px',
      top: '-80px',
    },
  },
  lottieContainer: {
    margin: 'auto',
    marginTop: '-40px',
    maxWidth: '150px',
    maxHeight: '150px',
  },

  mainGrid: {},
  logoCircle: {},
  rocketAstronaut: {},
}));

const PageRedirect = ({ message }) => {
  const { classes } = useStyles();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div className={classes.container}>
      <Card className={classes.mainCard}>
        <div className={classes.logoCircle}>
          <img className={classes.ellipsisLogo} src="/ellipsis-logo.svg" alt="ellipsis-logo" />
        </div>
        <Grid container spacing={2} alignItems="stretch" className={classes.mainGrid}>
          <Grid item md={12} xs={12}>
            <Typography variant="subtitle1" component="h1">
              {message}
            </Typography>
          </Grid>
          <Grid item md={12} xs={12}>
            <div className={classes.lottieContainer}>
              <SuspenseContainer displayName="PageRedirect">
                <Lottie {...defaultOptions} />
              </SuspenseContainer>
            </div>
          </Grid>
        </Grid>

        <img className={classes.rocketAstronaut} src="/svgs/rocket_astronaut.svg" alt="rocket-astronaut" />
      </Card>
    </div>
  );
};

PageRedirect.propTypes = {
  reason: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default PageRedirect;
