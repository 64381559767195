import React from 'react';

import { TextField } from '@mui/material';

const Text = ({ searchVal, setSearchVal, input: { placeholder } }) => (
  <TextField
    fullWidth
    size="small"
    value={searchVal}
    onChange={(e) => setSearchVal(e?.target?.value)}
    placeholder={placeholder}
  />
);

export default Text;
