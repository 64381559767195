import React from 'react';
import { hiddenHubspotInputs } from './constants';

const HiddenHubspotFields = ({ itemsRef }) => {
  return (
    <>
      {hiddenHubspotInputs.map((inputName, inputIdx) => (
        <input
          key={inputName}
          type="hidden"
          id={inputName}
          value={inputName}
          name={inputName}
          ref={(el) => (itemsRef.current[inputIdx] = el)}
        />
      ))}
    </>
  );
};

export default HiddenHubspotFields;
