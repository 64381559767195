import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  container: {
    maxWidth: '660px',
  },
  textField: {
    width: '260px',
  },
  textFieldInput: {
    padding: '14px 14px',
    height: '22px',
  },
  submitButton: {},
  groupTitle: {},
  copyLinkBtn: {},
  caption: {
    marginBottom: '6px',
    marginTop: '-8px',
  },
  codeSnippet: {},
}));

export default useStyles;
