import React, { PureComponent } from 'react';

import {
  Button,
  CircularProgress,
  TextField,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  Divider,
  Box,
  useMediaQuery,
} from '@mui/material';
import { Send } from '@mui/icons-material';
import { withStyles } from 'tss-react/mui';

import DialogTitle from 'ReusableComponents/DialogTitle/DialogTitle';

import { withAuth } from 'Account';
import { withMainContext } from 'ReusableComponents/MainContext';
import ApiManager from '../ApiManager';

import './Contact.css';
import { withPushToDataLayer } from 'ConsentContext';

const BREAKPOINT = 'sm';

const styles = (theme) => ({
  root: {},
  paper: { height: 'calc(100% - 64px)', [theme.breakpoints.up('md')]: { maxHeight: 800 } },
  gridContainer: { height: '100%' },
  calendlyContainer: {
    boxSizing: 'border-box',
    display: 'grid',
    gridTemplateRows: 'min-content 1fr',
    gap: theme.spacing(1),
    [theme.breakpoints.down('sm')]: { height: '50vh' },
    // borderRight: '1px black solid',
  },
  messageContainer: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    alignItems: 'flex-start',
  },
});

const questionsInit = {
  name: '',
  email: '',
  message: '',
};

class Contact extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      values: questionsInit,
      loading: false,
      error: questionsInit,
    };
  }

  componentDidMount = () => {
    if (this.props.authUser) {
      this.setState({
        values: { email: this.props.authUser.email, name: this.props.authUser.username, message: this.props.message },
      });
    } else {
      this.setState({ values: { email: '', name: '', message: this.props.message } });
    }
  };

  handleChange = (e, type) => {
    let values = { ...this.state.values };
    let error = this.state.error;

    values[type] = e.target.value;
    error[type] = '';
    this.setState({ values: values, error: error });
  };

  sendMessage = async () => {
    let text = '';
    let error = false;
    let errorObj = this.state.error;

    for (let key in this.state.values) {
      if (this.state.values[key]?.length > 0) {
        text = text + key + ': ' + this.state.values[key] + '\n';
      } else {
        error = true;
        errorObj[key] = "Can't be empty";
      }
    }
    if (!error) {
      let body = {
        subject: this.props.subject ?? this.props.title,
        text: text,
        message: text,
      };
      try {
        if (this.props.userId) {
          await ApiManager.post(`/v3/user/${this.props.userId}/contact`, body, this.props.authUser);
        } else {
          await ApiManager.post('/v3/contact', body, this.props.user);
        }

        this.props.onOpenSnackbar({
          content: 'Inquiry sent',
          level: 'success',
        });

        this.props.pushToDataLayer({
          gtmId: 'GTM-K72V8Z6',
          dataLayer: {
            event: 'get-in-touch-contact-form-send',
          },
        });

        this.props.setOpenContact(false);
        this.props.cb && this.props.cb();
      } catch (error) {
        console.error(error);
        this.props.onOpenSnackbar({
          content: 'An error ocurred sending the message',
          level: 'error',
        });
        this.props.setOpenContact(false);
      }
    } else {
      this.setState({
        error: errorObj,
        loading: false,
      });
    }
  };

  render() {
    let error = ' ';
    for (let key in this.state.error) {
      error = error + ' ' + this.state.error[key];
    }

    const form = (
      <Box width="100%">
        {!this.props.userId && !this.props.authUser && (
          <TextField
            required
            disabled={this.state.loading}
            label="Name"
            fullWidth
            type="text"
            helperText={this.state.error['name']}
            error={this.state.error.name.length > 0}
            onChange={(e) => {
              this.handleChange(e, 'name');
            }}
            value={this.state.values.name}
            variant="outlined"
          />
        )}
        {!this.props.userId && !this.props.authUser && (
          <TextField
            required
            disabled={this.state.loading}
            label="Email address"
            fullWidth
            type="text"
            helperText={this.state.error['email']}
            error={this.state.error.email.length > 0}
            onChange={(e) => {
              this.handleChange(e, 'email');
            }}
            value={this.state.values.email}
            variant="outlined"
          />
        )}
        <TextField
          required
          disabled={this.state.loading}
          label="Message"
          fullWidth
          multiline
          rows={this.props.mediaQuery ? 3 : 7}
          type="text"
          helperText={this.state.error['message']}
          error={this.state.error.message.length > 0}
          onChange={(e) => {
            this.handleChange(e, 'message');
          }}
          value={this.state.values.message}
          variant="outlined"
        />
      </Box>
    );

    const sendButton = (
      <Button
        color="primary"
        variant="contained"
        onClick={() => {
          this.setState({ loading: true, error: questionsInit }, () => {
            this.sendMessage();
          });
        }}
        startIcon={!this.state.loading ? <Send /> : <CircularProgress />}
        disabled={this.state.loading}
      >
        Send
      </Button>
    );

    if (this.props.type === 'calendly') {
      return (
        <Dialog
          maxWidth={'xl'}
          fullWidth
          open={true}
          keepMounted
          onClose={() => {
            this.props.setOpenContact(false);
          }}
          style={{ paddingTop: 64 }}
          PaperProps={{ className: this.props.classes.paper }}
        >
          <DialogTitle
            title={this.props.title}
            titleProps={{
              component: 'h1',
              variant: 'h2',
              color: 'primary',
              noWrap: false,
            }}
            close={() => {
              this.props.setOpenContact(false);
            }}
          />
          <DialogContent>
            <Grid container spacing={2} className={this.props.classes.gridContainer}>
              <Grid item xs={12} {...{ [BREAKPOINT]: 6 }} className={this.props.classes.messageContainer}>
                <Typography component="h2" variant="h3" color="textPrimary">
                  {this.props.messageTitle}
                </Typography>
                {form}
                <Typography variant="bodyEmphasis">
                  {!this.props.userId && 'We will get back to you as soon as we can!'}
                </Typography>
                {sendButton}
              </Grid>
              <Grid item xs={12} {...{ [BREAKPOINT]: 1 }}>
                <Divider orientation={this.props.mediaQuery ? 'horizontal' : 'vertical'}>
                  <Typography component="h2" variant="h3" color="textPrimary">
                    or
                  </Typography>
                </Divider>
              </Grid>
              <Grid item xs={12} {...{ [BREAKPOINT]: 5 }} className={this.props.classes.calendlyContainer}>
                <Typography component="h2" variant="h3" color="textPrimary">
                  Book a Demo
                </Typography>
                <iframe
                  src="https://calendly.com/ellipsisdrive/30min"
                  style={{ border: 'none' }}
                  title="demo"
                  width="100%"
                  height="100%"
                />
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      );
    } else {
      return (
        <Dialog
          open={true}
          keepMounted
          onClose={() => {
            this.props.setOpenContact(false);
          }}
        >
          <DialogTitle
            title={this.props.title}
            titleProps={{
              component: 'h1',
              variant: 'h2',
              color: 'primary',
              noWrap: false,
            }}
            close={() => {
              this.props.setOpenContact(false);
            }}
          />
          <DialogContent>
            <Typography variant="bodyEmphasis">
              {!this.props.userId && 'We will get back to you as soon as we can!'}
            </Typography>
            {form}
          </DialogContent>
          <DialogActions>{sendButton}</DialogActions>
        </Dialog>
      );
    }
  }
}

const withMediaQuery =
  (...args) =>
  (Component) => {
    const Comp = (props) => {
      const mediaQuery = useMediaQuery(...args);
      return <Component mediaQuery={mediaQuery} {...props} />;
    };

    return Comp;
  };

export default withPushToDataLayer(
  withStyles(
    withMediaQuery((theme) => theme.breakpoints.down(BREAKPOINT))(withAuth(withMainContext(Contact))),
    styles,
    { name: 'Contact' }
  )
);
