import { useLocation } from "react-router-dom";

function useQueryParams() {
  const searchParams = new URLSearchParams(useLocation().search);
  let params = {};
  for (let p of searchParams) {
    params[p[0]] = p[1];
  }
  return params;
}

export default useQueryParams;
