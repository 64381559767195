import React from 'react';

import { SvgIcon } from '@mui/material';

import LeaveMapComponent from '../Components/LeaveMap/LeaveMap';

import { handleRemoveAccess } from 'Drive/itemUtils';
import CombinedMenuItem from '../Components/CombinedMenuItem/CombinedMenuItem';

import { useMainContext } from 'ReusableComponents';
import { useMinimalAuth } from 'hooks';
import { useDriveContext } from 'Drive/DriveContext';
import { useSelectedItems } from 'Drive/selectedItemContext';
import { useContextMenu } from '../../ContextMenuContext';

const RemovePerson = (
  <path d="M14,8c0-2.21-1.79-4-4-4S6,5.79,6,8s1.79,4,4,4S14,10.21,14,8z M17,10v2h6v-2H17z M2,18v2h16v-2c0-2.66-5.33-4-8-4 S2,15.34,2,18z" />
);

const LeaveMap = ({ setDialog, onClose }) => {
  const user = useMinimalAuth();
  const { onOpenSnackbar } = useMainContext();
  const { handleOptimisticUpdate } = useDriveContext();
  const { resetSelectedItems } = useSelectedItems();

  const { menuOptions } = useContextMenu();
  const { path } = menuOptions;

  const leaveType = ['folder', 'file', 'bookmark', 'process'].includes(path?.type) ? path?.type : 'layer';

  const leaveMap = () => {
    setDialog({
      title: `Leave ${leaveType}`,
      content: (
        <LeaveMapComponent
          leaveType={leaveType}
          cancel={() => setDialog(null)}
          confirm={() => {
            onClose();
            resetSelectedItems();
            handleRemoveAccess({
              path,
              user,
              onOpenSnackbar,
              handleOptimisticUpdate,
            });
          }}
        />
      ),
    });

    onClose();
  };

  return <CombinedMenuItem icon={<SvgIcon>{RemovePerson}</SvgIcon>} label={`Leave ${leaveType}`} action={leaveMap} />;
};

export default LeaveMap;
