import React, { useState, useEffect, useCallback /* , useMemo */ } from 'react';

import {
  DialogContent,
  Breadcrumbs,
  Button,
  CircularProgress,
  DialogActions,
  // Link,
  Typography,
  // DialogContentText,
} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { makeStyles } from 'tss-react/mui';
import { Folder, FolderRounded } from '@mui/icons-material';
import { useMinimalAuth } from 'hooks';
import ApiManager from 'ApiManager';
// import { useCurrentFolder } from 'Drive/CurrentFolderContext';
import { Alert } from '@mui/material';
import VersionUtility from 'VersionUtility';

const useStyles = makeStyles()((theme) => ({
  content: {
    gap: theme.spacing(1),
    display: 'flex',
    maxWidth: '100%',
    minWidth: 0,
    flexDirection: 'row',
    flexWrap: 'wrap',
    // justifyContent: 'space-between',
    alignItems: 'flex-start',
    alignContent: 'flex-start',

    '& $folderContainer+.MuiAlert-root': {
      marginTop: 12,
    },
  },
  folderButton: {
    flex: `0 0 calc(50% - ${theme.spacing(0.5)}px)`,
    textTransform: 'none',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  skeleton: {
    flex: `0 0 calc(50% - ${theme.spacing(0.5)}px)`,
    height: '50px',
    borderRadius: '4px',
    marginBottom: '-8px',
  },
  breadcrumbContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: theme.spacing(2, 3),
    paddingTop: 0,
  },
  breadcrumbIcon: {
    color: theme.palette.action.active,
    marginRight: '1rem',
    marginTop: 6,
  },
  emptyText: {
    color: theme.palette.text.secondary,
    padding: '16px',
    margin: 'auto',
    maxWidth: '80%',
    wordBreak: 'break-all',
  },
}));

const rootNameMapping = {
  myMaps: 'My Drive',
  shared: 'Shared with me',
  sharedWithMe: 'Shared with me',
  favorites: 'Favorites',
};

const MoveTo = ({
  handleMove,
  setCurrentPathId,
  closeDialog,
  selectedItems,
  type,
  blockSubmit,
  bookmark,
  setDisabled,
  currentRoot,
  loadingSubmit,
  currentAccess,
}) => {
  const { classes } = useStyles();
  const user = useMinimalAuth();

  const [folders, setFolders] = useState(null);
  const [loading, setLoading] = useState(false);
  const [root, setRoot] = useState(currentRoot);

  useEffect(() => {
    if (bookmark) {
      setCurrentPathId(root.path.length > 0 ? root.path[root.path.length - 1].id : null);
    }
  }, [root, bookmark, setCurrentPathId]);

  const [selectedPath, setSelectedPath] = useState(null);

  const fetchFolders = useCallback(
    async (pathId = null, nextPageStart = null) => {
      if (!root.root && !pathId) {
        return;
      }
      try {
        setLoading(true);
        let res = await ApiManager.get(
          pathId
            ? `/v3/path/${pathId}/list`
            : `/v3/account/root/${ApiManager.rootNames[root.root] ? ApiManager.rootNames[root.root] : root.root}`,
          {
            isFolder: true,
            pageSize: 50,
            pageStart: nextPageStart,
          },
          user
        );

        res = VersionUtility.convertListRoot(res);
        res.result = res.result?.filter((folder) => !folder?.deleted);

        if (pathId) {
          let res = VersionUtility.convertPathInfo(await ApiManager.get(`/v3/path/${pathId}`, null, user));
          setSelectedPath(res);
        } else {
          setSelectedPath(null);
        }

        setFolders((folders) => (!!nextPageStart ? [...folders, ...res.result] : res.result));

        if (!!res?.nextPageStart) {
          fetchFolders(pathId, res.nextPageStart);
        } else {
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
        setLoading(false);

        return { result: null, nextPageStart: null };
      }
    },
    [root, user]
  );

  const handleMoveFolder = (id, name) => {
    setRoot((prev) => {
      return { ...prev, path: [...prev.path, { id, name }] };
    });
  };

  const handleBreadCrumbClick = (index) => {
    if (index === root.path.length - 1) {
      return;
    }

    setRoot((prev) => {
      if (index || index === 0) {
        let x = [...prev.path];
        x = x.slice(0, index + 1);

        return { ...prev, path: x };
      } else {
        return { ...prev, path: [] };
      }
    });
  };

  useEffect(() => {
    fetchFolders(root.path[root.path.length - 1]?.id);
  }, [fetchFolders, root]);

  const access = selectedPath ? selectedPath.yourAccess.accessLevel >= ApiManager.newAccessLevel['edit+'] : false;

  const owner = selectedPath?.user;
  const originPath = currentRoot.path.length > 0 ? currentRoot.path[currentRoot.path.length - 1] : null;

  const moveOwner = selectedPath
    ? selectedPath.yourAccess.accessLevel >= ApiManager.newAccessLevel['admin'] &&
      currentAccess >= ApiManager.newAccessLevel['admin']
    : currentAccess >= ApiManager.newAccessLevel['admin'];

  const alert = !root.root
    ? 'Cannot place an item here'
    : root.root === 'shared' && root.path?.length === 0
    ? 'You cannot place items in shared with me'
    : root.root === 'favorites' && root.path?.length === 0
    ? 'You cannot place items in favorites'
    : !access && root.path.length > 0
    ? 'No permission to place items here, you need to have at least edit+'
    : (selectedItems[0]?.user.id !== owner?.id || (!owner && selectedItems[0]?.user.id !== user.id)) &&
      type !== 'create' &&
      !moveOwner
    ? 'You need to have admin access to both folders, when moving an element to a folder with of a different owner'
    : root.length > 0 && root.path[root.path.length - 1].id === selectedItems[0]?.id && type !== 'create'
    ? 'Not allowed to move path to the same location'
    : type !== 'create' && (selectedPath?.id === originPath?.id || (!selectedPath && !originPath))
    ? 'Cannot move to the original folder'
    : null;

  useEffect(() => {
    if (bookmark) {
      if (alert) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
  }, [alert, bookmark]);

  const pathIds = selectedItems?.map((i) => i.id);

  return (
    <>
      <div className={classes.breadcrumbContainer}>
        <Folder className={classes.breadcrumbIcon} />
        <Breadcrumbs aria-label="folder breadcrumb">
          <Button color={!root ? 'primary' : 'inherit'} onClick={() => setRoot({ root: null, path: [] })}>
            Ellipsis
          </Button>

          <Button color={root.path.length === 0 ? 'primary' : 'inherit'} onClick={() => handleBreadCrumbClick(null)}>
            {rootNameMapping[root.root]}
          </Button>
          {root.path
            ? [...root.path]?.map(({ id, name }, i) => (
                <Button
                  color={i === root.path.length - 1 ? 'primary' : 'inherit'}
                  key={id}
                  className={classes.folderButton}
                  onClick={() => handleBreadCrumbClick(i)}
                >
                  {name}
                </Button>
              ))
            : null}
        </Breadcrumbs>
      </div>
      {alert && <Alert severity="warning">{alert}</Alert>}
      <DialogContent className={classes.content} dividers>
        {!loading ? (
          <>
            {!root.root ? (
              <>
                <Button
                  variant="outlined"
                  className={classes.folderButton}
                  onClick={() => {
                    setRoot({ root: 'myMaps', path: [] });
                  }}
                  startIcon={<FolderRounded />}
                  color="inherit"
                >
                  My Drive
                </Button>
                <Button
                  variant="outlined"
                  className={classes.folderButton}
                  onClick={() => {
                    setRoot({ root: 'shared', path: [] });
                  }}
                  startIcon={<FolderRounded />}
                  color="inherit"
                >
                  Shared With Me
                </Button>
              </>
            ) : folders?.filter((folder) => !pathIds.includes(folder.id))?.length > 0 ? (
              folders
                ?.filter((folder) => !pathIds.includes(folder.id))
                ?.map(({ id, name }, i) => (
                  <Button
                    variant="outlined"
                    key={id}
                    className={classes.folderButton}
                    onClick={() => handleMoveFolder(id, name)}
                    startIcon={<FolderRounded />}
                    color="inherit"
                  >
                    {name}
                  </Button>
                ))
            ) : (
              <Typography className={classes.emptyText}>No folders</Typography>
            )}
          </>
        ) : (
          new Array(6).fill(null).map((item, i) => <Skeleton key={i} className={classes.skeleton} />)
        )}
      </DialogContent>
      {!bookmark && (
        <DialogActions>
          {loadingSubmit ? (
            <CircularProgress />
          ) : (
            <React.Fragment>
              <Button onClick={closeDialog}>Cancel</Button>
              <Button
                onClick={() =>
                  handleMove(
                    selectedPath,
                    (owner && selectedItems[0]?.user.id !== owner.id) ||
                      (!owner && selectedItems[0]?.user.id !== user.id)
                  )
                }
                color="primary"
                disabled={!!alert || blockSubmit}
              >
                {type === 'create' ? 'Create Layer' : 'Move here'}
              </Button>
            </React.Fragment>
          )}
        </DialogActions>
      )}
    </>
  );
};

export default MoveTo;
