import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { Tabs, Tab } from '@mui/material';

const useStyles = makeStyles()((theme, { fancyTransparentThingy }) => ({
  tabsRoot: {
    paddingBottom: '10px',
    marginTop: '10px',
    marginBottom: '6px',
    overflow: 'hidden',
    maxWidth: '100%',
    position: 'relative',
    whiteSpace: 'nowrap',
    '&::after': {
      content: fancyTransparentThingy ? "''" : '',
      position: 'absolute',
      pointerEvents: 'none',
      top: 0,
      right: '-20%',
      height: '100%',
      width: '30%',
      backgroundImage: 'linear-gradient(to right, transparent, white)',
    },
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '0px',
    },
  },
  tabsIndicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.light,
    height: '4px',
  },
  tabsScroller: {
    display: 'flex',
    alignItems: 'center',
  },
  tab: {
    minWidth: 0,
    padding: '3px',
    marginRight: '24px',
    marginLeft: '2px',
    lineHeight: 'normal',
    fontSize: '1.1rem',
    textTransform: 'none',
  },
  tabWrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    alignItems: 'center',
  },
  tabSelected: {
    // fontWeight: 600,
  },
}));

function CustomTabs({ tabs, tabValue, handleTabChange, scrollButtons = 'on', className = '', icons = {} }) {
  const { classes } = useStyles({ fancyTransparentThingy: scrollButtons === 'off' });

  return (
    <Tabs
      value={tabValue}
      indicatorColor="primary"
      textColor="primary"
      onChange={handleTabChange}
      classes={{
        root: `${classes.tabsRoot} ${className}`,
        indicator: `${classes.tabsIndicator}`,
        scroller: `${classes.tabsScroller}`,
      }}
      TabIndicatorProps={{ children: <span /> }}
      // orientation={xs ? "vertical" : "horizontal"}
      variant="scrollable"
      scrollButtons={scrollButtons}
    >
      {tabs.map((tab, i) => (
        <Tab
          classes={{
            root: `${classes.tab}`,
            selected: `${classes.tabSelected}`,
            wrapper: classes.tabWrapper,
          }}
          label={tab}
          key={`${tab}-${i}`}
          icon={icons[tab]}
        />
      ))}
    </Tabs>
  );
}

CustomTabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
  tabValue: PropTypes.number.isRequired,
  handleTabChange: PropTypes.func.isRequired,
  scrollButtons: PropTypes.string,
  className: PropTypes.string,
};

export default CustomTabs;
