import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  ListItem,
  ListItemText,
  Stack,
  TextField,
} from '@mui/material';
import DialogTitle from 'ReusableComponents/DialogTitle/DialogTitle';
import { useCurrentFolder } from 'Drive/CurrentFolderContext';
import MarkdownWrapper from 'ReusableComponents/MarkdownEditor/MarkdownWrapper';
import ApiManager from 'ApiManager';
import { useMinimalAuth } from 'hooks';
import { useMainContext } from 'ReusableComponents/MainContext';
import { useDriveContext } from 'Drive/DriveContext';

const NewJobDialog = ({ open, closeDialog, pathId, cb }) => {
  const { getPath } = useDriveContext();
  const { currentFolderInfo } = useCurrentFolder();

  const [path, setPath] = useState();

  useEffect(() => {
    if (open) {
      setPath(pathId ? getPath(pathId) : currentFolderInfo);
    }
  }, [currentFolderInfo, getPath, open, pathId]);

  return (
    <Dialog open={open} fullWidth onClose={(e) => e?.stopPropagation()}>
      <DialogTitle color="primary" onClose={closeDialog}>
        Define parameters
      </DialogTitle>
      <NewJobStack path={path} closeDialog={closeDialog} cb={cb} />
    </Dialog>
  );
};

export default NewJobDialog;

const NewJobStack = ({ path, closeDialog, cb }) => {
  const user = useMinimalAuth();

  const { onOpenSnackbar } = useMainContext();

  const [newParameters, setNewParameters] = useState([]);

  useEffect(() => {
    setNewParameters(path?.process?.parameters?.map((p) => ({ name: p?.name, value: '' })));
  }, [path?.process?.parameters]);

  const setValue = useCallback((i, value) => {
    setNewParameters((old) => old?.map((p, j) => (i === j ? { name: p?.name, value } : p)));
  }, []);

  const hasError = useMemo(
    () => newParameters?.map((n) => n?.value?.length === 0 || !!n?.error)?.includes(true),
    [newParameters]
  );

  const execute = useCallback(() => {
    if (hasError) {
      setNewParameters((old) => old?.map((o) => (o?.value?.length === 0 ? { ...o, error: true } : { ...o })));
    } else {
      const body = { parameters: newParameters };

      ApiManager.post(`/v3/path/${path?.id}/process/execute`, body, user)
        .then((res) => {
          typeof cb === 'function' && cb();
          closeDialog();
        })
        .catch((e) => {
          onOpenSnackbar({ level: 'error', content: e?.message });
          console.log(e);
        });
    }
  }, [cb, closeDialog, hasError, newParameters, onOpenSnackbar, path, user]);

  return (
    <>
      {path?.process?.parameters?.length > 0 && (
        <DialogContent sx={{ '&&': { pt: 2 } }}>
          <Stack gap={1}>
            {path?.process?.parameters?.map((p, i) => (
              <NewJobItem
                key={`${p?.name}_${i}`}
                {...p}
                error={newParameters?.[i]?.error}
                value={newParameters?.[i]?.value ?? ''}
                setValue={setValue}
                i={i}
              />
            ))}
          </Stack>
        </DialogContent>
      )}
      <DialogActions>
        <Button variant="contained" onClick={execute} disabled={hasError}>
          Execute Process
        </Button>
      </DialogActions>
    </>
  );
};

const NewJobItem = ({ name, example, description, error, value, setValue, i }) => {
  const handleChange = useCallback((e) => setValue(i, e?.target?.value), [i, setValue]);

  return (
    <Box>
      <TextField label={name} placeholder={example} fullWidth value={value} onChange={handleChange} error={error} />
      <ListItem dense>
        <ListItemText
          secondary={
            <MarkdownWrapper textProps={{ variant: 'body2', color: 'text.secondary' }}>{description}</MarkdownWrapper>
          }
        />
      </ListItem>
    </Box>
  );
};
