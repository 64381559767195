import React from 'react';

import {
  Avatar,
  Button,
  CircularProgress,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Error, OpenInNew } from '@mui/icons-material';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const useStyles = makeStyles({ name: 'SectionMenuItemDownload' })((theme) => ({
  container: {},
  projectTypeIcon: {
    height: '40px',
    width: '40px',
  },
  textContainer: {},
  title: {},
  legend: {},
  iconButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(1),
  },
  loadingText: {
    color: 'inherit',
  },
  downloadDoneRoot: {
    height: '40px',
    width: '40px',
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.light,
    },
  },
  downloadIconRoot: {
    height: '40px',
    width: '40px',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

const SectionMenuItemImports = ({ title, legend, status, thumbnail, pathId, handleClose }) => {
  const { classes } = useStyles();
  const history = useHistory();

  const isCompleted = status === 'completed';

  return (
    <ListItem className={classes.container}>
      {thumbnail && (
        <ListItemAvatar>
          <Avatar src={thumbnail} variant="rounded" alt={title} loading="lazy">
            <Skeleton variant="rectangular" width="100%" height="100%" />
          </Avatar>
        </ListItemAvatar>
      )}

      <ListItemText
        primary={title}
        secondary={
          isCompleted ? (
            <Button
              variant="text"
              color="primary"
              className={classes.button}
              disabled={!pathId}
              onClick={() => {
                history.push(`/drive/me?pathId=${pathId}`);
                handleClose();
              }}
              startIcon={<OpenInNew />}
            >
              Go to layer
            </Button>
          ) : (
            legend
          )
        }
        primaryTypographyProps={{ noWrap: true }}
        secondaryTypographyProps={{ color: status === 'errored' ? 'error' : undefined }}
      />
      {!isCompleted && (
        <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
          <ItemIcon status={status} />
        </ListItemIcon>
      )}
    </ListItem>
  );
};

const ItemIcon = ({ status }) => {
  const { classes, theme } = useStyles();

  if (status === 'created' || status === 'pending' || status === 'processing') {
    return (
      <div className={classes.iconButtonContainer} style={{ color: theme.palette.primary.main }}>
        <CircularProgress size={24} />
        <Typography className={classes.loadingText} variant="overline">
          {status === 'created' ? 'pending' : status}
        </Typography>
      </div>
    );
  } else {
    // error
    return (
      <div className={classes.iconButtonContainer}>
        <Error color="inherit" style={{ color: theme.palette.error.main }} />
        <Typography className={classes.loadingText} variant="overline">
          error
        </Typography>
      </div>
    );
  }
};

export default SectionMenuItemImports;
