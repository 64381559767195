import React from 'react';

import { Collapse } from '@mui/material';
import PropTypes from 'prop-types';

import { useUploadContext } from './uploadContext';

const UploadContent = ({ children }) => {
  const { showAll } = useUploadContext();

  return <Collapse in={showAll}>{children}</Collapse>;
};

UploadContent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UploadContent;
