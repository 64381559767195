import { Slider } from '@mui/material';
import { withStyles } from 'tss-react/mui';

const StyledSlider = withStyles(Slider, (_theme, _params, classes) => ({
  root: {
    width: '8px !important',
  },
  thumb: {
    width: 24,
    height: 24,
    border: '2px solid currentColor',
    marginTop: '-12px !important',
    marginLeft: '-8px !important',
    [`&:focus, &:hover, &.${classes.active}`]: {
      boxShadow: 'inherit',
    },
  },
  mark: {
    left: '16px !important',
  },
  markLabel: {
    left: '40px !important',
  },
  active: {},
  track: {
    width: '8px !important',
    borderRadius: 4,
  },
  rail: {
    width: '8px !important',
    borderRadius: 4,
  },
}));

export default StyledSlider;
