import React from 'react';

import { CircularProgress, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { CheckCircle } from '@mui/icons-material';
import PropTypes from 'prop-types';

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  successContainer: {
    display: 'flex',
    alignSelf: 'flex-start',
  },
  checkIconContainer: {},
  checkIcon: {},
  title: {},
  legend: {},
}));

const FinishedStep = ({ loading = false }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      {loading ? (
        <CircularProgress />
      ) : (
        <ListItem sx={{ p: 0 }}>
          <ListItemIcon>
            <CheckCircle className={classes.checkIcon} color="success" />
          </ListItemIcon>
          <ListItemText primary="Layer creation was successful" secondary="You are now ready to upload your files" />
        </ListItem>
      )}
    </div>
  );
};

FinishedStep.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default FinishedStep;
