import React, { useState, useEffect, useCallback } from 'react';

import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import ApiManager from '../../../ApiManager';
import { SectionTitle, useMainContext } from '../../../ReusableComponents';
import { useMinimalAuth } from '../../AuthContext';
import ApplicationListItem from './ApplicationListItem';

const useStyles = makeStyles()((theme) => ({
  caption: {
    marginBottom: '6px',
    marginTop: '-8px',
    hyphens: 'none',
  },
  emptyContainer: {
    width: '100%',
    boxSizing: 'border-box',
    padding: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  authorizedAppsContainer: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '6px',
    width: '100%',
  },
  appsTitle: {
    marginBottom: '10px',
    marginRight: 'auto',
    marginTop: '30px',
  },
}));

const Applications = () => {
  const { classes } = useStyles();
  const user = useMinimalAuth();
  const { onOpenSnackbar } = useMainContext();

  const [authorizedApps, setAuthorizedApps] = useState(null);

  const fetchClients = useCallback(async () => {
    const clients = await ApiManager.get('/v3/oauth/myClients', null, user);
    setAuthorizedApps(clients);
  }, [user]);

  useEffect(() => {
    fetchClients();
  }, [user, fetchClients]);

  const handleRevokeApp = async (userId) => {
    const res = await ApiManager.post('/oauth/revoke', { clientId: userId }, user);

    if (!!res) {
      await fetchClients();
      onOpenSnackbar({
        level: 'success',
        content: 'Authorization successfully revoked.',
      });
    }
  };

  return (
    <div>
      <SectionTitle first={true}>
        <SectionTitle.SectionTitleText>Authorized OAuth Apps</SectionTitle.SectionTitleText>
      </SectionTitle>
      <Typography variant="body1" className={classes.caption}>
        You can connect your Ellipsis Drive account to third party applications using OAuth. When authorizing an OAuth
        App, you should ensure you trust the application and review that you are fine with the permissions you grant the
        application.
      </Typography>
      {authorizedApps?.length > 0 ? (
        <>
          <Typography className={classes.appsTitle}>These third parties have access to your drive:</Typography>
          <div className={classes.authorizedAppsContainer}>
            {authorizedApps.map((app) => (
              <ApplicationListItem
                key={app.userId}
                user={{ id: app.userId }}
                scope={app.scope[0]}
                handleDelete={() => handleRevokeApp(app.userId)}
                {...app}
              />
            ))}
          </div>
        </>
      ) : (
        <div className={classes.emptyContainer}>
          <Typography variant="subtitle1">No authorized OAuth Apps.</Typography>
        </div>
      )}
    </div>
  );
};

export default Applications;
