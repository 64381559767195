import React, { useEffect } from 'react';

import { Collapse, Typography } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import { Close } from '@mui/icons-material';
import PropTypes from 'prop-types';

import { LoadingIconButtonWithTooltip } from '../';

const useStyles = makeStyles()((theme) => ({
  container: {
    width: '100%',
    maxWidth: '660px',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
    borderRadius: '4px',
    marginBottom: '12px',
    display: 'grid',
    gridTemplateColumns: '1fr 40px',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px 10px',
    boxSizing: 'border-box',
  },
  content: {
    textAlign: 'center',
  },
  closeButton: {
    padding: '5px',
    marginLeft: 'auto',
  },
}));

const colorFromType = (type, theme) => {
  if (type === 'info') return theme.palette.info.dark;
  if (type === 'success') return theme.palette.success.dark;
  if (type === 'warning') return 'hsl(30, 100%, 40%)';
  if (type === 'error') return theme.palette.error.dark;
  return theme.palette.common.white;
};

const NotificationBar = ({ show, closeNotification, type, text, duration = 5000 }) => {
  const { classes, theme } = useStyles();

  useEffect(() => {
    let timeout = null;
    if (duration && show) {
      timeout = setTimeout(() => {
        closeNotification();
      }, duration);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [duration, closeNotification, show]);

  return (
    <Collapse in={show}>
      <div
        className={classes.container}
        style={{
          backgroundColor: colorFromType(type, theme),
          color: theme.palette.getContrastText(colorFromType(type, theme)),
        }}
      >
        {React.isValidElement ? text : <Typography className={classes.content}>{text}</Typography>}
        <LoadingIconButtonWithTooltip
          title={'Close'}
          onClick={closeNotification}
          className={classes.closeButton}
          style={{ color: theme.palette.getContrastText(colorFromType(type, theme)) }}
        >
          <Close />
        </LoadingIconButtonWithTooltip>
      </div>
    </Collapse>
  );
};

NotificationBar.propTypes = {
  show: PropTypes.bool.isRequired,
  closeNotification: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['info', 'warning', 'error', 'success']).isRequired,
  text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  duration: PropTypes.number,
};

export default NotificationBar;
