import React from 'react';

import { Typography, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const useStyles = makeStyles()((theme) => ({
  title: {},
  mainSubTitle: {},
  subTitle: {},
  button: {},
}));

const ErrorContent = ({ error }) => {
  const { classes } = useStyles();

  let errorMessage = '';

  switch (error) {
    case 'backend-error':
      errorMessage = `There might be something fishy with the app who referred you.`;
      break;
    case 'wrong-parameters':
      errorMessage = `The third party app didn't provide all the needed information to process the authorization request.`;
      break;
    case 'error-client-profile':
      errorMessage = 'Something went wrong during the authorization process.';
      break;
    default:
      errorMessage = error;
      break;
  }

  return (
    <>
      <Typography className={classes.title} variant="h3" paragraph>
        Something went wrong.
      </Typography>

      <Typography className={classes.mainSubTitle}>{errorMessage}</Typography>
      <Typography className={classes.subTitle}>
        Please try again later or contact the third party app if the problem persists.
      </Typography>

      <Button className={classes.button} variant="contained" color="primary" component={Link} to="/drive/me">
        Go to Drive
      </Button>
    </>
  );
};

ErrorContent.propTypes = {
  error: PropTypes.string.isRequired,
};

export default ErrorContent;
