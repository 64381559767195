import React from 'react';

import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Helmet } from 'react-helmet';

import FullScreenLayout from '../FullScreenLayout';
import ApiManager from 'ApiManager';

const useStyles = makeStyles()((theme) => ({
  paperContainer: {
    // alignItems: 'center',
  },
  title: {
    marginTop: '30px',
  },
  subTitle: {
    marginTop: '2rem',
  },
  needHelpContainer: {
    marginTop: '2rem',
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    justifyContent: 'center',
  },
  needHelpText: {},
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    paddingLeft: '5px',
  },
}));

const VerificationNeededPage = () => {
  const { classes } = useStyles();
  return (
    <FullScreenLayout paperClassName={classes.paperContainer}>
      <Helmet>
        <meta name="robots" content="noindex" data-react-helmet="true" />
        <title>Verify email - {ApiManager?.displayName ?? 'Ellipsis Drive'}</title>
      </Helmet>
      <Typography className={classes.title} component="h1" variant="h3">
        Your account has been created!
      </Typography>
      <Typography className={classes.title} component="h2" variant="subtitle1">
        Please verify your email.
      </Typography>
      <Typography className={classes.subTitle}>{`You're almost there! We've just sent you an email.`}</Typography>
      <Typography className={classes.subTitle}>
        {`Just click on the link in that email to complete your sign up. If you don't see it, you may need to `}
        <b>check your spam</b> folder.
      </Typography>
      <div className={classes.needHelpContainer}>
        <Typography className={classes.needHelpText}>Need help?</Typography>
        <Typography component="a" className={classes.link} href="https://ellipsis-drive.com/contact/">
          Contact us
        </Typography>
      </div>
    </FullScreenLayout>
  );
};

export default VerificationNeededPage;
