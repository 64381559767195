import React from 'react';

import { SvgIcon } from '@mui/material';

const DeleteForeverSweep = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        clipRule="evenodd"
        d="m18 16h-2c-.55 0-1 .45-1 1s.45 1 1 1h2c.55 0 1-.45 1-1s-.45-1-1-1zm3-8h-5c-.55 0-1 .45-1 1s.45 1 1 1h5c.55 0 1-.45 1-1s-.45-1-1-1zm-1 4h-4c-.55 0-1 .45-1 1s.45 1 1 1h4c.55 0 1-.45 1-1s-.45-1-1-1zm-15 8c-1.1 0-2-.9-2-2v-10h10v10c0 1.1-.9 2-2 2zm6-15h2c.55 0 1 .45 1 1s-.45 1-1 1h-10c-.55 0-1-.45-1-1s.45-1 1-1h2l.71-.71c.18-.18.44-.29.7-.29h3.18c.26 0 .52.11.7.29zm-4.86151 5.8622-1.23227 1.2534 1.86151 1.8844-1.85277 1.8844 1.23227 1.2534 1.85277-1.8845 1.85277 1.8845 1.32603-1.1156-1.85275-2.0222 1.75895-1.8844-1.23223-1.2534-1.85277 1.8845z"
      />
    </SvgIcon>
  );
};

export default DeleteForeverSweep;
