import React, { forwardRef } from 'react';

import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles({ name: 'CenteredBox' })((theme) => ({
  root: {
    maxHeight: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: theme.spacing(4),
    padding: theme.spacing(2, 4),
    boxSizing: 'border-box',
    marginTop: 'auto',
    marginBottom: 'auto',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
}));

export const CenteredBox = forwardRef(({ children, className, onContextMenu, ...props }, ref) => {
  const { classes: styles, cx } = useStyles();

  return (
    <Box ref={ref} className={cx(styles.root, className)} onContextMenu={onContextMenu} {...props}>
      {children}
    </Box>
  );
});

CenteredBox.displayName = 'CenteredBox';
