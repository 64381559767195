import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import moment from 'moment';
import isEqual from 'lodash/isEqual';

import { InputAdornment, TextField, IconButton, Tooltip, Badge } from '@mui/material';
import { Clear, Search, Tune } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';

import { useMinimalAuth } from 'hooks';
import { useCurrentFolder } from '../CurrentFolderContext';

import AdvancedSearch from './AdvancedSearch';

import { Active, Bounds, Date, Hashtag, Resolution, Text, Type, User } from './Inputs';

export const GENERAL_INPUTS = [
  {
    key: 'text',
    label: 'text',
    component: Text,
    placeholder: 'Text search for name, description and style names',
    initialValue: '',
  },
  { key: 'type', label: 'type', component: Type, initialValue: [] },
  { key: 'userId', label: 'user', placeholder: 'Demo User', component: User, initialValue: null },
  { key: 'hashtag', label: 'hashtag', placeholder: 'AHN', component: Hashtag, initialValue: '' },
];
export const SPATIAL_INPUTS = [
  { key: 'extent', label: 'extent', component: Bounds, initialValue: null },
  { key: 'date', label: 'date', component: Date, reset: true, initialValue: null },
  { key: 'resolution', label: 'resolution', component: Resolution, reset: true, initialValue: null },
  { key: 'active', label: 'content status', component: Active, initialValue: null },
];

export const INITIAL_SEARCH_VALS = [...GENERAL_INPUTS, ...SPATIAL_INPUTS].reduce(
  (acc, curr) => ({
    ...acc,
    [curr?.key]: curr?.initialValue,
  }),
  {}
);

const useStyles = makeStyles({ name: 'SearchInput' })((theme) => ({
  root: {
    '& .MuiFormHelperText-root': { lineHeight: '24px' },
    maxWidth: 'min(calc(750px), calc(100vw - 32px))',
  },
  open: {},
  input: {
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.primary.surface,
    '& .MuiOutlinedInput-notchedOutline': { borderWidth: 0 },
  },
}));

const parseVals = (input) =>
  Object.entries(INITIAL_SEARCH_VALS).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: !!input?.[key]
        ? key === 'active'
          ? input?.[key] === true
            ? ['active']
            : ['passive']
          : key === 'date'
          ? { from: moment(input?.[key]?.from), to: moment(input?.[key]?.to) }
          : input?.[key]
        : value,
    }),
    {}
  );

const SearchInput = () => {
  const { getSearchValue, setSearchValue } = useCurrentFolder();
  const user = useMinimalAuth();
  const { classes: styles } = useStyles();

  const searchValues = useMemo(getSearchValue, [getSearchValue]);

  const [searchVals, setSearchVals] = useState(parseVals(searchValues));
  const { text } = { ...searchVals };

  const [open, setOpen] = useState(false);

  const searchRef = useRef();

  const handleChange = (e) => {
    setSearchVals((old) => ({ ...old, text: e.target.value }));
  };

  const searchNow = (props) => {
    const parsedValues = Object.entries(props || searchVals).reduce(
      (acc, [key, value]) =>
        (spatialDisabled && SPATIAL_INPUTS?.map((i) => i?.key).includes(key)) ||
        isEqual(value, INITIAL_SEARCH_VALS[key])
          ? { ...acc }
          : key === 'date'
          ? { ...acc, [key]: { from: value?.from?.toISOString(true), to: value?.to?.toISOString(true) } }
          : key === 'active'
          ? { ...acc, ...(!value || value?.length !== 1 ? {} : { [key]: value?.includes('active') }) }
          : { ...acc, [key]: value },
      {}
    );

    if (Object.keys(parsedValues)?.length > 0) {
      setSearchValue(parsedValues);
    } else if (Object.keys(searchValues)?.length > 0) {
      clearSearch();
    }
  };

  const closeAdvanced = (e) => {
    e?.stopPropagation();

    setOpen(false);
  };

  const clearSearch = useCallback(() => {
    // setOpen(false);
    setSearchValue('');
    setSearchVals(parseVals());
  }, [setSearchValue]);

  const firstRun = useRef(true);
  useEffect(() => {
    if (firstRun?.current === true) {
      setSearchVals(parseVals(searchValues));
      firstRun.current = false;
    }

    return () => (firstRun.current = true);
  }, [searchValues]);

  const spatialDisabled = useMemo(
    () =>
      searchVals?.type.includes('folder') ||
      searchVals?.type.includes('file') ||
      searchVals?.type.includes('bookmark') ||
      searchVals?.type.includes('process') ||
      searchVals?.type?.length === 0,
    [searchVals?.type]
  );

  return (
    <>
      <TextField
        ref={searchRef}
        className={styles.root}
        disabled={!user}
        label={'Search in Drive'}
        onKeyDown={(e) => {
          if (e.keyCode === 13) searchNow();
        }}
        // type="search"
        variant="outlined"
        value={text || ''}
        InputProps={{
          className: styles.input,
          endAdornment: (
            <InputAdornment position="end">
              {parseVals(searchValues)?.text !== text || !text ? (
                <Tooltip title="Search">
                  <span>
                    <IconButton onClick={() => searchNow()}>
                      <Search />
                    </IconButton>
                  </span>
                </Tooltip>
              ) : (
                (!!Object.keys(searchValues)?.length || !!text) && (
                  <Tooltip title="Reset">
                    <IconButton onClick={clearSearch}>
                      <Clear />
                    </IconButton>
                  </Tooltip>
                )
              )}
              <Tooltip title="Advanced Search">
                <IconButton color={open ? 'primary' : undefined} onClick={() => setOpen((old) => !old)}>
                  <Badge color="secondary" badgeContent={Object.keys(searchValues)?.length}>
                    <Tune />
                  </Badge>
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
        onChange={handleChange}
        fullWidth
      />
      <AdvancedSearch
        open={open}
        searchRef={searchRef}
        onClose={closeAdvanced}
        handleSearch={searchNow}
        searchVals={searchVals}
        setSearchVals={setSearchVals}
        clearSearch={clearSearch}
        spatialDisabled={spatialDisabled}
      />
    </>
  );
};

export default SearchInput;
