import { Button, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import React from 'react';

const LeaveMap = ({ cancel, confirm, leaveType }) => {
  const handleConfirm = () => {
    confirm();
    cancel();
  };
  return (
    <React.Fragment>
      <DialogContent>
        <DialogContentText>
          You are about to leave this {leaveType}. This means you will lose access to this {leaveType} until you get
          reinvited or resubscribe.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={cancel} color="inherit">
          Cancel
        </Button>
        <Button onClick={handleConfirm} color="primary">
          Leave
        </Button>
      </DialogActions>
    </React.Fragment>
  );
};

export default LeaveMap;
