import React from 'react';

import { Route } from 'react-router-dom';

import { COOKIE_TYPES, useConsentValue } from 'ConsentContext';

import * as Sentry from '@sentry/react';
const SentryRouteComponent = Sentry.withSentryRouting(Route);

const SentryRoute = ({ ...props }) => {
  const consent = useConsentValue();

  return consent?.[COOKIE_TYPES?.ANALYTICAL] ? <SentryRouteComponent {...props} /> : <Route {...props} />;
};

export default SentryRoute;
