import React from 'react';

import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  title: {},
  subTitle: {},
}));

const SuccessRedirect = ({ requestStatus }) => {
  const { classes } = useStyles();
  return (
    <>
      <Typography className={classes.title} variant="h3" paragraph>
        {requestStatus === 'accepted' ? `Authorization successful!` : `Denied authorization successfully.`}
      </Typography>

      <Typography className={classes.subTitle} gutterBottom>
        You will be redirected back to the third party website in 5 seconds.
      </Typography>
    </>
  );
};

export default SuccessRedirect;
