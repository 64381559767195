import React, { useMemo, forwardRef } from 'react';

import { IconButton, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';

const LoadingIconButtonWithTooltip = forwardRef(
  ({ children, loading = false, title, tooltipProps = {}, ...iconButtonProps }, ref) => {
    const modifiedTooltipProps = useMemo(
      () => ({
        enterDelay: 300,
        ...tooltipProps,
      }),
      [tooltipProps]
    );

    return iconButtonProps.disabled ? (
      <IconButton aria-label={title} {...iconButtonProps} ref={ref} size="large">
        {children}
      </IconButton>
    ) : (
      <Tooltip title={title} {...modifiedTooltipProps}>
        <IconButton aria-label={title} {...iconButtonProps} ref={ref} size="large">
          {children}
        </IconButton>
      </Tooltip>
    );
  }
);

LoadingIconButtonWithTooltip.displayName = 'LoadingIconButtonWithTooltip';

LoadingIconButtonWithTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  title: PropTypes.string.isRequired,
  tooltipProps: PropTypes.object,
};

export default LoadingIconButtonWithTooltip;
