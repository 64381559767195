import React, { forwardRef, useEffect, useRef } from 'react';

import PropTypes from 'prop-types';
import { Link, useRouteMatch } from 'react-router-dom';

import { makeStyles } from 'tss-react/mui';

import Skeleton from '@mui/material/Skeleton';

import { parseSize } from 'Storage/BalanceUtility';

import { Box, ListItem, ListItemIcon, ListItemText, Typography, CircularProgress, IconButton } from '@mui/material';
import { useAuthFuncs } from 'hooks';

const useStyles = makeStyles({
  name: 'MenuItem',
})((theme, _params, classes) => ({
  root: {
    background: 'white',
    zIndex: 1,
    borderRadius: theme.spacing(4),
    color: theme.palette.primary.main,
    border: '1px solid transparent',
    boxShadow: `0px 0px 12px -6px ${theme.palette.primary.main}4d`,
    padding: theme.spacing(0.75, 2),

    transition: theme.transitions.create('background-color'),

    '& .MuiListItemText-root, & .MuiListItemIcon-root .MuiSvgIcon-root': {
      transition: theme.transitions.create('color'),
    },

    '& .MuiListItemIcon-root': {
      minWidth: 'inherit',
      marginRight: theme.spacing(2),
    },

    '& .MuiListItemText-root': {
      color: theme.palette.text.primary,
      margin: 0,
    },

    '& .MuiSvgIcon-root': {},

    [`&.${classes.active}`]: {
      zIndex: 2,
      borderColor: theme.palette.primary.main + 'bf',
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main + 'bf',
      '& .MuiListItemText-root, & .MuiListItemIcon-root, & .MuiListItemIcon-root .MuiSvgIcon-root': {
        color: 'inherit',
      },

      '& .MuiListItemText-root': {
        filter: `drop-shadow(0px 0px 4px ${theme.palette.primary.dark}80)`,
      },
      '& .MuiListItemIcon-root .MuiSvgIcon-root': {
        filter: `drop-shadow(0px 0px 4px ${theme.palette.primary.dark}40)`,
      },

      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    },
    [`&.${classes.active} .MuiSvgIcon-root`]: {
      color: theme.palette.primary.contrastText,
    },

    '&.Mui-disabled': {
      color: 'inherit',
    },

    '&:hover': { backgroundColor: theme.palette.primary.main + '40' },
  },
  active: {},
  primaryBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(1.5),
  },

  hovering: {
    background: `${theme.palette.primary.main}40`,
  },
  canDrop: {
    borderColor: theme.palette.primary.main,
  },
}));

const MenuItem = forwardRef((props, ref) => {
  const { classes, cx } = useStyles();

  const active = useRouteMatch(props.url);
  const { fetchUserInfo } = useAuthFuncs();
  var balanceEl = null;
  var storageEl = null;
  var secondaryEl = null;

  const interval = useRef();
  useEffect(() => {
    if (props.user?.recalculatingStorage) {
      interval.current = setInterval(() => {
        fetchUserInfo();
      }, 5000);
    } else {
      interval.current = null;
    }
    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [fetchUserInfo, props.user?.recalculatingStorage]);

  if (props?.user?.plan) {
    if (props.type === 'storage') {
      let parsedTotal = props.user ? parseSize({ value: props.user.storage }) : null;
      storageEl = parseSize({ value: props.user.storage }) + '/' + parseSize({ value: props.user.plan.storageLimit });

      storageEl = props.user.recalculatingStorage ? (
        <CircularProgress size="1rem" />
      ) : !props.user || (!props.user.storage && props.user.storage !== 0) ? (
        <React.Fragment>
          <Skeleton type="text" width="45px" style={{ display: 'inline-block' }} />/
          <Skeleton type="text" width="45px" style={{ display: 'inline-block' }} />
        </React.Fragment>
      ) : (
        parsedTotal + '/' + parseSize({ value: props.user.plan.storageLimit })
      );
      secondaryEl = <StorageGraph parsedStorage={parsedTotal} storage={props.user.storage} plan={props.user.plan} />;
    } else if (props.type === 'balance') {
      balanceEl = props.user.coins?.toLocaleString(undefined, { style: 'currency', currency: 'EUR' });
    }
  }

  const content = (
    <React.Fragment>
      <ListItemIcon>{props.icon}</ListItemIcon>
      <ListItemText
        primary={
          <div className={classes.primaryBox}>
            <Typography variant="button">{props.title}</Typography>
            {balanceEl ||
              (storageEl && (
                <Typography variant="overline" align="right">
                  {balanceEl ? balanceEl : storageEl}
                </Typography>
              ))}
          </div>
        }
        disableTypography
        primaryTypographyProps={{ variant: 'button' }}
        secondary={secondaryEl}
        secondaryTypographyProps={{ variant: 'overline' }}
      />
    </React.Fragment>
  );

  return (
    <ListItem
      button
      key={'MenuButton_' + props.url}
      component={Link}
      to={props.url}
      disabled={props.disabled}
      url={props.url}
      className={cx(
        classes.root,
        active && classes.active,
        props.className,
        props.canDrop && classes.canDrop,
        props.isOver && props.canDrop && classes.hovering
      )}
      ref={ref}
    >
      {content}
    </ListItem>
  );
});

export default MenuItem;

MenuItem.displayName = 'MenuItem';

MenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  disabled: PropTypes.bool,
};

const StorageGraph = (props) => {
  const storageAmount = (parseInt(props.storage) / parseInt(props.plan.storageLimit)) * 100;
  var storageColor = null;

  if (storageAmount < 75) {
    storageColor = 'success.main';
  } else if (storageAmount < 90) {
    storageColor = 'warning.main';
  } else {
    storageColor = 'error.main';
  }

  return (
    <Box sx={{ borderRadius: 2, overflow: 'hidden', my: 0.5, height: 3, width: '100%', bgcolor: 'common.gray4' }}>
      <Box width={storageAmount + '%'} height="100%" bgcolor={storageColor} />
    </Box>
  );
};
