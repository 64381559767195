import { useState, useCallback, useEffect, useRef, useMemo } from 'react';

import ApiManager from 'ApiManager';
import VersionUtility from 'VersionUtility';
import { useMainContext } from 'ReusableComponents';

import { useLocalStorage } from 'hooks';
import { useCurrentFolder } from './CurrentFolderContext';

const CACHE_SIZE = 50;
const MAP_PAGE_RENDER_SIZE = 12;

const INITIAL_DATA_STATE = {
  data: [],
  loaded: [],
  page: 1,
  hasMore: true,
  nextPageStart: null,
  initial: true,
};

const MAINMODE_NAMES = {
  '/drive/me': 'myDrive',
  '/drive/shared': 'sharedWithMe',
  '/drive/favorites': 'favorites',
  '/drive/trash': 'trash',
};

const fetchData = async ({ type, pageStart, user, mainMode, pathId = null, search: inputSearch = {} }) => {
  // general data fetching function for the Drive
  if (mainMode === '/drive/search') {
    const { type: searchType, ...search } = { ...inputSearch };
    const filteredSearchType = searchType?.filter((st) =>
      type === 'map'
        ? st === 'vector' ||
          st === 'raster' ||
          st === 'pointCloud' ||
          st === 'file' ||
          st === 'bookmark' ||
          st === 'process'
        : type === st
    );

    return (!!searchType && filteredSearchType?.length > 0) || !searchType
      ? ApiManager.get(
          '/v3/path',
          {
            root: ['myDrive', 'sharedWithMe', 'favorites'],
            pageSize: CACHE_SIZE,
            pageStart: pageStart,
            ...(!!searchType && filteredSearchType?.length > 0
              ? { type: searchType }
              : {
                  type:
                    type === 'map'
                      ? ApiManager?.cloud === 'esa'
                        ? ['raster', 'vector', 'pointCloud', 'bookmark', 'process', 'file']
                        : ['raster', 'vector', 'pointCloud', 'bookmark', 'file']
                      : [type],
                }),
            ...search,
          },
          user
        )
          .then((res) => VersionUtility.convertListRoot(res))
          .catch((error) => console.error(error))
      : new Promise((resolve) => resolve({ result: [], nextPageStart: null }));
  } else {
    const url = !!pathId ? `/v3/path/${pathId}/list` : `/v3/account/root/${MAINMODE_NAMES[mainMode]}`;
    return ApiManager.get(
      url,
      {
        type:
          type === 'map'
            ? ApiManager?.cloud === 'esa'
              ? ['raster', 'vector', 'pointCloud', 'bookmark', 'process', 'file']
              : ['raster', 'vector', 'pointCloud', 'bookmark', 'file']
            : [type],
        pageStart,
        pageSize: CACHE_SIZE,
        includeTrashed: false,
      },
      user
    )
      .then((res) => {
        let newRes = VersionUtility.convertListRoot(res);
        if (mainMode !== '/drive/trash') {
          newRes['result'] = newRes['result'].filter((x) => !x.deleted);
        }
        return newRes;
      })
      .catch((error) => {
        console.log('in catch');
        console.error(error);
      });
  }
};

const useDataProvider = () => {
  const [view, setView] = useLocalStorage('driveViewPreference', 'grid');
  const { onOpenSnackbar, addedPath, addPath, driveContentKey } = useMainContext();
  const { currentFolderInfo, mainMode, searchValue, getSearchValue, user } = useCurrentFolder();

  const search = getSearchValue();

  const pathId = currentFolderInfo?.id;
  const isFiles = useMemo(
    () => currentFolderInfo && currentFolderInfo.type !== 'folder' && mainMode !== '/drive/search',
    [currentFolderInfo, mainMode]
  );

  // `data` is `null` when initialized, `[]` when there's nothing
  // `nextPageStart` is `null` when initialized, not `null` when there's more stuff to be retrieved
  const [items, setItems] = useState({
    maps: INITIAL_DATA_STATE,
    folders: INITIAL_DATA_STATE,
    resetNumber: 0,
    page: 1,
    parentId: currentFolderInfo?.id,
    mainMode: currentFolderInfo?.mainMode,
  });

  const folders = items.folders;
  const maps = items.maps;

  const [error, setError] = useState(null);

  const resetRef = useRef(0);

  const reset = useCallback((mainMode, parentId) => {
    setItems((prev) => {
      const newNumber = prev.resetNumber + 1;
      resetRef.current = newNumber;
      return {
        mainMode,
        parentId,
        maps: INITIAL_DATA_STATE,
        folders: INITIAL_DATA_STATE,
        page: 1,
        resetNumber: newNumber,
      };
    });
  }, []);

  const hasMore = useMemo(
    () => (folders.hasMore ? 'folders' : maps.hasMore || maps?.loaded?.length > maps?.data?.length ? 'maps' : false),
    [folders.hasMore, maps.hasMore, maps?.loaded?.length, maps?.data?.length]
  );

  const handleFolderSet = useCallback(({ result, nextPageStart }) => {
    setItems((prevItems) => ({
      ...prevItems,
      page: prevItems.page + 1,
      folders: {
        hasMore: !!nextPageStart,
        data: !!prevItems.folders.data ? [...prevItems.folders.data, ...result] : [...result],
        nextPageStart,
      },
    }));

    setError(null);
  }, []);

  const handleMapsSet = useCallback(({ type, result, nextPageStart }) => {
    if (type) {
      if (type === 'fetch') {
        setItems((prevItems) => {
          const newLoaded = [...prevItems.maps.loaded, ...result];

          return {
            ...prevItems,
            page: prevItems.page + 1,
            maps: {
              ...prevItems.maps,
              hasMore: nextPageStart,
              page: prevItems.maps.page + 1,
              loaded: [...newLoaded],
              data: [...[...newLoaded].splice(0, prevItems.maps.page * MAP_PAGE_RENDER_SIZE)],
              nextPageStart,
            },
          };
        });
      } else if (type === 'loaded') {
        setItems((prevItems) => {
          return {
            ...prevItems,
            page: prevItems.page + 1,
            maps: {
              ...prevItems.maps,
              page: prevItems.maps.page + 1,
              data: [...prevItems.maps.loaded].splice(0, prevItems.maps.page * MAP_PAGE_RENDER_SIZE),
            },
          };
        });
      }

      setError(null);
    }
  }, []);

  //current pathId en userId need to be in a ref
  //currently loading must also be in a ref

  const collectedPages = useRef({});
  const loadMore = useCallback(
    (page) => {
      if (items.resetNumber !== resetRef.current) {
        // console.log('can this actualy even happen?');
        return;
      }
      //to prevent racing grid to list view cause loadMore can be called multiple times in this case
      if (collectedPages.current[resetRef.current] && collectedPages.current[resetRef.current].includes(page)) {
        // console.log('page already being retrieved');
        return;
      }
      collectedPages.current[resetRef.current] = collectedPages.current[resetRef.current]
        ? [...collectedPages.current[resetRef.current], page]
        : [page];

      // called when infinite scroller wants to load more stuff
      // first tries to load all folders and then the maps
      if (!error) {
        if (hasMore === 'folders') {
          fetchData({ type: 'folder', pageStart: folders.nextPageStart, user, mainMode, pathId, search })
            .then((r) => {
              const { result, nextPageStart } = r;
              if (items.resetNumber === resetRef.current) {
                handleFolderSet({ result, nextPageStart });
              }
            })
            .catch((error) => {
              setError(error);
              console.error(error);
            });
        } else if (hasMore === 'maps') {
          if (maps.loaded.length > 0 && maps.loaded.length > maps.data.length) {
            handleMapsSet({ type: 'loaded' });
          } else {
            fetchData({ type: 'map', pageStart: maps.nextPageStart, user, mainMode, pathId, search })
              .then(({ result, nextPageStart }) => {
                if (items.resetNumber === resetRef.current) {
                  handleMapsSet({ type: 'fetch', result, nextPageStart });
                }
              })
              .catch((error) => {
                setError(error);
                console.error(error);
              });
          }
        }
      }
    },
    [
      error,
      folders?.nextPageStart,
      handleFolderSet,
      items,
      handleMapsSet,
      hasMore,
      maps?.data?.length,
      maps?.loaded?.length,
      maps.nextPageStart,
      pathId,
      mainMode,
      search,
      user,
    ]
  );

  const identity = (value) => value;
  const handleOptimisticUpdate = useCallback(
    async ({
      updateMaps = identity,
      updateFolders = identity,
      revertMapsUpdate = identity,
      revertFoldersUpdate = identity,
      apiCall,
      onSuccess = null,
      onError = null,
      isSubscribed = true,
    }) => {
      // function to optimistically update the state and revert it if the call doesn't go through
      // type is either 'folder' or 'map'

      // do the optimistic update
      if (isSubscribed) {
        setItems((prevItems) => {
          return {
            ...prevItems,
            folders: {
              ...prevItems.folders,
              data: updateFolders(prevItems.folders.data ? prevItems.folders.data : []),
            },
            maps: {
              ...prevItems.maps,
              data: updateMaps(prevItems.maps.data ? prevItems.maps.data : []),
              loaded: updateMaps(prevItems.maps.loaded ? prevItems.maps.loaded : []),
            },
          };
        });
      }
      if (!apiCall) {
        return;
      }

      try {
        // validate the change with the backend
        const res = await apiCall;
        onSuccess && onSuccess(res);
      } catch (error) {
        console.log('error: ', error);
        // revert the optimistic update if the call failed
        if (isSubscribed) {
          setItems((prevItems) => {
            return {
              ...prevItems,
              folders: {
                ...prevItems.folders,
                data: revertFoldersUpdate(prevItems.folders.data ? prevItems.folders.data : []),
              },
              maps: {
                ...prevItems.maps,
                data: revertMapsUpdate(prevItems.maps.data ? prevItems.maps.data : []),
                loaded: revertMapsUpdate(prevItems.maps.loaded ? prevItems.maps.loaded : []),
              },
            };
          });
        }
        console.error(error);
        onError && onError(error);
      }
    },
    []
  );

  const handleUpdate = useCallback((path) => {
    setItems((prevItems) => ({
      ...prevItems,
      ...(path?.type === 'folder'
        ? { folders: { ...prevItems.folders, data: prevItems.folders.data.map((f) => (path?.id === f.id ? path : f)) } }
        : { maps: { ...prevItems.maps, data: prevItems.maps.data.map((f) => (path?.id === f.id ? path : f)) } }),
    }));
  }, []);

  const getPath = useCallback(
    (id) => [...items?.maps?.data, ...items?.folders?.data].find((p) => p?.id === id),
    [items?.folders, items?.maps]
  );

  const addNewFolder = useCallback(
    (newFolder) =>
      setItems((prevItems) => ({
        ...prevItems,
        folders: {
          ...prevItems.folders,
          data: [...prevItems.folders?.data, newFolder],
        },
      })),
    []
  );

  const handleAddNewFolder = async (name) => {
    try {
      const body = { name: name, type: 'folder' };
      if (pathId) {
        body.parentId = pathId;
      }

      const res = await ApiManager.post('/v3/path', body, user);
      const folderInfo = VersionUtility.convertPathInfo(await ApiManager.get(`/v3/path/${res.id}`, null, user));

      addNewFolder(folderInfo);
      onOpenSnackbar({
        content: 'Folder successfully added.',
        level: 'success',
      });
    } catch (error) {
      onOpenSnackbar({
        content: error.message ?? 'Folder creation failed.',
        level: 'error',
      });
    }
  };

  const noResult = useMemo(
    () => folders.data?.length === 0 && maps.data?.length === 0 && !folders.hasMore && !maps.hasMore && [folders, maps]
  );

  const fetchPathInfo = useCallback(
    ({ id, parentId, mainMode }) => {
      ApiManager.get(`/v3/path/${id}`, null, user)
        .then((r) => {
          r = VersionUtility.convertPathInfo(r);
          addPath({ id, parentId, mainMode, path: r });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    [addPath, user]
  );

  const added = useRef([]);
  useEffect(() => {
    if (!addedPath.id) {
      return;
    }

    if (!added.current.includes(addedPath.id)) {
      if (!addedPath.path) {
        fetchPathInfo(addedPath);
        return;
      }
      added.current = [addedPath.id, ...added.current];

      if (addedPath.parentId === currentFolderInfo?.id && addedPath.mainMode === mainMode) {
        const result = addedPath.path;
        if (result.type === 'folder') {
          setItems((prevItems) => {
            if (prevItems.folders.data.map((x) => x.id).includes(addedPath.id)) {
              return prevItems;
            }
            return {
              ...prevItems,
              folders: {
                ...prevItems.folders,
                data: [result, ...prevItems.folders.data],
              },
            };
          });
        } else {
          setItems((prevItems) => {
            if (prevItems.maps.loaded.map((x) => x.id).includes(addedPath.id)) {
              return prevItems;
            }
            return {
              ...prevItems,
              maps: {
                ...prevItems.maps,
                data: [result, ...prevItems.maps.data],
                loaded: [result, ...prevItems.maps.loaded],
              },
            };
          });
        }
      }
    }
  }, [addedPath, mainMode, pathId, maps, user, currentFolderInfo?.id, fetchPathInfo]);

  useEffect(() => {
    if (!isFiles) {
      reset(mainMode, currentFolderInfo?.id);
    }
  }, [
    reset,
    mainMode,
    isFiles,
    currentFolderInfo?.id,
    user?.id,
    searchValue,
    currentFolderInfo?.publicAccess.publicAccessLevel,
    currentFolderInfo?.publicAccess.hidden,
    currentFolderInfo?.yourAccess.accessLevel,
    driveContentKey,
  ]);

  return {
    folders,
    getPath,
    handleAddNewFolder: handleAddNewFolder,
    handleOptimisticUpdate,
    handleUpdate,
    hasMore,
    initialLoaded: !maps.initial && !folders.initial,
    isFiles,
    items: items,
    loadMore,
    MAP_PAGE_RENDER_SIZE,
    maps,
    noResult,
    reset,
    setView,
    view,
    error,
  };
};

export default useDataProvider;
