import React from 'react';

import CombinedMenuItem from '../Components/CombinedMenuItem/CombinedMenuItem';

const Subheading = React.forwardRef(({ label }, ref) => {
  return <CombinedMenuItem type="subheading" label={label} ref={ref} />;
});

export default Subheading;
Subheading.displayName = 'Subheading';
