import React, { useState } from 'react';

import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { ConfirmationDialog, useMainContext } from 'ReusableComponents';
import DeleteForeverSweep from './DeleteForeverSweep';
import ApiManager from 'ApiManager';
import { useAuth, useAuthFuncs } from 'hooks';
import { removeFolder } from 'Drive/itemUtils';

const useStyles = makeStyles()((theme) => ({
  addFolderButton: {
    width: '60px',
    height: '60px',
  },
  newFolderDialog: {
    minWidth: '300px',
  },
  spinner: {
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: '20px',
  },
}));

const EmptyTrashButton = ({ reset, handleOptimisticUpdate }) => {
  const { classes: styles } = useStyles();
  const user = useAuth();
  const { onOpenSnackbar } = useMainContext();
  const { fetchUserInfo } = useAuthFuncs();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setDialogOpen(false);
  };

  //recursive function to remove all maps in a folder and then call function on all subfolders

  const handleEmptyTrash = async () => {
    setLoading(true);
    try {
      let trashMaps = null;
      let pageStartMaps = null;
      //list all maps in trash root
      while (pageStartMaps !== null || trashMaps === null) {
        const res = await ApiManager.get(
          '/v3/account/root/trash',
          {
            type:
              ApiManager?.cloud === 'esa'
                ? ['raster', 'vector', 'pointCloud', 'bookmark', 'process', 'file']
                : ['raster', 'vector', 'pointCloud', 'bookmark', 'file'],
            pageStart: pageStartMaps,
          },
          user
        );
        trashMaps = trashMaps ? [...trashMaps, res.result].flat() : res.result;
        pageStartMaps = res.nextPageStart;
      }

      // delete all maps in trash root
      for (let j = 0; j < trashMaps.length; j++) {
        await ApiManager.delete(`/v3/path/${trashMaps[j].id}`, null, user);
      }

      let pageStartFolders = null;
      let trashFolders = null;

      // list all folders in trash root
      while (pageStartFolders !== null || trashFolders === null) {
        const res = await ApiManager.get(
          '/v3/account/root/trash',
          {
            type: ['folder'],
            pageStart: pageStartFolders,
          },
          user
        );
        trashFolders = trashFolders ? [...trashFolders, res.result] : res.result;
        pageStartFolders = res.nextPageStart;
      }
      console.log('trash folders');
      console.log(trashFolders);

      for (let i = 0; i < trashFolders.length; i++) {
        await removeFolder(trashFolders[i].id, user);
      }

      onOpenSnackbar({
        level: 'success',
        content: 'The trash has been successfully emptied',
      });
      fetchUserInfo();

      handleOptimisticUpdate({
        updateMaps: (items) => {
          return [];
        },
        updateFiles: (items) => {
          return [];
        },
        updateFolders: (items) => {
          return [];
        },
        apiCall: null,
        isSubscribed: true,
      });
      handleClose();
    } catch (error) {
      onOpenSnackbar({
        level: 'error',
        content: error.message ?? 'An error occurred while emptying the trash.',
      });
    }

    setLoading(false);
  };

  return (
    <>
      <Tooltip title={loading ? 'Emptying trash' : 'Empty trash'} arrow={true}>
        {loading ? (
          <div className={styles.spinner}>
            <CircularProgress size={30} />
          </div>
        ) : (
          <IconButton
            color="primary"
            className={styles.addFolderButton}
            onClick={() => setDialogOpen(true)}
            size="large"
          >
            <DeleteForeverSweep color="primary" />
          </IconButton>
        )}
      </Tooltip>
      <ConfirmationDialog
        className={styles.newFolderDialog}
        open={dialogOpen}
        handleClose={handleClose}
        onClick={handleEmptyTrash}
        legend="This will delete everything in your trash from our servers. Once done, this action can't be reversed. Please be certain."
        title="Empty trash permanently"
        agreeText="Delete"
        loading={loading}
      />
    </>
  );
};

export default EmptyTrashButton;
