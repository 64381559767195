import React from 'react';

import { makeStyles } from 'tss-react/mui';

const useDefaultStyles = makeStyles({ name: 'AccordionContainer' })((theme) => ({
  root: {
    flexGrow: 1,
    flexShrink: 1,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignContent: 'center',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    height: '100%',
    overflow: 'hidden',
  },
}));

const AccordionContainer = ({ children }) => {
  const { classes: styles } = useDefaultStyles();

  const filtered = children?.filter((child) => !!child);

  return (
    <div className={styles.root}>
      {React.Children.map(filtered, (child) => React.cloneElement(child, { accordionAmount: filtered?.length }))}
    </div>
  );
};

export default AccordionContainer;
