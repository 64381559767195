const FILES_COLORS = {
  DOC: '#185ABD',
  DOCX: '#185ABD',
  ODT: '#185ABD',
  PDF: '#FF2116',
  PNG: '#9747FF',
  JPEG: '#9747FF',
  TIF: '#9747FF',
  TIFF: '#9747FF',
  PPT: '#D24625',
  PPTX: '#D24625',
  ODP: '#D24625',
  HTML: '#E33608',
  HTM: '#E33608',
  JSON: '#333333',
  TXT: '#333333',
  XLS: '#107C41',
  XLSX: '#107C41',
  ODS: '#107C41',
  DBF: '#2813AD',
};

const DEFAULT_COLOR = '#0C208A';
const NO_EXTENSION_COLOR = '#8a0c3e';

export const getColor = (extension = '') =>
  !extension ? NO_EXTENSION_COLOR : FILES_COLORS?.[extension?.split('.')?.[1].toUpperCase()] ?? DEFAULT_COLOR;

export const parseExtension = (input = '') => {
  if (!input.includes('.')) {
    return {
      fileName: input,
      fileExtension: '',
    };
  }
  const split = input?.split('.');

  const fileName = split.slice(0, -1).join('.');
  const fileExtension = split?.length > 1 ? '.' + split[split?.length - 1] : undefined;

  return {
    fileName,
    fileExtension,
  };
};
