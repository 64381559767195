import React, { Suspense } from 'react';
import { Box, CircularProgress } from '@mui/material';

const SuspenseContainer = ({
  fallbackOptions = {},
  fallback = (
    <Box display="flex" alignItems="center" justifyContent="center" className="suspenseContainer" {...fallbackOptions}>
      <CircularProgress />
    </Box>
  ),
  displayName = 'default',
  children,
}) => {
  SuspenseContainer.displayName = `SuspenseContainer_${displayName}`;

  return <Suspense fallback={fallback}>{children}</Suspense>;
};

SuspenseContainer.displayName = `SuspenseContainer_fallback`;

export default SuspenseContainer;
