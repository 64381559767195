import React, { useState } from 'react';

import { CircularProgress, Fab, ListItem, ListItemIcon, ListItemText, SvgIcon, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { GetApp, Error } from '@mui/icons-material';
import PropTypes from 'prop-types';

import ProjectTypeIcon from 'ReusableComponents/ProjectTypeIcon';

const DownloadDoneIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M5 18h14v2H5v-2zm4.6-2.7L5 10.7l2-1.9 2.6 2.6L17 4l2 2-9.4 9.3z" />
    </SvgIcon>
  );
};

const useStyles = makeStyles({ name: 'SectionMenuItemDownload' })((theme) => ({
  container: {},
  projectTypeIcon: {
    height: '40px',
    width: '40px',
  },
  textContainer: {},
  title: {},
  legend: {},
  iconButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(1),
  },
  loadingText: {
    color: 'inherit',
  },
  downloadDoneRoot: {
    height: '40px',
    width: '40px',
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.light,
    },
  },
  downloadIconRoot: {
    height: '40px',
    width: '40px',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

const SectionMenuItemDownload = ({ projectType, title, legend, status, downloadId }) => {
  const { classes } = useStyles({ type: 'download' });
  return (
    <ListItem className={classes.container}>
      <ListItemIcon>
        <ProjectTypeIcon type={projectType === 'file' ? 'folder' : projectType} className={classes.projectTypeIcon} />
      </ListItemIcon>
      <ListItemText primary={title} secondary={legend} primaryTypographyProps={{ noWrap: true }} />
      <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
        <ItemIcon status={status} downloadId={downloadId} projectType={projectType} />
      </ListItemIcon>
    </ListItem>
  );
};

SectionMenuItemDownload.propTypes = {
  projectType: PropTypes.oneOf(['map', 'shape', 'file']).isRequired,
  title: PropTypes.string.isRequired,
  legend: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  downloadId: PropTypes.string.isRequired,
};

const ItemIcon = ({ status, downloadId, projectType }) => {
  const { classes, theme } = useStyles({});

  const [newStatus, setNewStatus] = useState(null);

  if (status === 'created') {
    return (
      <div className={classes.iconButtonContainer} style={{ color: theme.palette.info.light }}>
        <CircularProgress color="inherit" size={24} />
        <Typography className={classes.loadingText} variant="overline">
          Pending
        </Typography>
      </div>
    );
  } else if (status === 'processing' || status === 'downloading') {
    return (
      <div className={classes.iconButtonContainer} style={{ color: theme.palette.primary.main }}>
        <CircularProgress size={24} />
        <Typography className={classes.loadingText} variant="overline">
          {status}
        </Typography>
      </div>
    );
  } else if (status === 'downloaded' || newStatus !== null) {
    return (
      <div className={classes.iconButtonContainer}>
        <Fab
          component="button"
          classes={{ root: classes.downloadDoneRoot }}
          type="submit"
          onClick={() => {
            console.log('DOWNLOADING');
            console.log('projecttype', projectType);
            window.open(
              `${import.meta.env.VITE_APP_API_URL}/v3/path/${
                projectType === 'file'
                  ? `order`
                  : projectType === 'map'
                  ? 'raster/timestamp/order'
                  : projectType === 'pointCloud'
                  ? 'pointCloud/timestamp/order'
                  : 'vector/timestamp/order'
              }/${downloadId}/data`
            );
          }}
        >
          <DownloadDoneIcon style={{ color: theme.palette.common.white }} />
        </Fab>
      </div>
    );
  } else if (status === 'completed') {
    return (
      <div className={classes.iconButtonContainer}>
        <Fab
          type="submit"
          classes={{ root: classes.downloadIconRoot }}
          style={{ color: theme.palette.primary.main }}
          onClick={() => {
            window.open(
              `${import.meta.env.VITE_APP_API_URL}/v3/path/${
                projectType === 'file'
                  ? 'order'
                  : projectType === 'map'
                  ? 'raster/timestamp/order'
                  : projectType === 'pointCloud'
                  ? 'pointCloud/timestamp/order'
                  : 'vector/timestamp/order'
              }/${downloadId}/data`
            );
            setNewStatus('downloaded');
          }}
        >
          <GetApp style={{ color: theme.palette.common.white }} />
        </Fab>
      </div>
    );
  } else {
    // error
    return (
      <div className={classes.iconButtonContainer}>
        <Error color="inherit" style={{ color: theme.palette.error.main }} />
        <Typography className={classes.loadingText} variant="overline">
          error
        </Typography>
      </div>
    );
  }
};

ItemIcon.propTypes = {
  status: PropTypes.string.isRequired,
  downloadId: PropTypes.string,
  projectType: PropTypes.string.isRequired,
};

export default SectionMenuItemDownload;
