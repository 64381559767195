import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { Typography, Button, CircularProgress } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { useMinimalAuth } from '../AuthContext';
import FullScreenLayout from '../FullScreenLayout';
import { useQueryParams } from '../../MapManagement/hooks';
import ApiManager from '../../ApiManager';
import ErrorContent from './ErrorContent';
import SuccessRedirect from './SuccessRedirect';
import VersionUtility from 'VersionUtility';

const useStyles = makeStyles()((theme) => ({
  spinner: {
    marginTop: '50px',
    margin: 'auto',
  },
  title: {},
  subTitle: {},
  authorizeButton: {
    marginTop: '20px',
  },
  buttonLegend: {
    marginTop: '4px',
    color: theme.palette.text.secondary,
  },
  cancelButton: {
    width: 'none',
    marginTop: '20px',
  },
}));

const OAuthConsent = () => {
  const user = useMinimalAuth();
  const params = useQueryParams();
  const history = useHistory();
  const { classes } = useStyles();

  const [clientUser, setClientUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  // initial checks to know what to do
  // If redirect from elsewhere (no history state) and missing params, display so
  // If not, redirect to right page for (re)auth
  useEffect(() => {
    const fetchProfile = async (userId) => {
      try {
        setLoading(true);
        let profile = await ApiManager.get(`/v3/user/${userId}`, null, user);
        profile = VersionUtility.convertAccount(profile);

        setClientUser(profile);
        setLoading(false);
      } catch (error) {
        setError('error-client-profile');
        setLoading(false);
      }
    };

    if (params.hasOwnProperty('error')) {
      setError('backend-error');
    } else if (
      history.location.state === undefined &&
      (!params.hasOwnProperty('uuid') || !params.hasOwnProperty('scope') || !params.hasOwnProperty('userId'))
    ) {
      setError('wrong-parameters');
    } else if (!user || !history.location.state?.password) {
      history.push('/login', { from: `/oauth/consent${history.location.search}` });
    } else {
      // The user was authenticated so we can proceed
      fetchProfile(params.userId);
    }
  }, [history, params, user]);

  const handleSubmit = async (requestStatus) => {
    try {
      const res = await ApiManager.post(
        '/v3/oauth/consent',
        { uuid: params.uuid, requestStatus, password: history.location.state.password },
        user
      );
      console.log(res);
      if (!!res) {
        setSuccess(requestStatus);
        setInterval(() => {
          window.location.replace(res.redirectUri);
        }, 5 * 1000);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  if (loading) {
    return (
      <ConsentLayout>
        <CircularProgress className={classes.spinner} />
      </ConsentLayout>
    );
  }

  if (error !== null) {
    return (
      <ConsentLayout>
        <ErrorContent error={error} />
      </ConsentLayout>
    );
  }
  if (success) {
    return (
      <ConsentLayout>
        <SuccessRedirect requestStatus={success} />
      </ConsentLayout>
    );
  }

  return (
    <ConsentLayout>
      <Typography className={classes.title} variant="h3" paragraph>
        Give {clientUser?.username} access to your Drive
      </Typography>

      <Typography className={classes.subTitle} variant="subtitle1" gutterBottom>
        This will give this third-party app full access to your Drive, both in read and write mode. Please be certain
        you trust this third-party with your data before giving them access.
      </Typography>

      <Button
        variant="contained"
        color="primary"
        className={classes.authorizeButton}
        onClick={() => handleSubmit('accepted')}
      >
        Authorize {clientUser?.username}
      </Button>
      <Typography className={classes.buttonLegend}>You will be redirected to the third party website</Typography>
      <Button
        variant="outlined"
        color="primary"
        className={classes.cancelButton}
        onClick={() => handleSubmit('rejected')}
      >
        Cancel
      </Button>
    </ConsentLayout>
  );
};

const ConsentLayout = ({ children }) => {
  return (
    <FullScreenLayout>
      <Helmet>
        <meta name="robots" content="noindex" data-react-helmet="true" />
        <title>Authorize app - {ApiManager?.displayName ?? 'Ellipsis Drive'}</title>
      </Helmet>
      {children}
    </FullScreenLayout>
  );
};

export default OAuthConsent;
