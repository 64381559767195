import React from 'react';

import StorageSize from '../Components/StorageSize/StorageSize';

import CombinedMenuItem from '../Components/CombinedMenuItem/CombinedMenuItem';

import { Storage as StorageIcon } from '@mui/icons-material';
import ApiManager from 'ApiManager';

const Storage = ({ setDialog, onClose }) => {
  const openDialog = () => {
    setDialog({
      title: `Total Size`,
      content: <StorageSize />,
    });

    onClose();
  };

  return (
    <CombinedMenuItem
      icon={<StorageIcon />}
      label="Storage Size"
      openDialog
      accessLevel={ApiManager.newAccessLevel.admin}
      action={openDialog}
    />
  );
};

export default Storage;
