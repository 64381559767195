import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles({ name: 'LoginPage' })((theme, _, classes) => ({
  title: {},
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  textFieldContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: theme.spacing(1.5, 0),
  },
  textFieldLabel: {
    marginBottom: theme.spacing(0.5),
  },
  textField: {
    width: '100%',
  },
  loginButton: {
    width: '100%',
  },
  forgotPasswordContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px 0 15px 0',
    marginBottom: '6px',
  },
  link: {},
  signUpContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '10px',
  },
  signUpText: {},
  errorContainer: {
    width: '100%',
    borderRadius: '6px',
    border: `1px solid ${theme.palette.error.dark}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
    marginTop: '10px ',
    boxSizing: 'border-box',
  },
  warningContainer: {
    width: '100%',
    borderRadius: '6px',
    border: `1px solid green`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
    marginTop: '10px ',
    boxSizing: 'border-box',
  },
  errorText: {
    color: theme.palette.error.dark,
    textAlign: 'left',
  },
  warningText: {
    color: '#1976d2',
    textAlign: 'left',
  },
  // googleForm: { color: '#dadce0' },
  googleButton: {
    textTransform: 'none',
    justifyContent: 'flex-start',
    textAlign: 'center',
    borderColor: theme.palette.action.disabled,
    '&>.MuiButton-startIcon': { margin: 0 },
    [`&>.${classes.label}`]: {
      display: 'flex',
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'left',
      color: theme.palette.text.primary,
    },
  },
  label: {},
}));

export default useStyles;
