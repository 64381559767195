import React from 'react';

import { withRouter } from 'react-router-dom';

class NavigationHelper extends React.Component {
  componentDidUpdate = (prevProps) => {
    if (this.props.location) {
      let hideNav = new URLSearchParams(prevProps.location.search).get('hideNavbar');
      let newHideNav = new URLSearchParams(this.props.location.search).get('hideNavbar');

      if (this.props.location.pathname !== prevProps.location.pathname) {
        this.props.onOpenSidebar(false);

        this.props.redirect(null);
      }

      if (hideNav !== newHideNav) {
        this.props.checkNavbar({ value: newHideNav === 'true' });
      }
    }
  };

  render() {
    return this.props.children;
  }
}

export default withRouter(NavigationHelper);
