import React, { useCallback, useState } from 'react';

import { Button } from '@mui/material';

import NewJobDialog from './NewJobDialog';

const NewJobButton = (props = {}) => {
  const { pathId, cb, slotProps = {} } = { ...props };
  const { button: buttonProps = {} } = { ...slotProps };

  const [open, setOpen] = useState(false);

  const openDialog = useCallback(() => setOpen(true), []);
  const closeDialog = useCallback(() => setOpen(false), []);

  return (
    <>
      <Button {...buttonProps} onClick={openDialog}>
        Execute
      </Button>

      <NewJobDialog pathId={pathId} open={open} closeDialog={closeDialog} cb={cb} />
    </>
  );
};

export default NewJobButton;
