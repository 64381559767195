import React from 'react';

import PropTypes from 'prop-types';

import { makeStyles } from 'tss-react/mui';
import { Box, Tooltip } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

import { useMinimalAuth } from '../../Account/AuthContext';
import ApiManager from '../../ApiManager';

const fontSize = 14;
const GAP = 0.75;

const useStyles = makeStyles({ name: 'AccessLevelComponent' })((theme, _params, classes) => ({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: theme.spacing(GAP),
    fontSize: fontSize,
    lineHeight: fontSize + 'px',
    transition: theme.transitions.create('opacity'),
    [`&:hover .${classes.collapse}`]: {
      maxWidth: '100vw',
      marginLeft: 0,
    },
  },
  label: {
    textAlign: 'left',
  },
  overflowWrap: { flexWrap: 'wrap' },
  button: {
    flexGrow: 1,
    [`& .${classes.label}`]: { flexGrow: 1 },
    '& .MuiSvgIcon-root': { marginRight: theme.spacing(1) },
  },
  collapse: {
    maxWidth: 0,
    overflow: 'hidden',
    marginLeft: theme.spacing(-GAP),
    transition: theme.transitions.create(['margin-left', 'max-width']),
  },
}));

const AccessLevelComponent = ({
  map,
  tooltip,
  accessLevel,
  skeleton,
  component = 'div',
  isOwner,
  isButton,
  extraText,
  disabled,
  className: userClassName = '',
  style: userStyles = {},
  noColor,
  overflowWrap = false,
  labelProps = {},
  collapse,
}) => {
  const { classes, cx } = useStyles({ isButton });
  const user = useMinimalAuth();
  accessLevel = accessLevel || accessLevel === 0 ? accessLevel : map?.yourAccess?.accessLevel;

  const owner =
    isOwner || (map && user && accessLevel === ApiManager.newAccessLevelLong.find((x) => x.key === 'owner'))
      ? isOwner || (map?.user ? map.user.id === user.id : map.owner.id === user.id)
      : null;

  const accessLevelObj = skeleton
    ? {}
    : ApiManager.newAccessLevelLong.find((x, i) => {
        if (owner) {
          return x.key === 'owner';
        } else if (typeof accessLevel === 'string') {
          return x.key === accessLevel;
        } else if (typeof accessLevel === 'number') {
          if (x.accessLevel === accessLevel) {
            return true;
          }
          return ApiManager.newAccessLevelLong[i - 1]?.accessLevel < accessLevel && x.accessLevel >= accessLevel;
        } else if (typeof accessLevel !== 'undefined') {
          console.log(typeof accessLevel, accessLevel);
        }

        return false;
      });

  const styling = { color: !noColor && accessLevelObj?.color, ...userStyles };
  if (disabled === true) {
    styling.opacity = 0.5;
  }

  const inner = (
    <Box
      component={component}
      className={cx(
        'AccessLevelComponent',
        classes.root,
        overflowWrap && classes.overflowWrap,
        isButton && classes.button,
        userClassName?.length > 0 && userClassName
      )}
      style={styling}
    >
      {accessLevelObj?.icon ? accessLevelObj.icon : <Skeleton variant="circular" width={fontSize} height={fontSize} />}
      {accessLevelObj?.label ? (
        <Box className={cx(classes.label, collapse && classes.collapse)} component="span" {...labelProps}>
          {accessLevelObj.label}
        </Box>
      ) : (
        <Skeleton variant="text" width={52} height={fontSize} />
      )}
      {extraText}
    </Box>
  );

  return accessLevelObj?.tooltip && tooltip ? (
    <Tooltip title={accessLevelObj.tooltip} arrow>
      {inner}
    </Tooltip>
  ) : (
    inner
  );
};

const testSame = (prevProps, nextProps) => {
  if (
    prevProps?.map?.accessLevel !== nextProps?.map?.accessLevel ||
    prevProps?.map?.id !== nextProps?.map?.id ||
    prevProps?.accessLevel !== nextProps?.accessLevel ||
    prevProps?.disabled !== nextProps?.disabled ||
    prevProps?.className !== nextProps?.className ||
    prevProps?.isOwner !== nextProps?.isOwner
  ) {
    return false;
  }
  return true;
};

export default React.memo(AccessLevelComponent, testSame);

AccessLevelComponent.propTypes = {
  map: PropTypes.shape({
    id: PropTypes.string.isRequired,
    accessLevel: PropTypes.oneOf([
      ...Object.keys(ApiManager.newAccessLevel),
      ...Object.values(ApiManager.newAccessLevel),
    ]),
    owner: PropTypes.shape({
      id: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
    }),
    user: PropTypes.shape({
      id: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
    }),
  }),
  accessLevel: PropTypes.number,
  component: PropTypes.string,
  isButton: PropTypes.bool,
  tooltip: PropTypes.bool,
  skeleton: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  noColor: PropTypes.bool,
};
