import React from 'react';

import {
  Radio,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListSubheader,
  CircularProgress,
  Tooltip,
  ListItemText,
  Typography,
} from '@mui/material';

import { isValidMap, LinkStyleButton } from 'ReusableComponents';
import { PANE_TYPES, usePaneContentFuncs } from '../../../PaneContentContext';
import { useElevationModels } from 'Viewer/ViewerContexts/ElevationModelsContext';
import { makeStyles } from 'tss-react/mui';

import { tileLayers } from 'Viewer/TileLayers';
import { useCurrentFolder } from 'Drive/CurrentFolderContext';
import { useLinkStyleButtonStyles } from 'ReusableComponents/LinkStyleButton';
import MainPaneHeader from '../MainPaneHeader/MainPaneHeader';
import { Add } from '@mui/icons-material';
import { useMainViewer } from 'Viewer/ViewerContexts/MainViewerContext';
const useStyles = makeStyles({ name: 'ElevationModels' })((theme) => ({
  listItem: { paddingLeft: 0 },
  header: { lineHeight: 2, padding: theme.spacing(0, 1) },
  primary: {
    textTransform: 'initial',
    lineHeight: '100%',
    overflow: 'clip',
    overflowClipMargin: 4,
    minWidth: 150,
  },
}));

export const ElevationModels = ({ canElevation }) => {
  const { models, selectModel, selectedModel, deselectModel } = useElevationModels();
  const { classes: styles, cx } = useStyles();
  const { classes: linkButtonStyles } = useLinkStyleButtonStyles();
  const { onToLocationClick, currentFolderInfo: selectedMap } = useCurrentFolder();
  const { viewMode, isPresentationMode, viewerConfig } = useMainViewer();

  const { setContent } = usePaneContentFuncs();

  return (
    <div>
      {isPresentationMode && <MainPaneHeader label="Elevation Models" sx={{ mx: -2 }} />}
      <List dense>
        {!isPresentationMode && (
          <ListSubheader disableSticky className={styles.header}>
            <Typography variant="subtitle2">Elevation Models</Typography>
          </ListSubheader>
        )}

        <div className="map-layers--container">
          {models.map((model) => {
            let disabled = !isValidMap(model).available || viewMode === '3D';
            return (
              <ListItem className={cx(linkButtonStyles.listItem, styles.listItem)} disabled={disabled} key={model.id}>
                <ListItemIcon>
                  <Radio
                    checked={model.id === selectedModel?.id}
                    disabled={disabled}
                    onClick={() => {
                      if (model.id === selectedModel?.id) {
                        deselectModel(model);
                      } else {
                        selectModel(model);
                      }
                    }}
                    color="primary"
                  />
                </ListItemIcon>
                <ListItemText
                  primary={model.name}
                  primaryTypographyProps={{ noWrap: true, className: styles.primary }}
                  secondary={
                    viewMode === '3D'
                      ? 'Altitude models can only be loaded in 2D mode'
                      : disabled
                      ? isValidMap(model).reason
                      : tileLayers(selectedMap).find((l) => l.id === model.id)?.attribution
                  }
                />
                {!isPresentationMode && (
                  <ListItemSecondaryAction className={linkButtonStyles.secondaryAction}>
                    {disabled &&
                    ['Reindexing layer', 'Relocating layer', 'Activating files'].includes(isValidMap(model).reason) ? (
                      <CircularProgress size={20} />
                    ) : (
                      !viewerConfig?.hideLayerMore &&
                      viewMode !== '3D' && (
                        <Tooltip title={disabled ? 'Fix' : 'Tune'} placement="right">
                          <span>
                            <LinkStyleButton
                              label={disabled ? 'Fix' : 'Tune'}
                              data-test-id={`elevationModelDetailsButton-${model.name}`}
                              action={() => {
                                if (disabled) {
                                  onToLocationClick(model);
                                } else {
                                  setContent({ type: PANE_TYPES.model, props: { layerId: model.id } });
                                }
                              }}
                            />
                          </span>
                        </Tooltip>
                      )
                    )}
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            );
          })}
          {(models.length === 0 || canElevation) && (
            <ListItem disabled={!canElevation}>
              <ListItemIcon>
                <Add />
              </ListItemIcon>
              <ListItemText primary={'Drag a layer here to use it as elevation'} />
            </ListItem>
          )}
        </div>
      </List>
    </div>
  );
};
