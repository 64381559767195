/* import React from 'react';
import { Typography, Paper } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme, _params, classes) => ({
  section: {
    marginTop: '0.5rem',
    marginBottom: '1rem',
  },
  interpText: {
    color: theme.palette.text.secondary,
    fontSize: '1.1rem',
    marginBottom: '10px',
  },
  cardContainer: {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'start',
    flexWrap: 'wrap',
    gap: '20px',
  },
  card: {
    width: '300px',
    cursor: 'pointer',
    padding: '16px',
    boxSizing: 'border-box',
    borderWidth: '1px',
    [`&.${classes.selected}`]: {
      borderColor: theme.palette.primary.main,
      backgroundColor: `${theme.palette.primary.main}09`,
    },
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}04`,
    },
  },
  cardTitle: {
    fontSize: '1.3rem',
    lineHeight: '1.334',
  },
  cardSubtitle: {
    textAlign: 'left',
  },

  selected: {},
})); */

const DownSample = ({ downsample, setDownsample }) => {
  // const { classes, cx } = useStyles();
  return null;

  /* return (
    <section className={classes.section}>
      <Typography className={classes.interpText}>{`Processing strategy`}</Typography>
      <div className={classes.cardContainer}>
        <Paper
          variant="outlined"
          className={cx(classes.card, downsample && classes.selected)}
          onClick={() => setDownsample(true)}
        >
          <Typography className={classes.cardTitle}>Simplify</Typography>
          <Typography color="textSecondary" className={classes.cardSubtitle}>
            Recommended if you need your vectors at different levels of detail.
          </Typography>
        </Paper>
        <Paper
          variant="outlined"
          className={cx(classes.card, !downsample && classes.selected)}
          onClick={() => setDownsample(false)}
        >
          <Typography className={classes.cardTitle}> full</Typography>
          <Typography color="textSecondary" className={classes.cardSubtitle}>
            Recommended if you do not need your vectors at different levels of detail.
          </Typography>
        </Paper>
      </div>
    </section>
  ); */
};

export default DownSample;
