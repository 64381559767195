import React from 'react';
import PropTypes from 'prop-types';
import { LinearProgress, Typography } from '@mui/material';

const CustomLinearProgress = ({
  showLabel = false,
  variant = 'indeterminate',
  className = '',
  style = {},
  ...props
}) => {
  return (
    <div style={{ width: '100%', ...style }} className={className}>
      <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <div style={{ width: '100%', marginRight: '4px' }}>
          <LinearProgress variant={variant} {...props} />
        </div>
        {!!showLabel && (
          <div style={{ minWidth: '35px', marginleft: '10px' }}>
            <Typography variant="body2" color="textSecondary">{`${Math.round(props.value)}%`}</Typography>
          </div>
        )}
      </div>
    </div>
  );
};

CustomLinearProgress.propTypes = {
  showLabel: PropTypes.bool,
  variant: PropTypes.oneOf(['determinate', 'indeterminate']),
  value: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default CustomLinearProgress;
