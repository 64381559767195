import React, { Component, useContext, useEffect } from 'react';

import { makeStyles, withStyles } from 'tss-react/mui';
import { Typography, Button } from '@mui/material';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { MainContext } from './ReusableComponents/MainContext';
import { COOKIE_TYPES, useConsentValue } from './ConsentContext';
import SentryComponent from './SentryComponent';

const GlobalErrorBoundarySentry = ({ children }) => {
  const consent = useConsentValue();

  return consent?.[COOKIE_TYPES?.ANALYTICAL] ? (
    <SentryComponent>{children}</SentryComponent>
  ) : (
    <CookielessErrorBoundary>{children}</CookielessErrorBoundary>
  );
};

GlobalErrorBoundarySentry.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GlobalErrorBoundarySentry;

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    width: '100vw',
    height: '100vh',
    gap: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
    },
  },
  title: {},
  subtitle1: {},
  subtitle2: {},
  button: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(2),
  },
  link: {
    color: theme.palette.primary.light,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

function setWithExpiry(key, value, ttl) {
  const item = {
    value: value,
    expiry: new Date().getTime() + ttl,
  };

  try {
    localStorage.setItem(key, JSON.stringify(item));
  } catch (e) {
    console.warn(e);
  }
}

function getWithExpiry(key) {
  try {
    const itemString = window.localStorage.getItem(key);
    if (!itemString) return null;

    const item = JSON.parse(itemString);
    const isExpired = new Date().getTime() > item.expiry;

    if (isExpired) {
      localStorage.removeItem(key);
      return null;
    }

    return item.value;
  } catch (e) {
    console.warn(e);

    return null;
  }
}

const ColorButton = withStyles(Button, (theme) => ({
  root: {
    color: theme.palette.getContrastText(theme.palette.common.white),
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.grey[50],
    },
  },
}));

export const ErrorComponent = ({ error, reset }) => {
  const { classes } = useStyles();
  const history = useHistory();

  const { setOpenContact } = useContext(MainContext);

  // based on https://mitchgavan.com/code-splitting-react-safely/
  useEffect(() => {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (error?.message && chunkFailedMessage.test(error.message)) {
      if (!getWithExpiry('chunk_failed')) {
        setWithExpiry('chunk_failed', 'true', 10000);
        window.location.reload();
      }
    }
  }, [error]);

  return (
    <>
      <div
        className={classes.container}
        style={{
          backgroundImage: `linear-gradient(180deg, rgba(13, 46, 76, 0.6), rgba(13, 46, 76, 0.6)), url(${process.env.PUBLIC_URL}/images/error/spacebg.jpg)`,
        }}
      >
        <Typography className={classes.title} variant="h1">
          Oops, something went wrong...
        </Typography>
        <Typography className={classes.subtitle1} variant="subtitle1">
          The issue may be temporary. Please try again.{' '}
        </Typography>
        <Typography className={classes.subtitle2}>
          If the problem persists, please report or get in touch with our{' '}
          <span className={classes.link} onClick={() => setOpenContact({ title: 'Support form' })}>
            support team
          </span>
          . We&apos;re here to help.
        </Typography>
        <ColorButton
          variant="contained"
          className={classes.button}
          onClick={() => {
            history.push('/');
            reset();
          }}
        >
          Go to Drive
        </ColorButton>
      </div>
    </>
  );
};

ErrorComponent.propTypes = {
  reset: PropTypes.func.isRequired,
};

class CookielessErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      error: { message: '', stack: '' },
      info: { componentStack: '' },
    };
  }

  static getDerivedStateFromError = (error) => {
    return { hasError: true };
  };

  componentDidCatch = (error, info) => {
    console.log('error: ', error);
    this.setState({ error, info });
  };

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    return hasError ? (
      <ErrorComponent
        reset={() =>
          this.setState({
            hasError: false,
            error: { message: '', stack: '' },
            info: { componentStack: '' },
          })
        }
      />
    ) : (
      children
    );
  }
}
