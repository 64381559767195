import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { CLOUDS } from 'ApiUrls';
import {
  Typography,
  Dialog,
  DialogContent,
  Grid,
  Button,
  CardContent,
  CardActions,
  CardMedia,
  Card,
  DialogActions,
} from '@mui/material';
import { School } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';

import DialogTitle from 'ReusableComponents/DialogTitle/DialogTitle';

import { useMainContext } from 'ReusableComponents/MainContext';
import { useMinimalAuth } from 'hooks';

import AppUtility from 'AppUtility';
import ApiManager from 'ApiManager';
import { LinkStyleButton } from 'ReusableComponents/LinkStyleButton';
import { INITIAL_COOKIE_VALUE, useConsentSetter } from 'ConsentContext';

const useStyles = makeStyles({ name: 'TourDialog' })((theme) => ({
  root: {},
  content: { paddingBottom: theme.spacing(0.5) },
  avatar: {
    height: theme.spacing(12),
    width: theme.spacing(12),
    '& .MuiSvgIcon-root': {
      height: theme.spacing(8),
      width: theme.spacing(8),
    },
  },
  coloredAvatar: { backgroundColor: theme.palette.success.main },
  customTitle: { paddingBottom: 0, paddingLeft: theme.spacing(3) },
  subheader: { marginBottom: theme.spacing(3) },
}));

const TourDialog = ({ setTourOpen, veryFirstTime = false, onClose = () => {} }) => {
  const user = useMinimalAuth();
  const { classes: styles } = useStyles();
  const { setOpenContact } = useMainContext();

  const setConsent = useConsentSetter();

  const actions = [
    {
      media: {
        type: 'img',
        alt: 'Ellipsis',
        src:
          ApiManager.cloud === CLOUDS.ellipsis ? '/ellipsis-logo.svg' : ApiManager.apiUrl + '/v3/backoffice/theme/logo',
        background: 'darkPrimary',
      },
      title: 'Explore the Drive',
      body: !!veryFirstTime
        ? 'Start the short page tour!'
        : 'Start the short page tour or take a look around yourself.',
      actions: [
        {
          title: 'Take the Tour',
          onClick: () => {
            let cb = () => {
              setTourOpen('click');
            };
            onClose(null, cb);
          },
        },
      ],
    },
    {
      media: { type: 'icon', src: <School />, background: 'lightPrimary' },
      title: 'Explore user journeys',
      body: 'Dive into relevant user journeys on the Help Center.',
      actions: [
        {
          title: 'Go to Help Center',
          component: 'a',
          href: 'https://docs.ellipsis-drive.com/getting-started/user-journeys',
          target: '_blank',
          onClick: () => {
            onClose();
          },
        },
      ],
    },
    {
      media: { type: 'img', alt: 'Ellipsis Drive Experts', src: '/images/apptour/DriveExperts.jpg' },
      title: 'Talk to our Drive Experts',
      body: 'Our team is here to help you on the way!',
      actions: [
        {
          title: 'Contact Support',
          onClick: () => {
            setOpenContact({
              title: (
                <>
                  Contact{' '}
                  <Typography component="span" variant="inherit" color="textPrimary">
                    Support
                  </Typography>
                </>
              ),
              subject: 'Contact Support',
              messageTitle: 'Ask your question',
              type: 'calendly',
            });
            onClose();
          },
        },
      ],
    },
  ];

  return (
    <Dialog open={true} maxWidth={'md'} style={{ paddingTop: 64 }}>
      <DialogTitle
        title={
          <>
            Welcome {!!user ? '' : 'to '}
            <Typography component="span" variant="inherit" color="textPrimary">
              {!!user ? user?.username : ' Ellipsis Drive'}
            </Typography>
          </>
        }
        titleProps={{
          component: 'h1',
          variant: 'h2',
          color: 'primary',
          noWrap: false,
          style: { textTransform: 'capitalize' },
        }}
        close={onClose}
        className={styles.customTitle}
      />
      <DialogContent className={styles.content}>
        <Typography component="h2" variant="h3" color="textPrimary" className={styles.subheader}>
          {veryFirstTime ? 'What do you need to get started?' : 'How can we help?'}
        </Typography>
        <Grid container spacing={2}>
          {actions.map((action, i) => (
            <TourGridItem key={i} action={action} />
          ))}
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', gap: 2 }}>
        {<LinkStyleButton onClick={() => setConsent(INITIAL_COOKIE_VALUE)}>Change Cookie settings</LinkStyleButton>}
        <LinkStyleButton
          component={RouterLink}
          to={AppUtility.mainModeRoutes[AppUtility.mainModes.disclosure].route}
          onClick={onClose}
        >
          Responsible disclosure policy
        </LinkStyleButton>
      </DialogActions>
    </Dialog>
  );
};

export default TourDialog;

const useTourGridItemStyles = makeStyles({ name: 'TourGridItem' })((theme, _params, classes) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardContent: { flexGrow: 1 },
  cardActions: { justifyContent: 'center', paddingBottom: theme.spacing(2) },
  mediaWrapper: {
    width: '100%',
    paddingBottom: 'calc(100% / 16 * 9)',
    position: 'relative',
    [`&.${classes.darkPrimary}`]: { backgroundColor: theme.palette.primary.dark },
    [`&.${classes.lightPrimary}`]: { backgroundColor: theme.palette.primary.light },
  },
  iconContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    padding: theme.spacing(2),
    '&>.MuiSvgIcon-root': {
      height: '100%',
      width: '100%',
      color: 'white',
    },
  },
  media: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    backgroundSize: 'contain',
  },
  darkPrimary: {},
  lightPrimary: {},
}));

const TourGridItem = ({ action }) => {
  const { classes: styles, cx } = useTourGridItemStyles();

  return (
    <Grid item xs={12} sm={4}>
      <Card className={styles.root}>
        <div className={cx(styles.mediaWrapper, action?.media?.background && styles[action?.media?.background])}>
          {action?.media?.type === 'img' ? (
            <CardMedia className={styles.media} image={action?.media?.src} title={action?.media?.alt} />
          ) : (
            <div className={styles.iconContainer}>{action?.media?.src}</div>
          )}
        </div>
        <CardContent className={styles.cardContent}>
          <Typography gutterBottom variant="subtitle1" component="h2" color="textPrimary">
            {action?.title}
          </Typography>
          <Typography variant="body1" color="textSecondary" component="p">
            {action?.body}
          </Typography>
        </CardContent>
        <CardActions className={styles.cardActions}>
          {action?.actions?.length > 0 &&
            action?.actions.map(({ title, ...buttonProps }, i) => (
              <Button key={i} color="primary" variant="outlined" {...buttonProps}>
                {title}
              </Button>
            ))}
        </CardActions>
      </Card>
    </Grid>
  );
};
