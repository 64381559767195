import React from 'react';

import { Typography } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';

import LoadingButton from '../LoadingButton';

const SectionTitle = ({ first = false, border = true, style: userStyles = {}, className, classes, children }) => {
  const classNames = [classes.container, first ? '' : classes.notFirst, border ? classes.border : '', className]
    .join(' ')
    .trim();
  return (
    <div className={classNames} style={userStyles}>
      {children}
    </div>
  );
};

SectionTitle.propTypes = {
  first: PropTypes.bool,
  border: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

const StyledSectionTitle = withStyles(SectionTitle, (theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    width: '100%',
    paddingBottom: '8px',
    marginBottom: '16px',
  },
  notFirst: {
    marginTop: '40px',
  },
  border: {
    borderBottom: `1px solid ${theme.palette.action.disabled}`,
  },
}));

/*
 * sub components
 */

const SectionTitleText = ({
  style: userStyles = {},
  className,
  classes,
  secondaryButton = null,
  TypographyProps,
  children,
}) => {
  return (
    <div className={classes.titleContainer}>
      <Typography
        component="h1"
        variant="subtitle1"
        className={`${classes.title} ${className}`}
        {...TypographyProps}
        style={userStyles}
      >
        {children}
      </Typography>
      {secondaryButton && secondaryButton}
    </div>
  );
};

SectionTitleText.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  secondaryButton: PropTypes.node,
  children: PropTypes.node,
};

const StyledSectionTitleText = withStyles(SectionTitleText, (theme) => ({
  titleContainer: {
    display: 'flex',
    flexGrow: 1,
    paddingBottom: '1px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '10px',
      whiteSpace: 'break-spaces',
    },
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    alignItems: 'center',
  },
  title: { height: 'fit-content' },
}));

const SectionTitleAction = ({ className, classes, children, ...otherProps }) => {
  return (
    <LoadingButton variant="contained" color="primary" className={`${classes.button} ${className}`} {...otherProps}>
      {children}
    </LoadingButton>
  );
};

SectionTitleAction.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

const StyledSectionTitleAction = withStyles(SectionTitleAction, (theme) => ({
  button: {
    height: '34px',
    minWidth: '100px',
    textTransform: 'none',
    maxWidth: 'fit-content',
  },
}));

StyledSectionTitle.SectionTitleText = StyledSectionTitleText;
StyledSectionTitle.SectionTitleAction = StyledSectionTitleAction;

export default StyledSectionTitle;
