import React from 'react';

import { Box, Checkbox, Chip, ListItemText, MenuItem, Radio, Select, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import TypeChip from 'ReusableComponents/TypeChip/TypeChip';
import useChipStyles from 'ReusableComponents/TypeChip/useChipStyles';

const useStyles = makeStyles({ name: 'MultiSelectComponent' })((theme, _params, classes) => ({
  root: { height: theme.spacing(6), '& .MuiInputBase-input': { padding: theme.spacing(1) } },
  item: {
    padding: theme.spacing(0, 1.5),
    gap: theme.spacing(1),
    '&.Mui-selected': { backgroundColor: 'unset' },
  },
  text: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    margin: 0,
    [`& .${classes.label}`]: { fontWeight: 600 },
  },
  chip: {
    '& .MuiChip-deleteIcon': { zIndex: 1301 },
    [theme.breakpoints.down('sm')]: { gap: theme.spacing(0.5), '& .MuiChip-label': { display: 'none' } },
  },
  renderContainer: { display: 'flex', flexWrap: 'wrap', gap: 0.5 },
  label: {},
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '100%',
    padding: theme.spacing(0.5),
    boxSizing: 'border-box',
    height: 24,
    width: 24,
    '&>svg': { width: 'unset', height: 'unset', flexShrink: 1 },
  },
}));

const MultiSelectComponent = ({
  className,
  items,
  value,
  handleChange,
  minWidth,
  chipType = 'default',
  multiple = true,
}) => {
  const { classes: styles, cx } = useStyles();
  const { classes: chipStyles } = useChipStyles();

  return (
    <Select
      className={cx(styles.root, className)}
      value={value}
      multiple={multiple}
      displayEmpty
      fullWidth
      onChange={handleChange}
      MenuProps={{ disablePortal: true }}
      renderValue={(selected) => (
        <div className={styles.renderContainer}>
          {selected?.length === 0
            ? 'Any'
            : selected.map((selected) => {
                const selectedItem = items.find((i) => i?.key === selected);
                const onDelete = () => handleChange({ target: { value: value.filter((x) => x !== selected) } });
                const onMouseDown = (e) => {
                  e?.stopPropagation();
                  e?.preventDefault();
                };

                const Icon = selectedItem?.icon;

                return chipType === 'TypeChip' ? (
                  <TypeChip
                    className={styles.chip}
                    key={selectedItem?.key}
                    onMouseDown={multiple ? onMouseDown : undefined}
                    onDelete={multiple ? onDelete : undefined}
                    type={selectedItem?.key}
                  />
                ) : (
                  <Chip
                    className={cx(styles.chip, chipStyles.root)}
                    key={selectedItem?.key}
                    onDelete={multiple ? onDelete : undefined}
                    onMouseDown={multiple ? onMouseDown : undefined}
                    label={selectedItem?.label}
                    color={selectedItem?.color}
                    icon={<Icon />}
                  />
                );
              })}
        </div>
      )}
    >
      {items.map(({ key: itemKey, label: itemLabel, icon: Icon, color, secondary }) => (
        <MenuItem key={itemKey} value={itemKey} className={styles.item}>
          {multiple ? (
            <Checkbox color="primary" checked={value?.includes(itemKey)} />
          ) : (
            <Radio color="primary" checked={value?.includes(itemKey)} />
          )}
          {Icon && (
            <Box
              className={styles.iconContainer}
              sx={{
                backgroundColor: (theme) => theme.palette?.[color]?.main,
                color: (theme) => theme.palette?.[color]?.contrastText,
              }}
            >
              {!!Icon?.type?.render ? <Icon /> : Icon}
            </Box>
          )}

          <ListItemText
            className={styles.text}
            primary={
              <>
                <Typography variant="overline" className={styles.label} sx={{ minWidth }}>
                  {itemLabel}
                </Typography>
              </>
            }
            secondary={secondary}
            disableTypography
          />
        </MenuItem>
      ))}
    </Select>
  );
};

export default MultiSelectComponent;
