export const parseSize = ({ value: b, round = 2, abs = false, withSpace }) => {
  const base = 1024;
  if (b < 0) {
    b = 0;
  }
  const isNegative = b < 0;

  if (!b && b !== 0) {
    return null;
  }

  b = Math.abs(b) * Math.pow(base, 3);

  let i = ~~(Math.log2(b) / 10);
  let rounder = Math.pow(10, round);

  return (
    (!!isNegative && !abs ? '-' : '') +
    Math.round((b / Math.pow(base, i)) * rounder) / rounder +
    (withSpace ? ' ' : '') +
    ('KMGTPEZY'[i - 1] || '') +
    'B'
  );
};

export const parsePU = ({ value: b, round = 2, abs = false }) => {
  const base = 1000;
  const isNegative = b < 0;

  if (!b && b !== 0) {
    return null;
  }

  b = Math.abs(b);

  let i = ~~(Math.log2(b) / 10);
  let rounder = Math.pow(10, round);

  return (
    (!!isNegative && !abs ? '-' : '') +
    Math.round((b / Math.pow(base, i)) * rounder) / rounder +
    (['k', 'M', 'B', 'T', 'Qa', 'Qi', 'Sx', 'Sp', 'Oc', 'No', 'Dc'][i - 1] || '')
  );
};

export const stylePrice = (n) => {
  return n?.toLocaleString(undefined, {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};
