import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';

import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';

import { usePaneContent, usePaneContentFuncs } from 'Viewer/DetailsPane/PaneContentContext';

const DEFAULT_MIN_HEIGHT = 48;

const useDefaultStyles = makeStyles({ name: 'StyledAccordion-Default' })((theme, _params, classes) => ({
  root: {
    background: 'none',
    boxShadow: 'none',
    boxSizing: 'border-box',
    [`&.${classes.expanded}`]: {
      margin: 0,
    },
    '&:before': {
      display: 'none',
    },
  },
  summary: {
    marginBottom: -1,
    minHeight: DEFAULT_MIN_HEIGHT,
    [`.${classes.expanded} &`]: {
      minHeight: DEFAULT_MIN_HEIGHT,
    },
    '& .MuiAccordionSummary-content': {
      alignItems: 'center',
      gap: theme.spacing(3),
      [`.${classes.expanded} &`]: {
        margin: '0',
      },
      '&>svg': {
        fill: theme.palette.common.gray1,
      },
    },
  },
  details: {
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  disableGutters: { paddingLeft: 0, paddingRight: 0 },
  disablePadding: { padding: 0 },
  heading: {},
  startIconContainer: {
    color: theme.palette.common.gray2,
    // height: 30,
    // width: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  expanded: {},
  disableScroll: {
    overflowY: 'hidden',
  },
  contained: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
    minHeight: 0,
    '& .MuiCollapse-root': {
      display: 'flex',
      minHeight: 0,
      '& .MuiCollapse-wrapper': {
        minHeight: 0,
        maxHeight: '100%',
        '& .MuiAccordion-region': {
          display: 'flex',
          maxHeight: '100%',
          flexDirection: 'column',
          [`& .${classes.details}`]: { minHeight: 0, maxHeight: '100%', display: 'flex', overflow: 'auto' },
        },
      },
    },
  },
}));

const useHeaderStyles = makeStyles({ name: 'StyledAccordion-Header' })((theme, { accordionAmount = 0 }, classes) => ({
  root: {
    '&:before': {
      display: 'none',
    },
    [`&.${classes.expanded}`]: {
      overflowY: 'auto',
    },
    [`&.${classes.expanded}.${classes.disableScroll}`]: {
      overflowY: 'hidden',
    },
    [`&.${classes.fullHeight}`]: { flexGrow: 0 },
    [`&.${classes.expanded}.${classes.fullHeight}`]: { flexGrow: 1 },
    maxHeight: `calc(100% - calc(${
      accordionAmount > 0 ? accordionAmount - 1 : accordionAmount
    } * ${DEFAULT_MIN_HEIGHT}px))`,
  },
  summary: {
    border: `1px solid ${theme.palette.divider}`,
    borderRight: 0,
    borderLeft: 0,
    background: 'white',
    position: 'sticky',
    top: 0,
    zIndex: 10,
    '& .MuiAccordionSummary-content': {
      '& span': {},
    },
  },
  detailsWrapper: {},
  details: { paddingTop: theme.spacing(2), paddingBottom: theme.spacing(3) },
  heading: {},
  startIconContainer: {},
  expanded: {},
  fullHeight: {},
  disableScroll: {},
}));

const useSubheaderStyles = makeStyles({ name: 'StyledAccordion-Subheader' })((theme, _params, classes) => ({
  root: {
    border: 0,
    '&:before': {
      display: 'none',
    },
  },
  summary: {
    background: 'white',
    border: `1px solid ${theme.palette.divider}`,
    borderRight: 0,
    borderLeft: 0,
    minHeight: 36,
    [`.${classes.expanded} &`]: {
      minHeight: 36,
    },
    '& .MuiAccordionSummary-content': {
      margin: theme.spacing(0.5, 0),
      [`.${classes.expanded} &`]: {
        margin: theme.spacing(0.5, 0),
      },
      '& span': {},
    },
    '& .MuiAccordionSummary-expandIcon': {
      padding: theme.spacing(0.5),
      marginRight: theme.spacing(-0.5),
    },
  },
  details: {},
  heading: {},
  //disableGutters: { paddingLeft: 0, paddingRight: 0 },
  startIconContainer: {},
  expanded: {},
}));

const useItemStyles = makeStyles({ name: 'StyledAccordion-Item' })((theme, _params, classes) => ({
  root: {
    color: theme.palette.common.gray1,
    '&:before': { display: 'block', content: '""' },
    [`&.${classes.expanded}:before`]: { opacity: 1 },
  },
  summary: {
    padding: theme.spacing(0, 2),
    '& svg': {},
  },
  details: { padding: theme.spacing(1, 2) },
  heading: {},
  //disableGutters: { paddingLeft: 0, paddingRight: 0 },
  expanded: {},
}));

const StyledAccordion = ({
  children,
  defaultExpanded = true,
  overRideOpen = false,
  disabled = false,
  startIcon = null,
  title = '',
  onClick,
  disableGutters = false,
  disablePadding = false,
  headerContent,
  type = 'item',
  group,
  accordionAmount,
  fullHeight,
  disableScroll = false,
  passRef = false,
  contained = false,
  classNames = {},
  sxs = {},
  ...rest
}) => {
  //const [viewerSections, setViewerSections] = useLocalStorage('viewerSections', {});
  const { viewerSections } = usePaneContent();
  const { setViewerSections } = usePaneContentFuncs();

  const { classes: defaultStyles, cx } = useDefaultStyles();
  const { classes: headerStyles } = useHeaderStyles({ accordionAmount });
  const { classes: subheaderStyles } = useSubheaderStyles();
  const { classes: itemStyles } = useItemStyles();

  const accordionRef = useRef();

  const styleList = useMemo(
    () => [
      defaultStyles,
      type === 'header' && headerStyles,
      type === 'subheader' && subheaderStyles,
      type === 'item' && itemStyles,
    ],
    [type, defaultStyles, headerStyles, subheaderStyles, itemStyles]
  );

  const {
    root: customRootClassName = undefined,
    summary: customSummaryClassName = undefined,
    details: customDetailsClassName = undefined,
  } = classNames;

  const {
    root: customRootSx = undefined,
    summary: customSummarySx = undefined,
    details: customDetailsSx = undefined,
  } = sxs;

  const constructStyles = useCallback(
    (...props) => cx(styleList.map((style) => props.map((prop) => style[prop]))),
    [styleList]
  );

  const isExpanded = useMemo(
    () =>
      viewerSections[group ?? title] === undefined
        ? defaultExpanded
        : !!group
        ? viewerSections[group] === title
        : viewerSections[title],
    [group, defaultExpanded, viewerSections, title]
  );

  const setIsExpanded = useCallback(
    (event, isExpanded) => {
      setViewerSections((prev) => ({ ...prev, [group ?? title]: !!group && !!isExpanded ? title : isExpanded }));
      typeof onClick === 'function' && onClick(isExpanded);
    },
    [group, onClick, setViewerSections, title]
  );

  useEffect(() => {
    if (overRideOpen && defaultExpanded) {
      setIsExpanded(true);
    }
  }, [defaultExpanded, overRideOpen, setIsExpanded]);

  const headerContentValue = headerContent ?? (
    <>
      {!!startIcon && <div className={constructStyles('startIconContainer')}>{startIcon}</div>}
      <Typography
        className={constructStyles('heading')}
        variant={type === 'item' ? 'subtitle2' : 'button'}
        color={type === 'subheader' ? 'primary' : 'inherit'}
      >
        {title}
      </Typography>
    </>
  );

  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      disabled={disabled}
      elevation={0}
      className={cx(
        constructStyles(
          'root',
          isExpanded && 'expanded',
          fullHeight && 'fullHeight',
          disableScroll && 'disableScroll',
          contained && 'contained'
        ),
        customRootClassName
      )}
      onChange={setIsExpanded}
      expanded={isExpanded ?? defaultExpanded}
      ref={accordionRef}
      sx={{ ...customRootSx }}
      slotProps={{ transition: { mountOnEnter: true, unmountOnExit: true } }}
      {...rest}
    >
      <AccordionSummary
        expandIcon={<ExpandMore fontSize={type === 'subheader' ? 'small' : 'medium'} />}
        className={cx(constructStyles('summary'), customSummaryClassName)}
        disableRipple={false}
        disableTouchRipple={false}
        focusRipple={true}
        sx={{ ...customSummarySx }}
      >
        {headerContentValue}
      </AccordionSummary>
      <AccordionDetails
        className={cx(
          constructStyles('details', !!disableGutters && 'disableGutters', !!disablePadding && 'disablePadding'),
          customDetailsClassName
        )}
        sx={{ ...customDetailsSx }}
      >
        {React.Children.map(children, (child) =>
          !passRef ? child : child && React.cloneElement(child, { accordionRef })
        )}
      </AccordionDetails>
    </Accordion>
  );
};

StyledAccordion.propTypes = {
  children: PropTypes.node.isRequired,
  defaultExpanded: PropTypes.bool,
  disabled: PropTypes.bool,
  startIcon: PropTypes.element,
  title: PropTypes.node,
  disableGutters: PropTypes.bool,
  headerContent: PropTypes.node,
};

export default StyledAccordion;
