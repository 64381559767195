import React, { useState, useEffect, useCallback, useRef } from 'react';

import { Typography, Button, CircularProgress } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import FullScreenLayout from '../FullScreenLayout';
import SuccessAstronaut from './successAstronaut.png';
import ErrorAstronaut from './errorAstronaut.svg';
import { useMinimalAuth, useQueryParams, useAuthFuncs } from '../../hooks';
import ApiManager from '../../ApiManager';
import { useLocalStorage } from 'hooks';
import { useHistory } from 'react-router-dom';

const LOCAL_STORE_ACCOUNTS_KEY = 'accounts';

const useStyles = makeStyles()((theme) => ({
  paperContainer: {
    alignItems: 'center',
  },
  successAstronaut: {
    height: '300px',
  },
  errorAstronaut: {
    height: '250px',
    margin: '20px 0',
  },
  title: { marginTop: theme.spacing(2) },
  subtitle: {},
  needHelpContainer: {
    marginTop: '2rem',
    display: 'flex',
    alignItems: 'center',
  },
  needHelpText: {},
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    paddingLeft: '5px',
  },
  buttonLink: {},
  spinner: {
    padding: '50px',
  },
}));

const typeMapping = {
  invite: {
    url: null,
    noButton: false,
    userIdRequired: true,
    withNotifications: true,
    successMessageWithUser:
      'See the notifications in the top navigation bar of the Drive to review and accept the invite',
    successMessageWithoutUser: 'Login to accept the invite',
  },
  transfer: {
    url: null,
    noButton: false,
    userIdRequired: true,
    withNotifications: true,
    successMessageWithUser:
      'See the notifications in the top navigation bar of the Drive to review and accept the transfer',
    successMessageWithoutUser: 'Login to accept the transfer',
  },
  emailUpdated: {
    url: null,
    noButton: true,
    withNotifications: false,
    userIdRequired: true,
    successMessageWithUser: 'please go to your inbox to verify your email',
  },
  passwordUpdated: {
    url: null,
    noButton: false,
    withNotifications: false,
    userIdRequired: true,
    successMessageWithUser: 'Your password was updated',
    successMessageWithoutUser: 'You can now login with your new password',
  },
  usernameUpdated: {
    noButton: false,
    url: null,
    withNotifications: false,
    userIdRequired: true,
    successMessageWithUser: 'Your username was updated',
  },
  changeEmail: {
    url: '/v3/account/security/email/validate',
    noButton: false,
    withNotifications: false,
    userIdRequired: true,
    successMessageWithUser: 'You successfully updated your email!',
    successMessageWithoutUser: 'You successfully updated your email!',
  },
  resetPasswordRequest: {
    url: null,
    withNotifications: false,
    noButton: true,
    userIdRequired: false,
    successMessageWithUser: 'We have sent you an email with a link to reset your password',
    successMessageWithoutUser: 'We have sent you an email with a link to reset your password',
  },
  setPasswordRequest: {
    url: null,
    withNotifications: false,
    noButton: true,
    userIdRequired: false,
    successMessageWithUser: 'We have sent you an email with a link to set your password',
    successMessageWithoutUser: 'We have sent you an email with a link to set your password',
  },
  resetPassword: {
    url: '/v3/account/security/password/validate',
    noButton: false,
    withNotifications: false,
    userIdRequired: true,
    successMessageWithUser:
      'You successfully updated your password! You can update your password by clicking your user avatar in the top right and choosing manage account.',
    successMessageWithoutUser: 'You successfully updated your password. You can now login with your new password.',
  },
  registration: {
    url: '/v3/account/security/email/validate',
    userIdRequired: true,
    noButton: false,
    withNotifications: false,
    successMessageWithUser: 'Your registration was successful! You can now login to your account',
    successMessageWithoutUser: 'Your registration was successful! You can now start using Ellipsis Drive.',
  },
  registrationInvite: {
    url: null,
    userIdRequired: true,
    withNotifications: false,
    noButton: false,
    successMessageWithoutExistingUser: `Looks like your first time around here! Let's get you setup!`,
    successMessageWithUser:
      'See the notifications in the top navigation bar of the Drive to review and accept the invite',
    successMessageWithoutUser: `Login to accept the invite`,
  },
  transferInvite: {
    url: null,
    userIdRequired: true,
    noButton: false,
    withNotifications: true,
    successMessageWithoutExistingUser: `Looks like your first time around here! Let's get you setup!`,
    successMessageWithUser:
      'See the notifications in the top navigation bar of the Drive to review and accept the transfer',
    successMessageWithoutUser: `Login to accept the invite`,
  },
  registrationReferral: {
    url: null,
    userIdRequired: true,
    noButton: false,
    withNotifications: false,
    successMessageWithoutExistingUser: `Welcome to Ellipsis drive. Let's get you setup!`,
    successMessageWithUser: 'You are already an Ellipsis Drive member',
    successMessageWithoutUser: `You are already an Ellipsis Drive member`,
  },
};

const RegistrationPage = () => {
  const { classes } = useStyles();
  const { type, validationToken, userId, invitedByUserId } = useQueryParams();
  const user = useMinimalAuth();
  const { signout, signin, fetchUserInfo } = useAuthFuncs();
  const [accounts /* , setAccounts */] = useLocalStorage(LOCAL_STORE_ACCOUNTS_KEY, []);
  const [userNotExists, setUserNotExists] = useState(false);

  const [error, setError] = useState(null);
  const [loadingValidation, setLoadingValidation] = useState(true);
  const [loadingUser, setLoadingUser] = useState(true);
  const [loadingCheckUser, setLoadingCheckUser] = useState(true);

  const history = useHistory();

  const manageUser = useCallback(() => {
    console.log('managing user');

    let cb = () => {
      setLoadingUser(false);
    };

    if (!userId) {
      cb();
    }

    if (user && user.id === userId) {
      console.log('current user is correct');
      fetchUserInfo(user, cb);
    } else {
      let potentialAccount = accounts ? accounts.find((a) => a.id === userId) : null;

      if (potentialAccount) {
        console.log('good user found in cache trying to login');
        signin(potentialAccount, cb);
      } else {
        if (user) {
          console.log('user not found so login out');
          signout(cb);
        } else {
          cb();
          console.log('desired user not found and not logged in');
        }
      }
    }
  }, [signin, userId, accounts, user, signout, fetchUserInfo]);

  const checkUserExits = useCallback(async () => {
    console.log('check user exists');
    if (!userId) {
      setLoadingCheckUser(false);
      return;
    }

    try {
      /* let res =  */ await ApiManager.get('/v3/user/' + userId);
      console.log('user exits');
    } catch (e) {
      console.log('user does not exist');
      setUserNotExists(true);
    }
    setLoadingCheckUser(false);
  }, [userId]);

  const validateWithBackend = useCallback(
    async (type, token) => {
      try {
        if (typeMapping[type].url) {
          await ApiManager.post(`${typeMapping[type].url}`, { token: token });
        }
      } catch (error) {
        console.log('type', type);
        if (type === 'registration') {
          try {
            await ApiManager.get(`/v3/user/${userId}`);
          } catch {
            setError(error.message);
          }
        } else {
          setError(error.message);
        }
      }
      checkUserExits();
      manageUser();
      setLoadingValidation(false);
    },
    [checkUserExits, manageUser, userId]
  );

  const firstTime = useRef(true);
  useEffect(() => {
    console.log('try using effect');
    if (!firstTime.current) {
      return;
    }
    firstTime.current = false;

    if (
      !type ||
      !Object.keys(typeMapping).includes(type) ||
      (!validationToken && typeMapping[type].url) ||
      (!userId && typeMapping[type].userIdRequired)
    ) {
      setError('Invalid link');
      setLoadingCheckUser(false);
      setLoadingUser(false);
      setLoadingValidation(false);
    } else {
      validateWithBackend(type, validationToken);
    }
  }, [validationToken, type, userId, manageUser, checkUserExits, validateWithBackend]);

  const loading = loadingCheckUser || loadingValidation || loadingUser;

  return (
    <FullScreenLayout paperClassName={classes.paperContainer}>
      <Helmet>
        <meta name="robots" content="noindex" data-react-helmet="true" />
      </Helmet>
      {loading ? (
        <CircularProgress className={classes.spinner} />
      ) : (
        <>
          <Helmet>
            <title>
              {error !== null ? `Error` : `Verified!`} - {ApiManager?.displayName ?? 'Ellipsis Drive'}
            </title>
          </Helmet>
          <Typography className={classes.title} variant="h2">
            {error !== null ? 'Something went wrong...' : `Success!`}
          </Typography>
          {!!error && (
            <Typography className={classes.subtitle} variant="h3" align="center">
              {error}
            </Typography>
          )}
          {!loading && !error && (
            <Typography className={classes.subtitle} variant="h3" align="center">
              {userNotExists
                ? typeMapping[type]?.successMessageWithoutExistingUser
                : user
                ? typeMapping[type]?.successMessageWithUser
                : typeMapping[type]?.successMessageWithoutUser}
            </Typography>
          )}
          {error !== null ? (
            <img src={ErrorAstronaut} alt="" className={classes.errorAstronaut} />
          ) : (
            <img src={SuccessAstronaut} alt="" className={classes.successAstronaut} />
          )}
          {typeMapping[type]?.noButton || error ? null : userNotExists ? (
            <Button
              component={Link}
              to={`/register?validationToken=${validationToken}&userId=${userId}${
                invitedByUserId ? `&invitedByUserId=${invitedByUserId}` : ''
              }`}
              variant="contained"
              color="primary"
              className={classes.buttonLink}
            >
              Setup your account
            </Button>
          ) : !user ? (
            <Button
              onClick={() => {
                history.push('/login?userId=' + userId, {
                  from: `/drive/me?${
                    ['registrationInvite', 'transfer', 'invite', 'transferInvite'].includes(type)
                      ? 'notification=true'
                      : ''
                  }`,
                });
              }}
              variant="contained"
              color="primary"
              className={classes.buttonLink}
            >
              Go to login
            </Button>
          ) : (
            <Button
              component={Link}
              to={typeMapping[type].withNotifications ? '/drive/me?notification=true' : '/drive/me'}
              variant="contained"
              color="primary"
              className={classes.buttonLink}
            >
              Go to drive
            </Button>
          )}
        </>
      )}
    </FullScreenLayout>
  );
};

export default RegistrationPage;
