import React from 'react';

import { Settings } from '@mui/icons-material';
import { useContextMenu } from '../../ContextMenuContext';

import CombinedMenuItem from '../Components/CombinedMenuItem/CombinedMenuItem';
import { useCurrentFolder } from 'Drive/CurrentFolderContext';

const MapSettings = ({ onClose }) => {
  const { menuOptions } = useContextMenu();
  const { path } = menuOptions;
  const { onFolderClick, mainMode, onToLocationClick, currentFolderInfo, changeMainMode } = useCurrentFolder();

  return (
    <CombinedMenuItem
      icon={<Settings />}
      label="Settings"
      onClick={() => {
        onClose();
        if (mainMode === '/drive/search') {
          onToLocationClick(path, '/settings/general');
        } else if (['map', 'shape'].includes(currentFolderInfo?.type)) {
          changeMainMode('/settings/general');
        } else {
          onFolderClick(path, '/settings/general');
        }
      }}
      openNew
    />
  );
};

export default MapSettings;
