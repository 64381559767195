import React, { useState } from 'react';
import { Collapse, FormControlLabel, Checkbox, Button, TextField, Typography } from '@mui/material';

import PropTypes from 'prop-types';

import DatePatternChooser from './DatePatternChooser';
import { makeStyles } from 'tss-react/mui';
import { Add, Delete } from '@mui/icons-material';

const useStyles = makeStyles()((theme) => ({
  section: {
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
  },
  container: {
    marginLeft: '27px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  addButton: {
    textTransform: 'none',
  },
  disabled: {
    color: theme.palette.text.disabled,
  },
  interpText: {
    color: theme.palette.primary.main,
    fontSize: '0.9rem',
    cursor: 'pointer',
  },
  dateColumnTextField: {
    marginBottom: '6px',
  },
  textFieldRoot: {
    padding: '8px 5px !important',
  },
  itemContainer: {
    width: '100%',
    boxSizing: 'border-box',
    margin: '10px 0px',
    paddingLeft: '10px',
    borderLeft: `3px solid ${theme.palette.divider}`,
    borderBottom: `3px solid ${theme.palette.divider}`,
    position: 'relative',
  },
  caption: {
    marginBottom: '8px',
  },
  deleteButton: {
    position: 'absolute',
    right: '0',
    top: '6px',
    textTransform: 'none',
    padding: '1px 15px',
    fontSize: '0.9rem',
  },
}));

const DateColumnSettings = ({ dateColumns, setDateColumns, datePatterns, setDatePatterns }) => {
  const { classes } = useStyles();

  const [showDateOptions, setShowDateOptions] = useState(false);

  const handleShowDateOptionsChange = (e) => {
    if (e.target.checked) {
      setShowDateOptions(true);
    } else {
      setShowDateOptions(false);
      setDateColumns(['']);
      setDatePatterns(['']);
    }
  };

  const handleAddDateColumn = () => {
    setDateColumns((dateColumns) => (dateColumns === null ? [''] : [...dateColumns, '']));
    setDatePatterns((datePatterns) =>
      datePatterns === null ? [''] : [...datePatterns, datePatterns[datePatterns.length - 1]]
    );
  };

  const addButtonDisabled =
    dateColumns?.length > 0 &&
    (dateColumns[dateColumns?.length - 1] === '' || datePatterns[datePatterns?.length - 1] === '');

  return (
    <section className={classes.section}>
      <FormControlLabel
        control={<Checkbox checked={showDateOptions} onChange={handleShowDateOptionsChange} name="date options" />}
        label="Do your files contain a date?"
      />
      <Collapse in={showDateOptions}>
        <div className={classes.container}>
          <DateColumnList
            dateColumns={dateColumns}
            setDateColumns={setDateColumns}
            datePatterns={datePatterns}
            setDatePatterns={setDatePatterns}
          />
          <Button
            className={classes.addButton}
            variant="text"
            color="primary"
            startIcon={<Add color={addButtonDisabled ? 'disabled' : 'primary'} />}
            onClick={handleAddDateColumn}
            disabled={addButtonDisabled}
          >
            Add date column
          </Button>
        </div>
      </Collapse>
    </section>
  );
};

DateColumnSettings.propTypes = {
  dateColumns: PropTypes.arrayOf(PropTypes.string),
  setDateColumns: PropTypes.func.isRequired,
  datePatterns: PropTypes.arrayOf(PropTypes.string),
  setDatePatterns: PropTypes.func.isRequired,
};

const DateColumnList = ({ dateColumns, setDateColumns, datePatterns, setDatePatterns }) => {
  const handleDateColumnChange = (index) => (e) => {
    e.persist();
    setDateColumns((dateColumns) =>
      dateColumns.map((dateColumn, idx) => (idx === index ? e.target.value : dateColumn))
    );
  };

  const handleDatePatternChange = (index) => (func) => {
    setDatePatterns((datePatterns) =>
      datePatterns.map((datePattern, idx) => (idx === index ? func(datePattern) : datePattern))
    );
  };

  const handleDelete = (index) => () => {
    setDatePatterns((datePatterns) => datePatterns.filter((datePattern, idx) => index !== idx));
    setDateColumns((dateColumns) => dateColumns.filter((dateColumn, idx) => index !== idx));
  };

  return (
    <>
      {dateColumns?.map((dateColumn, index) => (
        <DateColumn
          dateColumn={dateColumns[index]}
          handleDateColumnChange={handleDateColumnChange(index)}
          datePattern={datePatterns[index]}
          handleDatePatternChange={handleDatePatternChange(index)}
          handleDelete={handleDelete(index)}
          key={index}
          disableDelete={dateColumns?.length === 1}
        />
      ))}
    </>
  );
};

DateColumnList.propTypes = {
  dateColumns: PropTypes.arrayOf(PropTypes.string),
  setDateColumns: PropTypes.func.isRequired,
  datePatterns: PropTypes.arrayOf(PropTypes.string),
  setDatePatterns: PropTypes.func.isRequired,
};

const DateColumn = ({
  dateColumn,
  handleDateColumnChange,
  datePattern,
  handleDatePatternChange,
  handleDelete,
  disableDelete,
}) => {
  const { classes } = useStyles();
  return (
    <div className={classes.itemContainer}>
      <Typography color="textSecondary" className={classes.caption}>
        Date column name:{' '}
      </Typography>
      <TextField
        className={classes.dateColumnTextField}
        variant="filled"
        value={dateColumn}
        onChange={handleDateColumnChange}
        placeholder="Column name"
        InputProps={{ classes: { input: classes.textFieldRoot } }}
        required
      />
      <DatePatternChooser datePattern={datePattern} handleDatePatternChange={handleDatePatternChange} />
      <Button
        variant="outlined"
        onClick={handleDelete}
        className={classes.deleteButton}
        startIcon={<Delete color={disableDelete ? 'disabled' : 'action'} />}
        disabled={disableDelete}
      >
        Delete
      </Button>
    </div>
  );
};

DateColumn.propTypes = {
  dateColumn: PropTypes.string,
  handleDateColumnChange: PropTypes.func.isRequired,
  datePattern: PropTypes.string,
  handleDatePatternChange: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  disableDelete: PropTypes.bool.isRequired,
};

export default DateColumnSettings;
