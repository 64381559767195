import React from 'react';
import { UploadPage } from '../core';

const fileFormats = ['tif', 'ecw', 'img', 'grib2', 'nc', 'jpg', 'jgw', 'gpkg'];

// This is the object that controls formats and which are accepted and how they are uploaded
export const uploadFormats = [
  {
    formats: ['tif', 'tiff', 'gtif', 'gtiff', 'geotif', 'geotiff', 'TIF', 'TIFF', 'img', 'IMG', 'gpkg'],
    type: 'success',
    // `uploadAs`, when specified, means that all of the `formats` should be uploaded as `uploadAs`
    // in this case for example, it means that geotiff will be uploaded as a tif
    uploadAs: 'tif',
  },
  {
    formats: ['ecw', 'ECW'],
    type: 'success',
    uploadAs: 'ecw',
  },
  {
    formats: ['geojson'],
    type: 'success',
    uploadAs: 'mask',
  },
  {
    formats: ['jpg', 'JPG', 'jpeg', 'JPEG'],
    type: 'success',
    uploadAs: 'jpg',
  },
  {
    formats: ['jgw', 'JGW'],
    type: 'success',
    uploadAs: 'jgw',
  },
  {
    formats: ['tfw', 'TFW'],
    type: 'success',
    uploadAs: 'tfw',
  },
  {
    formats: ['jp2', 'JP2', 'jp2000', 'JP2000'],
    type: 'success',
    uploadAs: 'jp2',
  },
  {
    formats: ['gpkg', 'GPKG'],
    type: 'success',
    uploadAs: 'gpkg',
  },
  {
    formats: ['grib', 'grib2', 'GRIB2', 'GRIB'],
    type: 'success',
    uploadAs: 'grib2',
  },
  {
    formats: ['nc', 'NC', 'netCDF', 'NETCDF'],
    type: 'success',
    uploadAs: 'nc',
  },
];

const RasterUpload = ({ captureId, setUploadOpen, setAutoProcessOpen }) => {
  return (
    <UploadPage
      fileFormats={fileFormats}
      captureId={captureId}
      setUploadOpen={setUploadOpen}
      uploadFormats={uploadFormats}
      setAutoProcessOpen={setAutoProcessOpen}
      type="raster"
    />
  );
};

export default RasterUpload;
