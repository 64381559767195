import React, { useState, useRef } from 'react';

import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { useMediaQuery, AppBar, Button, IconButton, Toolbar, Tooltip, Typography } from '@mui/material';
import { Menu as MenuIcon, Help, ArrowBack } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';

import Notifications from './Notifications';
import AccountMenu from './AccountMenu';
import { useAuth } from '../Account/AuthContext';
import { useMainContext } from 'ReusableComponents';
import { useCurrentFolder } from 'Drive/CurrentFolderContext';
import CustomAvatar from 'ReusableComponents/CustomAvatar';
import { closeViewerFlow } from 'Drive/CurrentFolderContext';
import './Navbar.css';
import NavbarViewerButton from 'Navbar/NavbarViewerButton';
import ApiManager from 'ApiManager';
import { CLOUDS, TYPES } from 'ApiUrls';
import { NAVBAR_BREAK } from 'App';

const useStyles = makeStyles({
  name: 'Navbar',
})((theme, _params, classes) => ({
  navBar: {
    height: 64,
    zIndex: 1300,
    color: theme.palette.action.active,
    backgroundColor: 'white',
    [`&.${classes.hide}`]: {
      height: 0,
      '& .MuiToolbar-regular': { display: 'none' },
    },
    [`&.${classes.ellipsis}`]: {
      color: '#f2f2f2',
      [`&.${classes.prod}`]: { backgroundColor: theme.palette.error.dark },
      [`&.${classes.acc}`]: { backgroundColor: theme.palette.success.main },
      [`&.${classes.dev}`]: { backgroundColor: theme.palette.warning.dark },

      [`& .${classes.logo}`]: {
        color: 'inherit',
        [`&.${classes.closable} .${classes.displayName}`]: { opacity: theme.palette.action.disabledOpacity },
      },
    },
  },
  white: {},
  toolbar: {
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 1),
      '&>.MuiIconButton-root': { padding: theme.spacing(0.5) },
    },
  },
  logo: {
    width: 150,
    maxWidth: '25vw',
    flexShrink: 0,
    '& img': { width: '100%' },
    color: 'inherit',
  },
  iconLogo: {
    padding: theme.spacing(0.5),
    flexShrink: 0,
    '&>img': { width: theme.spacing(4) },
  },
  innerLogo: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    alignItems: 'center',
    maxWidth: 'inherit',
    color: 'inherit',
  },
  spacer: {
    flexGrow: 1,
    flexShrink: 1,
    overflow: 'hidden',
  },
  hamburgerButton: {
    transition: 'transform 500ms cubic-bezier(0.68, -0.55, 0.27, 1.55)',
    transform: 'rotate(0deg)',
    color: theme.palette.common.gray3,
    [`&.${classes.open}`]: { transform: 'rotate(90deg)' },
  },
  helpButton: {
    /* color: theme.palette.common.gray3 */
  },
  avatarButton: { '&>div': { border: '2px solid white', boxShadow: theme.shadows[3] } },
  hide: {},
  open: {},
  invertButton: {},
  ellipsis: {},
  prod: {},
  acc: {},
  dev: {},
  closable: {},
  displayName: {},
}));

const NavBar = (props) => {
  const { classes, cx } = useStyles();
  const location = useLocation();
  const user = useAuth();
  const history = useHistory();
  const { currentFolderInfo, changeMainMode, mainMode, changeMainModeAndExit } = useCurrentFolder();

  const { openSidebar, onOpenSidebar } = useMainContext();
  const queryBreak = useMediaQuery((theme) => theme.breakpoints.up(NAVBAR_BREAK));
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const avatarRef = useRef(null);
  const [accountMenuOpen, setAccountMenuOpen] = useState(false);

  const onToggleSidebar = (e) => {
    onOpenSidebar(!openSidebar);
  };

  const isLocalhost = window?.location?.hostname === 'localhost';
  const isAcceptance = window?.location?.hostname === 'acc.app.ellipsis-drive.com';

  const closable = location.pathname.includes('/view') || location.pathname.includes('/settings');
  const viewerOrSettings = location.pathname.includes('/view') || location.pathname.includes('/settings');

  const isDev = ApiManager?.cloud === CLOUDS.ellipsis && ApiManager?.apiType === TYPES['dev'];
  const isAcc = ApiManager?.cloud === CLOUDS.ellipsis && ApiManager?.apiType === TYPES['acc'];
  const isProd = ApiManager?.cloud === CLOUDS.ellipsis && ApiManager?.apiType === TYPES['prod'];

  return (
    <React.Fragment>
      <AppBar
        position="fixed"
        component="nav"
        className={cx(
          props.hide && classes.hide,
          classes.navBar,
          (!!isDev || !!isAcc || !!isProd) && (!!isLocalhost || !!isAcceptance) && classes.ellipsis,
          !!isProd && classes.prod,
          !!isAcc && classes.acc,
          !!isDev && classes.dev
        )}
        elevation={1}
      >
        <Toolbar className={classes.toolbar}>
          {(!queryBreak || viewerOrSettings) && !closable && (
            <IconButton
              className={cx(classes.invertButton, classes.hamburgerButton, openSidebar ? classes.open : '')}
              color="inherit"
              onClick={onToggleSidebar}
            >
              <MenuIcon />
            </IconButton>
          )}

          {closable && (
            <Tooltip title={`Close ${mainMode === '/view' ? 'viewer' : 'settings'} and return to Drive`}>
              <IconButton
                color="inherit"
                className={cx(classes.invertButton, 'tutorialCloseViewer')}
                onClick={() => {
                  if (!currentFolderInfo) {
                    history.push('/drive/me');
                  } else if (closeViewerFlow === 'files' && currentFolderInfo?.type !== 'bookmark') {
                    // console.log('not exiting');
                    changeMainMode('drive');
                  } else {
                    // console.log('not exiting');
                    changeMainModeAndExit('drive');
                  }
                }}
                size="large"
                edge="start"
              >
                <ArrowBack />
              </IconButton>
            </Tooltip>
          )}

          {isSmall ? (
            <IconButton
              disabled={closable}
              className={cx(classes.iconLogo, closable && classes.closable, 'tutorialMainMenu')}
              onClick={() => history.push('/drive/me')}
              color="inherit"
            >
              <img
                src={
                  ApiManager.cloud !== CLOUDS.ellipsis
                    ? ApiManager.apiUrl + '/v3/backoffice/theme/logo'
                    : '/ellipsis-logo.svg'
                }
                alt="Go to drive"
              />
            </IconButton>
          ) : (
            <Button
              disabled={closable}
              className={cx(classes.logo, closable && classes.closable, 'tutorialMainMenu')}
              onClick={() => history.push('/drive/me')}
              size="large"
            >
              <div className={classes.innerLogo}>
                <img
                  src={
                    ApiManager.cloud !== CLOUDS.ellipsis
                      ? ApiManager.apiUrl + '/v3/backoffice/theme/wideLogo'
                      : '/svgs/Logo.svg'
                  }
                  alt="Go to drive"
                />
                {!!isLocalhost ? (
                  <Tooltip arrow title={ApiManager.apiUrl}>
                    <Typography noWrap display="inline" variant="overline" className={classes.displayName}>
                      {ApiManager.displayName}
                    </Typography>
                  </Tooltip>
                ) : null}
              </div>
            </Button>
          )}

          <div className={classes.spacer} />

          {mainMode === '/view' && <NavbarViewerButton isNavbar={true} />}

          <Tooltip title="Help">
            <IconButton
              component="a"
              target="_blank"
              onClick={() => {
                props.closeTourDialog();
                props.setTourDialogInfo(true);
              }}
              className={cx(classes.invertButton, classes.helpButton, 'tutorialHelp')}
              color="inherit"
            >
              <Help />
            </IconButton>
          </Tooltip>

          {!!user && <Notifications className={classes.invertButton} />}

          {user ? (
            <IconButton
              className={classes.avatarButton}
              onClick={() => setAccountMenuOpen((prev) => !prev)}
              ref={avatarRef}
            >
              <CustomAvatar user={user} size={isSmall ? 32 : undefined} />
            </IconButton>
          ) : (
            <Button
              className={classes.userButton}
              color={'primary'}
              variant={'contained'}
              onClick={() => {
                history.push('/login', {
                  from: `${history.location.pathname}${history.location.search}`,
                });
              }}
            >
              Log in
            </Button>
          )}
        </Toolbar>
        {user && (
          <AccountMenu
            open={accountMenuOpen}
            setOpen={setAccountMenuOpen}
            anchorRef={avatarRef}
            setAccountMenuOpen={setAccountMenuOpen}
          />
        )}
      </AppBar>
    </React.Fragment>
  );
};

export default NavBar;
