import React, { useCallback, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';

import { Autocomplete, InputAdornment, TextField } from '@mui/material';

import { useMinimalAuth } from 'hooks';
import { useMainContext } from 'ReusableComponents';

import ApiManager from 'ApiManager';

const Hashtag = ({ input, searchVal = '', setSearchVal }) => {
  const user = useMinimalAuth();
  const { onOpenSnackbar } = useMainContext();

  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const fetch = useCallback(
    (input, type) => {
      const parsedInput = input.charAt(0) === '#' ? input.substr(1) : input;
      input?.length === 0
        ? setOptions([])
        : type === 'change' &&
          parsedInput?.length !== 0 &&
          ApiManager.get('/v3/path/hashtag', { hashtag: parsedInput }, user)
            .then((res) => {
              setOptions(res?.map((o) => o?.substr(1)));
              setLoading(false);
            })
            .catch((err) => {
              console.error(err);
              onOpenSnackbar({ level: 'error', content: err.message });
              setLoading(false);
            });
    },
    [onOpenSnackbar, user]
  );

  const debouncedFetch = useMemo(() => debounce(fetch, 500), [fetch]);

  const handleInputChange = (event, newInputValue) => {
    setLoading(true);
    setSearchVal(newInputValue);
    debouncedFetch(newInputValue, event?.type);
  };

  return (
    <Autocomplete
      sx={{ '& .MuiOutlinedInput-root .MuiAutocomplete-input': { p: 0 } }}
      fullWidth
      autoComplete
      loading={loading && searchVal?.length > 0}
      value={searchVal}
      options={options}
      filterOptions={(x) => x}
      filterSelectedOptions
      renderInput={({ InputProps, ...params }) => (
        <TextField
          {...params}
          placeholder={input?.placeholder}
          fullWidth
          InputProps={{ ...InputProps, startAdornment: <InputAdornment position="start">#</InputAdornment> }}
        />
      )}
      onInputChange={handleInputChange}
      componentsProps={{ popper: { sx: { zIndex: 1302 } } }}
      noOptionsText={!searchVal ? 'Start Typing' : 'No Options'}
    />
  );
};

export default Hashtag;
