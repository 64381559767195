import React from 'react';

import { Menu, MenuItem, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ExpandMore } from '@mui/icons-material';

const useMenuButtonStyles = makeStyles()((theme, { disabled, width }) => ({
  propertyButton: {
    width,
    padding: '8px 12px',
    paddingRight: '26px',
    borderRadius: '4px',
    backgroundColor: 'rgba(0,0,0,0.05)',
    cursor: disabled ? 'default' : 'pointer',
  },
  buttonLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

const MenuButton = ({ currentValue, menuItems, disabled = false, width = '100px', menuWidth, handleMenuItemClick }) => {
  const { classes } = useMenuButtonStyles({ width, disabled });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        className={classes.propertyButton}
        classes={{ label: classes.buttonLabel }}
        onClick={disabled ? undefined : handleClick}
        endIcon={
          disabled ? undefined : (
            <ExpandMore
              style={{
                position: 'absolute',
                top: 'calc(50% - 10px)',
                right: '4px',
              }}
            />
          )
        }
      >
        {currentValue}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: menuWidth ? menuWidth : width,
          },
        }}
      >
        {menuItems.map((item) => (
          <MenuItem
            key={item}
            onClick={() => {
              handleMenuItemClick(item);
              handleClose();
            }}
            selected={item === currentValue}
          >
            {item}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default MenuButton;
