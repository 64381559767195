import React, { useState, useEffect } from 'react';
import Alert from '@mui/material/Alert';
import './SnackbarControl.css';

import { Snackbar } from '@mui/material';

const SnackbarControl = ({ opts }) => {
  const [open, setOpen] = useState({ open: false, key: undefined });

  const { content, duration, action, persistent, level } = opts || {};

  useEffect(() => {
    if (!!opts?.content && (opts?.content?.length > 0 || opts?.content?.props?.children?.length > 0)) {
      setOpen({ open: true, key: new Date().getTime() });
    }
  }, [opts]);

  useEffect(() => {
    if (!content || content === '') {
      setOpen({ open: false });
    }
  }, [content]);

  return (
    <Snackbar
      style={{ zIndex: 999999999 }}
      open={open.open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      key={open.open ? open.key : undefined}
      autoHideDuration={persistent ? null : duration !== undefined ? duration : 4000}
      onClose={() => setOpen(false)}
      ClickAwayListenerProps={{
        mouseEvent: persistent ? false : 'onClick',
      }}
    >
      <Alert
        className="SnackbarControlAlert"
        elevation={6}
        variant="filled"
        onClose={() => setOpen(false)}
        severity={level}
        action={action ? action : false}
      >
        {content}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarControl;
