import React from 'react';

import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';

import ProjectTypeIcon from 'ReusableComponents/ProjectTypeIcon';

const useStyles = makeStyles()((theme, { type }) => ({
  container: {},
  projectTypeIcon: {
    height: '40px',
    width: '40px',
  },
  textContainer: {},
  title: {},
  legend: {},
  downloadDoneRoot: {
    height: '40px',
    width: '40px',
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.light,
    },
  },
  downloadIconRoot: {
    height: '40px',
    width: '40px',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

const SectionMenuItemEngagement = ({ projectType, title, legend, status, downloadId }) => {
  const { classes } = useStyles({ type: 'download' });
  console.log('projectType', projectType);
  return (
    <ListItem className={classes.container}>
      <ListItemIcon>
        <ProjectTypeIcon type={'message'} className={classes.projectTypeIcon} />
      </ListItemIcon>
      <ListItemText primary={title} secondary={legend} />
    </ListItem>
  );
};

SectionMenuItemEngagement.propTypes = {
  projectType: PropTypes.oneOf(['map', 'shape', 'file']).isRequired,
  title: PropTypes.string.isRequired,
  legend: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  downloadId: PropTypes.string.isRequired,
};

export default SectionMenuItemEngagement;
