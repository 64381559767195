import React, { useContext } from 'react';

export const DriveContext = React.createContext({});

export const useDriveContext = () => {
  const context = useContext(DriveContext);
  if (context === undefined) {
    throw new Error('useDriveContext must be used with a DriveContextProvider');
  }
  return context;
};

export const withDriveContext = (Component) => {
  return function WrapperComponent(props) {
    const driveContext = useDriveContext();
    return <Component {...props} {...driveContext} />;
  };
};
