import React /* , { useMemo } */ from 'react';

import { Delete as DeleteIcon, Restore } from '@mui/icons-material';

import { handleDeleteItems } from 'Drive/itemUtils';

import CombinedMenuItem from '../Components/CombinedMenuItem/CombinedMenuItem';
import { useSelectedItems } from 'Drive/selectedItemContext';
import { useCurrentFolder } from 'Drive/CurrentFolderContext';
import { useMainContext } from 'ReusableComponents';
import { useMinimalAuth } from 'hooks';
import ApiManager from 'ApiManager';
import { useDriveContext } from 'Drive/DriveContext';
import { useContextMenu } from '../../ContextMenuContext';

const Delete = ({ onClose }) => {
  const { onOpenSnackbar } = useMainContext();
  const { selectedItems, resetSelectedItems } = useSelectedItems();
  const { onBreadcrumbClick, currentFolderInfo, path: breadcrumbPath } = useCurrentFolder();

  const { menuOptions } = useContextMenu();
  const { type, path } = menuOptions;

  const user = useMinimalAuth();
  const { handleOptimisticUpdate } = useDriveContext();

  const handleDeleteClick = () => {
    onClose();

    if (type === 'multiple') {
      handleDeleteItems({
        deleteItems: selectedItems,
        onOpenSnackbar,
        handleOptimisticUpdate,
        resetSelectedItems: resetSelectedItems,
        user,
      });
    } else {
      if (currentFolderInfo && currentFolderInfo.id === path?.id) {
        ApiManager.put(`/v3/path/${path?.id}/trashed`, { trashed: !path.deleted }, user)
          .then((r) => {
            onOpenSnackbar({
              level: 'success',
              content: 'item deleted',
            });

            let parentId = breadcrumbPath.length > 1 ? breadcrumbPath[breadcrumbPath.length - 2].info : null;
            resetSelectedItems();
            onBreadcrumbClick(parentId);
          })
          .catch((e) => {
            onOpenSnackbar({
              level: 'error',
              content: e.message,
            });
          });
      } else {
        handleDeleteItems({
          deleteItems: [path],
          onOpenSnackbar,
          handleOptimisticUpdate,
          resetSelectedItems,
          user,
        });
      }
    }
  };

  const recover = (type !== 'multiple' && path?.deleted) || (type === 'multiple' && selectedItems[0]?.deleted);

  return (
    <CombinedMenuItem
      icon={recover ? <Restore /> : <DeleteIcon />}
      label={(recover ? 'Recover ' : 'Trash') + (type === 'multiple' ? selectedItems.length + ' items ' : '')}
      action={handleDeleteClick}
    />
  );
};

export default Delete;
