import React, { useEffect } from 'react';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { UserInfoDialogProvider } from '../ReusableComponents';
import { DialogProvider } from '../DialogControl/DialogContext';
import { NotificationProvider } from '../Navbar/Notifications';
import { CurrentFolderProvider } from 'Drive/CurrentFolderContext';
import { UserProvider } from 'Account/AuthContext';
import { UseUploadLogicProvider } from 'UploadDialog';
import { UseUploadingTimestampsProvider } from 'UploadDialog';
import ProfileContext from 'ReusableComponents/ProfileContext';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { LayersProvider } from 'Viewer/ViewerContexts/ViewerLayersContext';
import { ElevationModels } from 'Viewer/DetailsPane/Panes/MainPane/LayersPane/ElevationModels';
import { ElevationModelsProvider } from 'Viewer/ViewerContexts/ElevationModelsContext';
import { PaneContentProvider } from 'Viewer/DetailsPane/PaneContentContext';
import { AddedLayersProvider } from 'Viewer/DetailsPane/AddedLayersContext';
import { MainViewerProvider } from 'Viewer/ViewerContexts/MainViewerContext';
import { ExtraLayersProvider } from 'Viewer/ViewerContexts/ExtraLayersContext';
import { SelectedElementProvider } from 'Viewer/ViewerContexts/SelectedElementContext';
import { ViewerFuncsProvider } from 'Viewer/ViewerContexts/ViewerFuncsContext';
import { LibraryProvider } from 'Viewer/ViewerContexts/LibraryContext';

// except for the authContext and consentContext because App.js needs it
const AllContexts = ({ children }) => {
  useEffect(() => {
    /* Promise.allSettled(
      [...window?.navigator?.languages]?.map((l) => new Promise((resolve) => resolve(import(`moment/locale/${l}`))))
    )
      .then((promises) => {
        const i = promises?.findIndex((p) => p?.status === 'fulfilled');

        if (i > -1) {
          moment.locale(window?.navigator?.languages[i]);
        }
      })
      .catch((err) => console.log('catch', err)); */
  }, []);

  return (
    <UserProvider>
      <UserInfoDialogProvider>
        <UseUploadLogicProvider>
          <UseUploadingTimestampsProvider>
            <NotificationProvider>
              <DialogProvider>
                <CurrentFolderProvider>
                  <ProfileContext>
                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={window?.navigator?.languages}>
                      <DndProvider backend={HTML5Backend}>
                        <LayersProvider>
                          <ElevationModelsProvider>
                            <PaneContentProvider>
                              <AddedLayersProvider>
                                <MainViewerProvider>
                                  <ExtraLayersProvider>
                                    <SelectedElementProvider>
                                      <ViewerFuncsProvider>
                                        <LibraryProvider> {children}</LibraryProvider>
                                      </ViewerFuncsProvider>
                                    </SelectedElementProvider>
                                  </ExtraLayersProvider>
                                </MainViewerProvider>
                              </AddedLayersProvider>
                            </PaneContentProvider>
                          </ElevationModelsProvider>
                        </LayersProvider>
                      </DndProvider>
                    </LocalizationProvider>
                  </ProfileContext>
                </CurrentFolderProvider>
              </DialogProvider>
            </NotificationProvider>
          </UseUploadingTimestampsProvider>
        </UseUploadLogicProvider>
      </UserInfoDialogProvider>
    </UserProvider>
  );
};

export default AllContexts;
