import React from 'react';

import { makeStyles } from 'tss-react/mui';

import SearchInput from '../SearchInput/SearchInput';
import ViewButtonGroup from './ViewButtonGroup';
// import AddFolderButton from '../AddFolderComponent';
import EmptyTrashButton from './EmptyTrashButton';
import { useDriveContext } from 'Drive/DriveContext';
import { useCurrentFolder } from 'Drive/CurrentFolderContext';

const useStyles = makeStyles({ name: 'SearchAndFilters' })((theme) => ({
  gridContainer: {
    display: 'flex',
    padding: theme.spacing(1.5),
    paddingBottom: 0,
    gap: theme.spacing(3),
    alignItems: 'flex-start',
  },
}));

const SearchAndFilters = () => {
  const { reset, handleOptimisticUpdate, type } = useDriveContext();

  const { mainMode } = useCurrentFolder();
  const { classes: styles } = useStyles();
  return (
    <div className={styles.gridContainer}>
      <SearchInput />
      {
        mainMode === '/drive/trash' ? (
          <EmptyTrashButton reset={reset} handleOptimisticUpdate={handleOptimisticUpdate} />
        ) : null
        /* false && <AddFolderButton type={'icon'} /> */
      }
      <ViewButtonGroup className={styles.buttonGroup} type={type} />
    </div>
  );
};

export default SearchAndFilters;
