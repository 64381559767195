import React, { useEffect, useRef } from 'react';

import { makeStyles } from 'tss-react/mui';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Skeleton,
  Typography,
} from '@mui/material';
import { FlightTakeoff, KeyboardArrowDown } from '@mui/icons-material';

import { useInView } from 'react-intersection-observer';
import moment from 'moment';

const SearchResults = ({
  features,
  loading,
  hoveredState,
  setHoveredState,
  resultOpen,
  setResultOpen,
  flyTo,
  handleImport,
  setSearchOpen,
}) => {
  const scrollRef = useRef();

  return loading ? (
    <Box sx={{ p: 2, textAlign: 'center' }}>
      <CircularProgress />
    </Box>
  ) : (
    <List sx={{ overflow: 'auto' }} ref={scrollRef}>
      {features?.length > 0 ? (
        features?.map((f) => (
          <ResultListItem
            key={f?.id}
            feature={f}
            hoveredState={hoveredState}
            hovered={hoveredState?.id === f?.id}
            isMap={hoveredState?.type === 'map'}
            setHoveredState={() => setHoveredState((old) => (old?.id === f?.id ? {} : { id: f?.id, type: 'list' }))}
            scrollRef={scrollRef}
            open={resultOpen === f?.id}
            setOpen={() => {
              setSearchOpen(false);
              setResultOpen((old) => (old === f?.id ? undefined : f?.id));
            }}
            flyTo={() => flyTo(f?.geometry)}
            handleImport={() => handleImport(f)}
          />
        ))
      ) : (
        <ListItem>
          <ListItemText primary="No Results" />
        </ListItem>
      )}
    </List>
  );
};

export default SearchResults;

const ResultListItem = ({ listRef, feature, hovered, isMap, setHoveredState, open, setOpen, flyTo, handleImport }) => {
  const { collection, completionDate, cloudCover, startDate, title, thumbnail } = { ...(feature?.properties ?? {}) };

  const { ref, inView, entry } = useInView({ root: listRef?.current, threshold: 1 });
  const { ref: thumbnailRef, inView: thumbnailInView } = useInView({ root: listRef?.current, triggerOnce: true });

  useEffect(() => {
    if (hovered && isMap && !inView) {
      entry?.target?.scrollIntoView();
    }
  }, [entry, hovered, inView, isMap]);

  return (
    <List
      ref={ref}
      disablePadding
      sx={(theme) => ({
        borderRadius: 4,
        overflow: 'hidden',
        mx: 1,
        transition: theme.transitions.create(['border-color']),
        border: '1px solid transparent',
        '&&': { ...(open ? { borderColor: theme.palette.primary.main } : {}) },
      })}
    >
      <ListItem sx={{ p: 0 }}>
        <ListItemButton
          sx={(theme) => ({
            transition: theme.transitions.create(['background-color']),
            gap: 1,
            '&&': { ...(hovered ? { backgroundColor: theme.palette.primary.lightOpaque } : {}) },
          })}
          onMouseEnter={setHoveredState}
          onMouseLeave={setHoveredState}
          onClick={setOpen}
        >
          {thumbnail && (
            <ListItemAvatar ref={thumbnailRef}>
              <Avatar src={thumbnailInView ? thumbnail : ''} variant="rounded" alt={title} loading="lazy">
                <Skeleton variant="rectangular" width="100%" height="100%" />
              </Avatar>
            </ListItemAvatar>
          )}
          <ListItemText
            primary={title}
            secondary={collection}
            primaryTypographyProps={{ variant: 'caption', sx: { overflowWrap: 'anywhere' } }}
            secondaryTypographyProps={{ variant: 'overline' }}
          />
          <IconButton edge="end">
            <CustomKeyboardArrowDown open={open} />
          </IconButton>
        </ListItemButton>
      </ListItem>
      <Collapse in={open} component="li">
        <ListItem
          component="div"
          secondaryAction={
            <IconButton onClick={flyTo} edge="end">
              <FlightTakeoff />
            </IconButton>
          }
        >
          <Box sx={{ display: 'grid', gridTemplateColumns: 'min-content 1fr', rowGap: 0.75, columnGap: 2 }}>
            <GridRow title="Start Date" content={moment(startDate)?.format('L LTS')} />
            <GridRow title="Completion Date" content={moment(completionDate)?.format('L LTS')} />
            <GridRow title="Cloud Cover" content={`${cloudCover}%`} />
          </Box>
        </ListItem>
        <Divider />
        <Box p={1} sx={{ textAlign: 'right' }}>
          <Button variant="contained" onClick={handleImport}>
            Import
          </Button>
        </Box>
      </Collapse>
    </List>
  );
};

const useStyles = makeStyles()((theme) => ({
  root: {
    transition: theme.transitions.create('transform'),
    transform: 'rotate(0deg)',
  },
  open: { transform: 'rotate(180deg)' },
}));

const CustomKeyboardArrowDown = ({ open }) => {
  const { classes, cx } = useStyles();

  return <KeyboardArrowDown className={cx(classes.root, open && classes.open)} />;
};

const GridRow = ({ title, content }) => (
  <>
    <Typography variant="caption" component="p">
      {title}
    </Typography>
    <Typography variant="body2" sx={{ mt: '-3px' }}>
      {content}
    </Typography>
  </>
);
