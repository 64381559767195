import React, { useState, useEffect } from 'react';
import { Link, Typography, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { FileCopy } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { createLink, getText, protocols, CreateTokenDialog } from 'ReusableComponents/OgcLinks';
import { useMinimalAuth } from 'Account/AuthContext';
import copy from 'copy-to-clipboard';
import { isValidTimestamp } from 'ReusableComponents/ValidLayer/ValidLayer';
import SectionTitle, { useStyles as useSectionStyles } from '../../Section';
import { useHistory } from 'react-router-dom';
import { useMainContext } from 'ReusableComponents';

function Links({ noTitle, map }) {
  const user = useMinimalAuth();

  const sectionClasses = useSectionStyles();

  const history = useHistory();

  const type = map.type === 'shape' ? 'vector' : map.type === 'map' ? 'raster' : map.type;
  const isLayer = ['raster', 'vector', 'pointCloud'].includes(type);
  let timestamps;
  let select;
  if (isLayer) {
    timestamps = map.timestamps.filter((timestamp) => isValidTimestamp(timestamp).available);
    select = timestamps.length - 1;
    for (let j = 0; j < timestamps.length; j++) {
      if (timestamps[j].totalSize > timestamps[select].totalSize) {
        select = j;
      }
    }
  }

  return (
    <>
      {!noTitle && (
        <SectionTitle style={{ marginTop: '20px' }}>
          <SectionTitle.SectionTitleText>Integrate</SectionTitle.SectionTitleText>
        </SectionTitle>
      )}
      <Typography variant="body1" className={sectionClasses.caption}>
        Use the links below to{' '}
        {isLayer ? 'open the layer in the GIS software of your choice' : ' use your data in your own apps'}. Checkout
        the{' '}
        <Link
          href="#"
          onClick={(e) => {
            e.preventDefault();
            history.push('/integrate');
          }}
        >
          PLUGINS PAGE.
        </Link>{' '}
        to use your Ellipsis Drive content in your own apps/frame works.
      </Typography>

      <OtherLinkSection link={map.id} titleText={'Path Id'} />
      {isLayer && (
        <OtherLinkSection
          link={select >= 0 ? timestamps[select]?.id : 'Not available, activate first'}
          titleText={'Timestamp Id (default)'}
        />
      )}
      {select >= 0 && isLayer && type !== 'pointCloud' && (
        <OtherLinkSection link={map.styles.find((s) => s.default)?.id} titleText={'Style Id (default)'} />
      )}
      {select >= 0 && isLayer && (
        <OtherLinkSection link={map.timestamps.find((t) => isValidTimestamp(t))?.zoom} titleText={'Zoom'} />
      )}

      {select >= 0 && isLayer && map.type === 'shape' && (
        <OtherLinkSection
          link={map.timestamps.find((t) => isValidTimestamp(t))?.statistics?.precomputeZoom}
          titleText={'Vector Tile Zoom'}
        />
      )}

      {['myMaps', 'sharedWithMe'].includes(map?.path?.root) && <CreateTokenSection layer={map} />}

      {protocols
        .filter((p) => p.type === type || p.always)
        .map((p) => (
          <LinkSection
            map={map}
            key={p.displayName}
            titleText={`${p.displayName}`}
            res={getText(null, p.displayName, map, user)}
          />
        ))}

      {(isLayer || type === 'bookmark') && <EmbedLinkSection type={map?.type} mapId={map?.id} stateId={map?.stateId} />}
    </>
  );
}

const useStyles = makeStyles()((theme) => ({
  sectionContainer: {
    margin: theme.spacing(1.5, 0),
  },
  linkContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  linkText: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.spacing(0.5),
    flexGrow: 1,
    marginRight: theme.spacing(3),
    padding: theme.spacing(0.75, 1),
    whiteSpace: 'nowrap',
    overflow: 'auto',
  },
  copyButton: {
    width: '110px',
    minWidth: '110px',
    boxSizing: 'border-box',
  },
  code: {
    '&>.CodeMirror': { padding: 0, minHeight: 'unset', border: 0, '&>.CodeMirror-scroll': { minHeight: 'unset' } },
  },
}));

const OtherLinkSection = ({ link, titleText }) => {
  const { classes, theme } = useStyles();
  const sectionClasses = useSectionStyles();

  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    copy(link);
    setIsClicked(true);
  };

  useEffect(() => {
    const id = setTimeout(() => {
      setIsClicked(false);
    }, 2000);
    return () => clearTimeout(id);
  }, [isClicked]);

  return (
    <section className={classes.sectionContainer}>
      <Typography variant="subtitle1" className={sectionClasses.groupTitle}>
        {titleText}
      </Typography>
      <div className={classes.linkContainer}>
        <Typography className={classes.linkText}>{link}</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClick}
          className={classes.copyButton}
          startIcon={<FileCopy />}
          style={{ backgroundColor: isClicked && theme.palette.success.main }}
        >
          {isClicked ? 'Copied!' : 'Copy'}
        </Button>
      </div>
    </section>
  );
};

const EmbedLinkSection = ({ mapId: pathId, stateId }) => {
  const { classes } = useStyles();
  const sectionClasses = useSectionStyles();

  const { onOpenSnackbar } = useMainContext();

  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    copy(iframeString)
      ? onOpenSnackbar({
          level: 'success',
          content: 'iFrame successfully copied to clipboard',
        })
      : onOpenSnackbar({
          level: 'error',
          content: 'Something went wrong',
        });
    setIsClicked(true);
  };

  useEffect(() => {
    const id = setTimeout(() => {
      setIsClicked(false);
    }, 2000);
    return () => clearTimeout(id);
  }, [isClicked]);

  const searchParams = new URLSearchParams({ pathId, ...(stateId ? { stateId } : {}) });
  const link = `${window?.location?.origin}/view?${searchParams?.toString()}&hideNavbar=true`;
  const iframeString = `<iframe title="Ellipsis Drive Viewer" src="${link}" />`;

  return (
    <>
      <section className={classes.sectionContainer}>
        <Typography variant="subtitle1" className={sectionClasses.groupTitle}>
          Embedded window
        </Typography>
        <div className={classes.linkContainer}>
          <Typography className={classes.linkText}>Embed the viewer on your website</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClick}
            className={classes.copyButton}
            startIcon={<FileCopy />}
          >
            {isClicked ? 'Copied!' : 'Copy'}
          </Button>
        </div>
      </section>
    </>
  );
};

const CreateTokenSection = ({ layer }) => {
  const { classes /* , theme */ } = useStyles();
  const sectionClasses = useSectionStyles();

  const [createToken, setCreateToken] = useState(false);

  if (layer.type === 'bookmark') return null;
  return (
    <>
      {createToken && <CreateTokenDialog onClose={() => setCreateToken(false)} layer={layer} />}
      <section className={classes.sectionContainer}>
        <Typography variant="subtitle1" className={sectionClasses.groupTitle}>
          Create access token for your app
        </Typography>
        <div className={classes.linkContainer}>
          <Typography className={classes.linkText}> Create an access token to this content </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setCreateToken(true)}
            className={classes.copyButton}
            startIcon={<FileCopy />}
          >
            {'Create'}
          </Button>
        </div>
      </section>
    </>
  );
};

const LinkSection = ({ titleText, res, map }) => {
  const sectionClasses = useSectionStyles();
  const user = useMinimalAuth();
  const { onOpenSnackbar } = useMainContext();
  const { classes, theme } = useStyles();

  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    console.log(map);
    createLink(
      null,
      titleText,
      map,
      user,
      onOpenSnackbar,
      map?.timestamps?.find((t) => isValidTimestamp(t))?.id,
      map?.styles?.find((s) => s.default)?.id
    );
    setIsClicked(true);
  };

  useEffect(() => {
    const id = setTimeout(() => {
      setIsClicked(false);
    }, 2000);
    return () => clearTimeout(id);
  }, [isClicked]);

  return (
    <section className={classes.sectionContainer}>
      <Typography variant="subtitle1" className={sectionClasses.groupTitle}>
        {titleText}
      </Typography>
      <div className={classes.linkContainer}>
        <Typography className={classes.linkText}>{res?.text}</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClick}
          disabled={res?.hasAccess !== undefined && !res.hasAccess}
          className={classes.copyButton}
          startIcon={<FileCopy />}
          style={{ backgroundColor: isClicked && theme.palette.success.main }}
        >
          {isClicked ? 'Copied!' : 'Create'}
        </Button>
      </div>
    </section>
  );
};

LinkSection.propTypes = {
  titleText: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default Links;
