import React, { useState, useCallback, useEffect, useRef } from 'react';

import { Paper } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';

import uploadContext from './uploadContext';
import { useUploadLogic, useAllUploadsLogic } from './useUploadLogic';

const useStyles = makeStyles()((theme, { show }) => ({
  container: {
    display: show ? 'flex' : 'none',
    flexDirection: 'column',
    width: '400px',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
    maxHeight: '445px',
    position: 'fixed',
    right: '30px',
    [theme.breakpoints.down('lg')]: {
      right: 0,
    },
    bottom: '15px',
    zIndex: '1000',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkitScrollbar': {
      display: 'none',
    },
  },
}));

const UploadDialog = ({ estimatedTime, children }) => {
  const [showAll, setShowAll] = useState(true);
  const { hideDialog, setHideDialog, doHideDialog } = useUploadLogic();
  const { uploads } = useAllUploadsLogic();
  const prevUploadsNumber = useRef(0);
  const { classes } = useStyles({ show: uploads.length > 0 && !hideDialog });

  const toggleShowAll = useCallback(() => setShowAll((prev) => !prev), []);
  const providerValue = {
    uploads,
    estimatedTime,
    showAll,
    toggleShowAll,
    doHideDialog,
  };

  // Hide dialog when no upload
  useEffect(() => {
    setHideDialog(uploads.length === 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploads.length]);

  // showAll items when new uploads have been added
  useEffect(() => {
    if (uploads.length > prevUploadsNumber.current) {
      setShowAll(true);
      prevUploadsNumber.current = uploads.length;
    }
  }, [uploads.length]);

  return (
    <uploadContext.Provider value={providerValue}>
      <Paper className={classes.container}>{children}</Paper>
    </uploadContext.Provider>
  );
};

UploadDialog.propTypes = {
  estimatedTime: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export default UploadDialog;
