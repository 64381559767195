import React from 'react';

import { List, ListItem, ListItemText, Paper, Badge } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';

import SharingComponent from '../../ReusableComponents/SharingComponent/SharingComponent';
import { FileNameComponent } from 'ReusableComponents';

const MAX_NUMBER_ITEM = 4;

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '200px',
  },
  item: {
    paddingTop: '0px',
    paddingBottom: '0px',
  },
}));

const ItemsTemplate = ({ items }) => {
  const { classes } = useStyles();

  return (
    <Badge badgeContent={items.length} color="primary" overlap="rectangular">
      <Paper className={classes.root}>
        <List aria-label="drag preview">
          {items.slice(0, MAX_NUMBER_ITEM).map((map) => {
            return (
              <ListItem key={map.id} className={classes.item}>
                <ListItemText
                  primary={map?.type === 'file' ? <FileNameComponent name={map.name} /> : map.name}
                  secondary={<SharingComponent map={map} />}
                  primaryTypographyProps={{ noWrap: true }}
                  secondaryTypographyProps={{ component: 'div' }}
                />
              </ListItem>
            );
          })}
          {items.length > MAX_NUMBER_ITEM ? (
            <ListItem className={classes.item}>
              <ListItemText
                primary={`+ ${items.length - MAX_NUMBER_ITEM} other items${
                  items.length - MAX_NUMBER_ITEM > 1 ? 's' : ''
                }`}
                primaryTypographyProps={{ color: 'textSecondary' }}
              />
            </ListItem>
          ) : null}
        </List>
      </Paper>
    </Badge>
  );
};

ItemsTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
};

export default ItemsTemplate;
