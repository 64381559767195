import React from 'react';
import PropTypes from 'prop-types';

import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

import { useDialogSetter } from './DialogContext';

const DialogControl = ({ title, text, content, buttons }) => {
  const open = !!(title || text || content || buttons);
  const dialogSetter = useDialogSetter();

  const onClose = () => {
    dialogSetter({});
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      {!!title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        {!!text && <DialogContentText>{text}</DialogContentText>}
        {content}
      </DialogContent>
      <DialogActions>
        {!!buttons ? (
          buttons?.map((actionButton, i) => (
            <Button
              key={'actions_' + i}
              variant={actionButton?.variant ?? 'text'}
              color={actionButton?.color ?? 'primary'}
              onClick={() => {
                if (typeof actionButton?.action === 'function') {
                  actionButton.action();
                }

                onClose();
              }}
            >
              {actionButton?.text}
            </Button>
          ))
        ) : (
          <Button onClick={onClose}>close</Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

DialogControl.prototype = {
  title: PropTypes.string,
  text: PropTypes.string,
  content: PropTypes.node,
  buttons: PropTypes.oneOfType[(PropTypes.array, PropTypes.element)],
};

export default DialogControl;
