import React, { useContext, useCallback } from 'react';

import { Button, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';

import MapManagementContext from '../../FilesManagementContext';
import DropZoneArea from '../../../ReusableComponents/DropZoneArea';
import UploadPreview from './UploadPreview';
import { validateFileFormat } from '../UploadPage';
import { useAuthFuncs } from 'Account/AuthContext';
import { UseUploadingTimestamps, useUploadLogic } from 'UploadDialog';
import { useCurrentFolder } from 'Drive/CurrentFolderContext';

const useDropZoneStyles = makeStyles({ name: 'UploadDropZone' })((theme) => ({
  dropZone: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: theme.spacing(3),
  },
  text: {},
  uploadButton: {
    width: '180px',
    alignSelf: 'center',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: '1.5rem',
    gap: theme.spacing(1),
  },
  fileNumberContainer: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
    flexGrow: 1,
  },
  deleteAllButton: {},
}));

const UploadDropZone = ({
  autoDetectEspg,
  noDataValue,
  setUploadOpen,
  setAutoProcessOpen,
  type,
  uploads,
  captureId,
  files,
  setFiles,
  fastUpload,
  downsample,
  dateColumns,
  datePatterns,
  uploadFormats,
}) => {
  const { classes } = useDropZoneStyles();

  const { map, user, openNotification } = useContext(MapManagementContext);
  const { handleStartUpload } = useUploadLogic();
  const { addUploading, setUploadCounter } = UseUploadingTimestamps();
  const { fetchUserInfo } = useAuthFuncs();
  const { poll } = useCurrentFolder();
  const handleFilesUpload = () => {
    setUploadOpen(false);
    setAutoProcessOpen(captureId);

    let skippedFiles = [];
    // let count = 0;
    files.forEach((file, index) => {
      const { error, uploadAs } = validateFileFormat(file.name.split('.').at(-1), uploadFormats);
      if (!error) {
        const body = {
          mapId: map.id,
          name: file.name,
          format: uploadAs,
        };
        if (autoDetectEspg.active) {
          body['epsg'] = Number(autoDetectEspg.epsg);
        }
        if (!!noDataValue || noDataValue === 0) {
          body['noDataValue'] = Number(noDataValue);
        }

        if (type === 'geometry') {
          body['fastUpload'] = fastUpload;
          body['method'] = downsample || fastUpload ? 'simplify' : 'full';

          body['datePatterns'] = JSON.stringify(
            // replace dummy $ that corresponds to empty string
            datePatterns.map((pattern) => pattern.replace(/\$/g, ''))
          );
          body['dateColumns'] = JSON.stringify(dateColumns);
        }

        body['timestampId'] = captureId;

        const onUploadSuccess = () => {
          setUploadCounter();
          poll();
          fetchUserInfo();
        };

        const onUploadError = () => {
          setUploadCounter();
        };

        let shouldSkip =
          (uploads &&
            uploads.reduce(
              (acc, curr) => (curr.status === 'completed' && curr.name === file.name && !curr.trashed ? true : acc),
              false
            )) ||
          files.findIndex((f) => f.name === file.name) !== index;
        if (shouldSkip) {
          skippedFiles.push(file.name);
        }
        addUploading(captureId);

        handleStartUpload(file, body, user, type, captureId, onUploadSuccess, onUploadError, shouldSkip, map);

        let cb = async () => {
          await new Promise((r) => setTimeout(r, 1000));
        };
        cb();
      }
    });

    if (skippedFiles.length > 0) {
      openNotification({
        type: 'info',
        text: `The following file${skippedFiles > 1 ? 's' : ''} seem${
          skippedFiles > 1 ? '' : 's'
        } to be already present in the server (same filename) so ${skippedFiles > 1 ? 'their' : 'its'} upload${
          skippedFiles > 1 ? 's' : ''
        } ${skippedFiles > 1 ? 'were' : 'was'} skipped:  ${skippedFiles.slice(0, 15).join(', ')}.`,
        duration: 0,
      });
    }

    setFiles([]);
  };

  const removeFile = (fileName) => setFiles((files) => files.filter((file) => file.name !== fileName));

  const handleFileChange = useCallback((files) => setFiles((prevFiles) => [...prevFiles, ...files]), [setFiles]);

  return (
    <div className={classes.dropZone}>
      <DropZoneArea onChange={handleFileChange} />
      <UploadPreview files={files} removeFile={removeFile} uploadFormats={uploadFormats} />
      <div className={classes.titleContainer}>
        <div className={classes.fileNumberContainer}>
          <Typography>{files?.length > 0 ? `${files.length} file${files.length === 1 ? '' : 's'}` : ''}</Typography>
        </div>
        {files.length > 0 ? (
          <Button variant="text" color="primary" className={classes.deleteAllButton} onClick={() => setFiles([])}>
            Remove {files.length === 1 ? '' : 'all '}
            {files.length} file{files.length === 1 ? '' : 's'}
          </Button>
        ) : null}
        <Button
          className={classes.uploadButton}
          variant="contained"
          color="primary"
          disabled={
            (autoDetectEspg.active && !autoDetectEspg.epsg) ||
            files.filter((f) => !validateFileFormat(f.name.split('.').at(-1), uploadFormats).error).length === 0
          }
          onClick={handleFilesUpload}
        >
          Upload
        </Button>
      </div>
    </div>
  );
};

UploadDropZone.propTypes = {
  handleStartUpload: PropTypes.func,
  type: PropTypes.string.isRequired,
  uploads: PropTypes.arrayOf(
    PropTypes.shape({
      fileName: PropTypes.string.isRequired,
    })
  ),
  files: PropTypes.array.isRequired,
  setFiles: PropTypes.func.isRequired,
  setCurrentTab: PropTypes.func,
};

export default UploadDropZone;
