import React from 'react';

import { PaymentTwoTone } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import ManageSubscriptionsComponent from '../Components/ManageSubscriptions/ManageSubscriptions';
import CombinedMenuItem from '../Components/CombinedMenuItem/CombinedMenuItem';
import { DialogContentText, DialogContent, DialogActions, Button } from '@mui/material';
import { useMinimalAuth } from 'hooks';
import { useContextMenu } from '../../ContextMenuContext';

const ManageSubscriptions = ({ onClose, setDialog }) => {
  const user = useMinimalAuth();

  const { menuOptions } = useContextMenu();
  const { path } = menuOptions;

  const manageSubscriptions = () => {
    setDialog({
      title: 'Manage Subscription plans',
      content: !path.user.commercial ? (
        <DialogContent>
          <DialogContentText>
            {user.id === path.user.id
              ? 'You need to upgrade to a commercial account in order to add subscription plans to your layer.'
              : 'Only maps of commercial users can have a subscription plan. Ask this user to upgrade his or her account.'}
          </DialogContentText>
          <DialogActions>
            <Button color="primary" size="small" component={Link} to="/plan">
              Upgrade Account
            </Button>
          </DialogActions>
        </DialogContent>
      ) : (
        <ManageSubscriptionsComponent path={path} />
      ),
    });

    onClose();
  };

  return <CombinedMenuItem icon={<PaymentTwoTone />} label="Subscriptions" action={manageSubscriptions} openDialog />;
};

export default ManageSubscriptions;
