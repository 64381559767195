import React, { useState } from 'react';

import {
  Dialog,
  DialogContent,
  DialogContentText,
  List,
  ListItem,
  TextField,
  ListItemSecondaryAction,
  IconButton,
  DialogActions,
  Button,
  Collapse,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { /* Close, */ Send, ContactMail } from '@mui/icons-material';

import { useMainContext } from '../MainContext';
import { useUserInfoState, useUserInfoDispatch } from './userInfoDialogContext';
import ApiManager from '../../ApiManager';
import { useMinimalAuth } from 'hooks';
import CustomAvatar from 'ReusableComponents/CustomAvatar';
import DialogTitle from 'ReusableComponents/DialogTitle/DialogTitle';
import { useEffect } from 'react';
import { useCallback } from 'react';
// import VersionUtility from 'VersionUtility';

const MAX_CHARACTERS = 2000;

const useStyles = makeStyles()((theme) => ({
  root: {
    transition: theme.transitions.create('max-width'),
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  title: {
    flex: 1,
    paddingLeft: '12px',
    paddingRight: '6px',
    wordBreak: 'break-all',
    lineHeight: 1,
  },
  button: {
    marginLeft: '1rem',
  },
  homepageLink: {
    textDecoration: 'underline',
    color: theme.palette.primary.main,
  },
  about: {
    whiteSpace: 'normal',
    overflowWrap: 'break-word',
  },
}));

const getClickableLink = (link) => {
  if (link) return link.startsWith('http://') || link.startsWith('https://') ? link : `https://${link}`;
};

const UserInfoDialog = () => {
  const { classes } = useStyles();
  const { onOpenSnackbar } = useMainContext();
  const authUser = useMinimalAuth();

  const { open, user } = useUserInfoState();
  const setDialogOpen = useUserInfoDispatch();

  const handleDialogClose = () => setDialogOpen((prev) => ({ ...prev, open: false }));

  const [userData, setUserData] = useState(null);
  const [messageOpen, setMessageOpen] = useState(false);
  const [messageValue, setMessageValue] = useState('');

  const handleMessageValueChange = (e) => setMessageValue(e.target.value);

  const sendMessage = async () => {
    try {
      await ApiManager.post(
        `/v3/user/${user.id}/contact`,
        { targetUser: user.username, message: messageValue },
        authUser
      );
      onOpenSnackbar({
        level: 'success',
        content: 'An email has been sent!',
      });
      setMessageValue('');
      setMessageOpen(false);
    } catch (error) {
      onOpenSnackbar({
        level: 'error',
        content: 'Error with sending message',
      });
    }
  };

  const fetchData = useCallback(
    (id) => {
      ApiManager.get(`/v3/user/${id}`, null, authUser)
        .then((res) => {
          console.log(res);
          setUserData(res);
        })
        .catch((err) => console.error(err));
    },
    [authUser]
  );

  useEffect(() => {
    if (user?.id) {
      if (userData?.id !== user?.id) {
        fetchData(user?.id);
      }
    }
  }, [fetchData, user?.id, userData?.id]);

  useEffect(() => {
    if (!messageOpen && messageValue?.length > 0) {
      setMessageValue('');
    }
  }, [messageOpen, messageValue?.length]);

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      scroll="paper"
      maxWidth={messageOpen ? 'md' : 'xs'}
      fullWidth
      classes={{ paper: classes.root }}
    >
      <DialogTitle
        titleProps={{ className: classes.titleContainer }}
        title={
          <>
            <CustomAvatar user={user} size={48} />
            {user?.username}
          </>
        }
        close={handleDialogClose}
      />
      <DialogContent className={classes.content}>
        {userData?.profile?.homepage && (
          <DialogContentText>
            Homepage:{' '}
            <Typography
              component="a"
              className={classes.homepageLink}
              target="_blank"
              href={getClickableLink(userData?.profile?.homepage)}
              rel="noopener noreferrer"
            >
              {userData?.profile?.homepage}
            </Typography>
          </DialogContentText>
        )}
        {userData?.profile?.about && (
          <DialogContentText className={classes.about}>{userData?.profile?.about}</DialogContentText>
        )}
        <Collapse in={messageOpen}>
          <List dense disablePadding>
            <ListItem dense disableGutters>
              <TextField
                label="Message User"
                variant="outlined"
                fullWidth
                autoFocus
                multiline
                error={messageValue.length > MAX_CHARACTERS}
                helperText={`${messageValue.length}/${MAX_CHARACTERS}`}
                onChange={handleMessageValueChange}
                value={messageValue}
              />
              <ListItemSecondaryAction>
                <IconButton
                  variant="contained"
                  color="primary"
                  edge="end"
                  onClick={sendMessage}
                  disabled={messageValue.length > MAX_CHARACTERS || messageValue.length === 0}
                  size="large"
                >
                  <Send />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Collapse>
      </DialogContent>
      <DialogActions>
        {userData?.profile?.allowContact &&
          (!messageOpen ? (
            <Collapse in={!messageOpen}>
              {authUser && authUser.id !== userData?.id && (
                <Button color="primary" startIcon={<ContactMail />} onClick={() => setMessageOpen(true)}>
                  Contact user
                </Button>
              )}
            </Collapse>
          ) : (
            <Collapse in={messageOpen}>
              <Button onClick={() => setMessageOpen(false)}>Cancel Message</Button>
            </Collapse>
          ))}
      </DialogActions>
    </Dialog>
  );
};

export default UserInfoDialog;
