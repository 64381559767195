import React from 'react';

import { withStyles } from 'tss-react/mui';

import { DialogTitle as MuiDialogTitle, IconButton, Typography } from '@mui/material';

import { Close } from '@mui/icons-material';
import useEmptyStyles from 'hooks/useEmptyStyles';

const dialogTitleStyles = (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    padding: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    lineHeight: 1.25,
  },
  closeButton: {},
  closeContainer: {
    display: 'flex',
    width: theme.spacing(3),
    height: theme.spacing(3),
    alignItems: 'center',
    justifyContent: 'center',
    color: 'inherit',
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    boxShadow: theme.shadows[1],
  },
});

const DialogTitle = withStyles(
  ({ id, title, close, onClose, classes, titleProps, className, children, color = 'initial', ...props }) => {
    const { className: titleClassName, ...restProps } = { ...titleProps };
    const { cx } = useEmptyStyles();

    const closeFunc = close ?? onClose;

    return (
      <MuiDialogTitle
        className={cx(classes.root, color === 'primary' && classes.primary, className)}
        {...props}
        component="div"
        id={id}
      >
        <Typography noWrap component="h1" variant="h3" className={cx(classes.title, titleClassName)} {...restProps}>
          {title || children}
        </Typography>
        {!!closeFunc && (
          <div className={classes.closeContainer}>
            <IconButton
              aria-label="close"
              //.cancelMove
              className={cx(classes.closeButton, '.cancelMove')}
              onClick={closeFunc}
              size="large"
              color={color !== 'initial' ? 'inherit' : undefined}
            >
              <Close />
            </IconButton>
          </div>
        )}
      </MuiDialogTitle>
    );
  },
  dialogTitleStyles
);

export default DialogTitle;
