import React, { useCallback, useState } from 'react';

import { Button, Dialog, DialogActions, DialogContent, useMediaQuery } from '@mui/material';
import { Link, Power } from '@mui/icons-material';
import copy from 'copy-to-clipboard';

import DialogTitle from 'ReusableComponents/DialogTitle/DialogTitle';
import StyledGroupButton from 'ReusableComponents/StyledGroupButton';

import { makeStyles } from 'tss-react/mui';
import { useMainContext } from '../MainContext';
import { retryableLazy } from 'globalUtils';
import SuspenseContainer from 'ReusableComponents/SuspenseContainer';

const ManageSharing = retryableLazy(() => import('ReusableComponents/ManageSharing'));

const useStyles = makeStyles({ name: 'ManageSharingDialog' })((theme, _params, classes) => ({
  root: {
    paddingTop: 64,
    [theme.breakpoints.down('sm')]: { paddingTop: 0 },
    [`& .${classes.content}`]: {
      padding: theme.spacing(1, 3),
      [theme.breakpoints.down('sm')]: { padding: theme.spacing(1), paddingTop: 0 },
      paddingTop: 0,
      margin: theme.spacing(1, 0),
    },
  },
  dialogTitle: {
    [theme.breakpoints.down('sm')]: {
      background: theme.palette.primary.main,
      color: 'white',
      '& button': { color: '#ededed' },
    },
  },
  content: {},
  actions: {
    justifyContent: 'space-between',
    padding: theme.spacing(2, 3),
    [theme.breakpoints.down('sm')]: { padding: theme.spacing(1) },
  },
}));

const ManageSharingDialog = ({ open = false, openFunc = () => {}, ...PropsManageSharing }) => {
  const { classes: styles } = useStyles();
  const isSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const closeFunc = useCallback(() => {
    openFunc(false);
  }, [openFunc]);

  return (
    <Dialog open={open} maxWidth={'md'} fullWidth onClose={closeFunc} className={styles.root} fullScreen={isSm}>
      <DialogTitle title={'Manage Sharing'} close={closeFunc} className={styles.dialogTitle} />
      <DialogContent className={styles.content}>
        <SuspenseContainer displayName="ManageSharingDialog">
          <ManageSharing {...PropsManageSharing} closeFunc={closeFunc} />
        </SuspenseContainer>
      </DialogContent>
      <DialogActions className={styles.actions}>
        <ShareLinkButton {...PropsManageSharing} />
        <Button onClick={closeFunc}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ManageSharingDialog;

const ShareLinkButton = ({ actions, path }) => {
  const { onOpenSnackbar } = useMainContext();
  // const [advancedLinkOpen, setAdvancedLinkOpen] = useState(false);
  const [, setAdvancedLinkOpen] = useState(false);

  const newActions = actions
    ? actions?.filter((a) => a.type === 'quickLink')
    : [
        {
          icon: <Link />,
          onClick: () => {
            copy(
              path.type === 'bookmark'
                ? window.location.origin + '/view?pathId=' + path.id
                : window.location.origin + '/drive/external?pathId=' + path.id
            );
            onOpenSnackbar({
              level: 'success',
              content: 'Link copied to clipboard',
            });
          },
          shortLabel: 'Copy Link',
          label: 'Quick link',
        },
      ];

  return (
    <>
      <StyledGroupButton
        actions={newActions}
        defaultActionProps={{ labelProp: 'shortLabel' }}
        menuItemProps={{ listItemTextProps: { primaryTypographyProps: { variant: 'button' } } }}
      />
    </>
  );
};
