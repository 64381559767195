import React from 'react';

import { FormControl, Typography, Select, FormHelperText } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';

const useStyles = makeStyles()((theme) => ({
  formControl: {
    // marginBottom: '10px',
  },
  title: {
    marginBottom: '4px',
    color: theme.palette.text.secondary,
  },
  input: {
    padding: '10px',
  },
}));

const CustomSelect = ({
  children,
  titleText = '',
  helperText = '',
  className = '',
  style: userStyles = {},
  ...selectProps
}) => {
  const { classes, cx } = useStyles();

  const { inputProps: userInputProps = {}, ...otherSelectProps } = selectProps;
  return (
    <FormControl variant="outlined" className={cx(classes.formControl, className)} style={userStyles}>
      {titleText && <Typography className={classes.title}>{titleText}</Typography>}
      <Select
        variant="standard"
        inputProps={{ className: classes.input, ...userInputProps }}
        {...otherSelectProps}>
        {children}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

CustomSelect.propTypes = {
  children: PropTypes.node.isRequired,
  titleText: PropTypes.string,
  helperText: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default CustomSelect;
